import React, { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./AuditDetails.css";
import ConvertTimeStamp from "./../../../utils/ConvertTimeStamp";
import TebularInputCardAnswer from "../../Company Sub Admin/Component/Sector Questions/TabularInputCardAnswer";
const AuditDetails = () => {
  const audit_data = JSON.parse(sessionStorage.getItem("audit_data"));

  let dateTime = audit_data.answer_details.updatedAt
    ? ConvertTimeStamp(audit_data.answer_details.updatedAt)
    : ConvertTimeStamp(audit_data.answer_details.answered_at);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("audit_data");
    };
  }, []);

  return (
    <>
      <h2> Detailed Audit Log</h2>
      <Container>
        <Table>
          <Row>
            <Col md={8} className="profile_column">
              <div className="profile_details">
                {" "}
                <img
                  className="profile-img"
                  src={audit_data.answer_details["zb_users.profile_picture"]}
                  alt="Profile Picture"
                />
                <div>
                  <h4>
                    {audit_data.answer_details["zb_users.first_name"]}{" "}
                    {audit_data.answer_details["zb_users.last_name"]}
                  </h4>

                  <p>
                    {
                      audit_data.answer_details[
                        "zb_users.register_company_name"
                      ]
                    }
                  </p>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <h5>
                {audit_data.update_type}
                <br></br> {dateTime[0]} <br></br>
                {dateTime[1].split(".")[0]}
              </h5>
            </Col>
          </Row>
        </Table>
        <Table hover={true} striped={true} bordered={true}>
          <th colSpan={2}>ANSWER DETAILS</th>
          <tbody>
            <tr colSpan={2}>{audit_data.question_details.completeQuestion.title}</tr>
            <tr>
              <td>Question Type</td>
              <td>{audit_data.questionType}</td>
            </tr>
            <tr>
              <td>Remark</td>
              <td>{audit_data.answer_details.remark}</td>
            </tr>
            <tr>
              <td> Answer</td>
              <td>
                {audit_data.questionType === "tabular_question" ||
                audit_data.questionType === "trends_question" ? (
                  <>See below</>
                ) : (
                  audit_data.answer_details.answer
                )}
              </td>
            </tr>
          </tbody>

          {audit_data.questionType === "tabular_question" && (
            <TebularInputCardAnswer
              item={{question_detail:audit_data.question_details.quesDets}}
              combinedAnswers={[{
                answered_by_email: `${audit_data.answer_details["zb_users.first_name"]} ${audit_data.answer_details["zb_users.last_name"]}`,
                audit_status:audit_data.audit_status,
                audit_remark:audit_data.answer_details.remark,
                answer:JSON.parse(audit_data.answer_details.answer)}]}
              userIsHead={1}  // check later
              // userIsHistory={0}
              // meterListData={meterList}
            />
          )}
        </Table>
      </Container>
    </>
  );
};

export default AuditDetails;
