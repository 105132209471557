import React, { useEffect, useState } from "react";
import config from "../../config/config.json";
import Sidebar from "../sidebar/sidebar";
import Header from "../header/header";
import "./settings.module.css";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./settings.module.css";
import Profile from "./Profile";
import Billing from "./Billing";
import SubUser from "./SubUsers";
import SubUsers from "./SubUsers";
import Generator from "./Generator";
const Settings = ({ location }) => {

  const [tab, setTab] = useState("profile");
  const authValue = JSON.parse(localStorage.getItem("currentUser"));
  const role = localStorage.getItem("role").toLowerCase();

  const handleSelectTab = (eventKey) => {
    if (eventKey === "billing") setTab("billing");
    if (eventKey === "profile") setTab("profile");
    if (eventKey === "subaccounts") setTab("subaccounts");
    if (eventKey === "generator") setTab("generator");
  };

  return (
    <PermissionMenuContext.Consumer>
      {({ userPermissionList }) => (
        <div>
          <Header />
          <Sidebar />
          {/*  my_profile_menus */}
          <div className="main_wrapper">
            <div className="inner_wraapper pt-0">
              <div className="container-fluid">
                <section className="d_text">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-sm-12">
                        <Tabs
                          defaultActiveKey="profile"
                          id="uncontrolled-tab-example"
                          className="mb-3"
                          onSelect={(eventKey) => handleSelectTab(eventKey)}
                        >
                          <Tab eventKey="profile" title="My Profile">
                            {tab === "profile" && (
                              <Profile
                                userPermissionList={userPermissionList}
                                tab={tab}
                              />
                            )}
                          </Tab>
                          {(userPermissionList.includes(
                            "GET ALL SUBSCRIPTION"
                          ) &&
                            role === "company") && (
                              <Tab eventKey="billing" title="Billing">
                                {tab === "billing" && (
                                  <Billing
                                    tab={tab}
                                    userPermissionList={userPermissionList}
                                  />
                                )}
                              </Tab>
                            )}
                          {userPermissionList.includes("CREATE SUB USER") &&
                            authValue?.hasValidPlan &&
                            role === "company" && (
                              <Tab eventKey="subaccounts" title="Sub Accounts">
                                {tab === "subaccounts" && (
                                  <SubUsers
                                    tab={tab}
                                    userPermissionList={userPermissionList}
                                  />
                                )}
                              </Tab>
                            )}
                          {userPermissionList.includes("GET_METER_ID") &&
                            authValue?.hasValidPlan &&
                            role === "company" && (
                              <Tab eventKey="generator" title="Generator">
                                {tab === "generator" && (
                                  <Generator
                                    tab={tab}
                                    userPermissionList={userPermissionList}
                                  />
                                )}
                              </Tab>
                            )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      )}
    </PermissionMenuContext.Consumer>
  );
};

export default Settings;
