/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { sweetAlert } from "../../../../utils/UniversalFunction";
import React, { Component } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import Moment from "react-moment";
import image16 from "../../../../img/image 16.png";
import image17 from "../../../../img/image 17.png";
import image18 from "../../../../img/image 18.png";
import supplyimg from "../../../../img/supply chain mgmt.webp";
// import image19 from "../../../../img/Group 81311.png";
import { NavLink, Link } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Button, Col, Modal, Row } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-select";

import config from "../../../../config/config.json";
import EnviornmentConsideration from "../../Component/Dashboard/enviornment_consideration";
import "./home.css";
import axios from "axios";
import { ExternalLink } from "react-external-link";
import { authenticationService } from "../../../../_services/authentication";
import { Recommendations } from "./Recommendations";
import CircularProgressBar from "./CircularProgressBar";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
// import { Chart } from "react-google-charts";
// import ApexCharts from 'apexcharts';
import ReactApexChart from "react-apexcharts";
import { apiCall } from "../../../../_services/apiCall";

const currentUser = authenticationService.currentUserValue;
// const data = [
//   ["x", "ESG Scrore", "Supplier Risks"],
//   [0, 0, 0],
//   [1, 10, 5],
//   [2, 23, 15],
//   [3, 17, 9],
//   [4, 18, 10],
//   [5, 9, 5],
//   [6, 11, 3],
//   [7, 27, 19],
// ];

// const options = {
//   hAxis: {
//     title: "Quality",
//   },
//   vAxis: {
//     title: "Parameter",
//   },
//   series: {
//     1: { curveType: "function" },
//   },
// };
export default class home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      criteria: [props.location.aboutProps] ?? [],
      isLoaded: true,
      finalIds: [],
      list: [],
      blogs: [],
      innovativeProgram: [],
      subInnovativeProgram: [],
      globalInnovativeProgram: [],
      limit: 10,
      skip: 0,
      seriesDataLength: 2,
      startDate: "",
      endDate: "",
      submitted: false,
      report_type: "",
      csvsubmitted: false,
      firstName: "",
      selectedFramework: [],
      lastName: "",
      graphTitle: "",
      frameworkValue: [],
      show: false,
      uuid: [],
      email: "",
      report: "STANDARD",
      companyName: "",
      totalPercentage: 0,
      percentageData: [],
      items: [],
      frameworkValueData:[],
      authValue: null, 
      businessPerformance: 0,
      esgRiskManagement: 0,
      supplierRiskManagement: 0,
      carbonFootprint: 0,
      sustainableDevelopmentGoals: 0,
      businessPerformanceRecommendation: {},
      esgRiskManagementRecommendation: {},
      supplierRiskManagementRecommendation: {},
      carbonFootprintRecommendation: {},
      sustainableDevelopmentGoalsRecommendation: {},
      esgReportDownloadLink: "",
    };
    this.getPoints = this.getPoints.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    // this.onDateChange = this.onDateChange.bind(this);
    // this.getInnovativeProgrammes = this.getInnovativeProgrammes.bind(this);
    // this.getInnovativeProgrammesbyUserIDForSubUsers =
    //   this.getInnovativeProgrammesbyUserIDForSubUsers.bind(this);
  }

  onFileChange = (event) => {
    this.setState({ csvsubmitted: true });
    let topicId = event.target.getAttribute("data-id");
    const formData = new FormData();

    // Update the formData object
    formData.append("csv", event.target.files[0], event.target.files[0].name);
    formData.append("supplier_management_criteria_ids", topicId);
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
    };
    const { isSuccess, data } = apiCall(
      config.OLD_API_URL + "supplierManagementCSV",
      {},
      formData,
      "POST"
    );
    if (isSuccess) {
      setTimeout(() => {
        const newLocal = "/supplier_management_detail";
        this.props.history.push(newLocal);
      }, 1000);
    }
    // axios
    //   .post(config.OLD_API_URL + "supplierManagementWithCSV", formData, {
    //     headers,
    //   })
    //   .then((response) => {
    //     sweetAlert("success", response.data.message);
    //     setTimeout(() => {
    //       const newLocal = "/supplier_management_detail";
    //       this.props.history.push(newLocal);
    //     }, 1000);
    //   })
    //   .catch(function (response) {
    //     sweetAlert("error", response.data.message);
    //   });
  };
  async fetchGraphs(selectedOption) {
    const { isSuccess, data } = await apiCall(
      config.API_URL + "fetchGraphs",
      {},
      {
        question_type:
          selectedOption === "quantitative_trends"
            ? "quantitative_trends"
            : "tabular_question",
      },
      "GET"
    );
    if (isSuccess && data && data.data && data.data.length > 0) {
      const seriesData = data?.data;
      this.setState({
        seriesDataLength: seriesData,
      });
    } else {
      this.setState({
        errorMessage: "Invalid response",
      });
    }
  }

  getGraphs = () => {
    if (Array.isArray(this.state.seriesDataLength)) {
      return this.state.seriesDataLength.map((seriesData, index) => {
        const options = {
          chart: {
            type: "bar",
            height: 350,
            stacked: true,
            toolbar: {
              show: true,
            },
            zoom: {
              enabled: true,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                legend: {
                  position: "bottom",
                  offsetX: -10,
                  offsetY: 0,
                },
              },
            },
          ],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 10,
              dataLabels: {
                total: {
                  enabled: true,
                  style: {
                    fontSize: "13px",
                    fontWeight: 900,
                  },
                  formatter: function (val) {
                    return val.toFixed(2); // Format the total sum to two decimal places
                  },
                },
              },
            },
          },
          xaxis: {
            categories: seriesData?.xaxis?.range,
            decimalInFloat:2,
          },

          legend: {
            position: "right",
            offsetY: 40,
          },
          fill: {
            opacity: 1,
          },
        };
        console.log(seriesData.series,"seriesData.series")
        return (
          <Col md={12} className="my-3 px-3" key={index}>
            <div className="box-of-all-chart">
              <div id={`chart-${index}`}>
                <ReactApexChart
                  options={options}
                  series={seriesData.series}
                  type="bar"
                  height={350}
                />
              </div>
              <div className="color_rent mb-0">
                <h6 className="color_rent_form">{seriesData.title}</h6>
              </div>
            </div>
          </Col>
        );
      });
    }
  };
  async getPoints() {
    const { isSuccess, data } = await apiCall(
      config.API_URL + "getTotalPoints",
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({
        businessPerformance: data?.points?.businessPerformance,
        esgRiskManagement: data?.points?.esgRiskManagement,
        supplierRiskManagement: data?.points?.supplierRiskManagement,
        carbonFootprint: data?.points?.carbonFootprint,
        sustainableDevelopmentGoals: data?.points?.sdg,
        businessPerformanceRecommendation: data?.points?.isRecommendated
          ?.businessPerformance
          ? JSON.parse(data?.points?.isRecommendated?.businessPerformance)
          : {},
        esgRiskManagementRecommendation: data?.points?.isRecommendated
          ?.esgRiskManagement
          ? JSON.parse(data?.points?.isRecommendated?.esgRiskManagement)
          : {},
        supplierRiskManagementRecommendation: data?.points?.isRecommendated
          ?.supplierRiskManagement
          ? JSON.parse(data?.points?.isRecommendated?.supplierRiskManagement)
          : {},
        carbonFootprintRecommendation: data?.points?.isRecommendated
          ?.carbonFootprint
          ? JSON.parse(data?.points?.isRecommendated?.carbonFootprint)
          : {},
        sustainableDevelopmentGoalsRecommendation: data?.points?.isRecommendated
          ?.sustainableDevelopmentGoals
          ? JSON.parse(
            data?.points?.isRecommendated?.sustainableDevelopmentGoals
          )
          : {},
      });
    } else {
      this.setState({
        isLoaded: true,
      });
    }
    // fetch(config.API_URL + "getTotalPoints", requestOptions)
    //   .then((res) => res.json())
    //   .then(
    //     (data) => {
    //       this.setState({
    //         businessPerformance: data?.points?.businessPerformance,
    //         esgRiskManagement: data?.points?.esgRiskManagement,
    //         supplierRiskManagement: data?.points?.supplierRiskManagement,
    //         carbonFootprint: data?.points?.carbonFootprint,
    //         sustainableDevelopmentGoals: data?.points?.sdg,
    //         businessPerformanceRecommendation: data?.points?.isRecommendated
    //           ?.businessPerformance
    //           ? JSON.parse(data?.points?.isRecommendated?.businessPerformance)
    //           : {},
    //         esgRiskManagementRecommendation: data?.points?.isRecommendated
    //           ?.esgRiskManagement
    //           ? JSON.parse(data?.points?.isRecommendated?.esgRiskManagement)
    //           : {},
    //         supplierRiskManagementRecommendation: data?.points?.isRecommendated
    //           ?.supplierRiskManagement
    //           ? JSON.parse(
    //               data?.points?.isRecommendated?.supplierRiskManagement
    //             )
    //           : {},
    //         carbonFootprintRecommendation: data?.points?.isRecommendated
    //           ?.carbonFootprint
    //           ? JSON.parse(data?.points?.isRecommendated?.carbonFootprint)
    //           : {},
    //         sustainableDevelopmentGoalsRecommendation: data?.points
    //           ?.isRecommendated?.sustainableDevelopmentGoals
    //           ? JSON.parse(
    //               data?.points?.isRecommendated?.sustainableDevelopmentGoals
    //             )
    //           : {},
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //       });
    //     }
    //   );
  }

  // onDateChange(event) {

  //   const target = event.target;
  //   const value = target.value;
  //   const name = target.name;
  //   this.setState({
  //     [name]: value,
  //   });
  //   let setStartDate = null;
  //   let setEndDate = null;
  //   const { isSuccess, data } = apiCall(
  //     config.OLD_API_URL + "supplyChainRiskManagement",
  //     {},
  //     {
  //       limit: this.state.limit,
  //       skip: this.state.skip,
  //       startDate: this.state.startDate,
  //       endDate: this.state.endDate,
  //     },
  //     "GET"
  //   );
  //   if (isSuccess) {
  //     this.setState({
  //       isLoaded: true,
  //       totalCount: data.totalCount,
  //       items: data.data,
  //       list: data.data,
  //       pages: Math.floor(data.data.length / this.state.perPage),
  //       isEditableOrNot: data?.insertOrUpdate,
  //     });
  //   } else {
  //     this.setState({
  //       isLoaded: true,
  //     });
  //   }
  //   // fetch(
  //   //   config.OLD_API_URL +
  //   //     `supplyChainRiskManagement?limit=${this.state.limit}&skip=${this.state.skip}&startDate=${setStartDate}&endDate=${setEndDate}`,
  //   //   { headers }
  //   // )
  //   //   .then((res) => res.json())
  //   //   .then(
  //   //     (result) => {
  //   //       this.setState({
  //   //         isLoaded: true,
  //   //         totalCount: result.totalCount,
  //   //         items: result.data,
  //   //         list: result.data,
  //   //         pages: Math.floor(result.data.length / this.state.perPage),
  //   //         isEditableOrNot: result?.insertOrUpdate,
  //   //       });
  //   //     },
  //   //     (error) => {
  //   //       this.setState({
  //   //         isLoaded: true,
  //   //         error,
  //   //       });
  //   //     }
  //   //   );
  // }

  getInnovativeProgrammes(uuid) {
    fetch(config.API_URL + `getInnovativeProgrammesByUUID/${uuid}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            innovativeProgram: result.innovativeProgram,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  getInnovativeProgrammesbyUserIDForSubUsers(id) {
    fetch(config.API_URL + `getInnovativeProgrammesbyUserIDForSubUsers/${id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            subInnovativeProgram: result.innovativeProgram,
          });
        },
        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }
  handleDownload = async (url) => {
    console.log(url);
    try {
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank"; // Open the file in a new tab/window
      link.rel = "noopener noreferrer"; // Set rel attribute for security

      link.download = "filename"; // Specify the desired file name here
      link.click();
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  async handleConfirm() {
    console.log(this.state.selectedFramework);
    const frameworkIds =
      this.state.selectedFramework &&
      this.state.selectedFramework?.map(({ id }) => id);
    const framework = frameworkIds.join(",");
    console.log(frameworkIds);
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}generateReport`,
      {},
      {
        report_type: this.state.report_type,
        framework_id: [framework],
        financial_year_id: this.state.financialYearId,
        report_mode: this.state.report,
      }
    );
    if (isSuccess) {
      this.handleDownload(data?.data?.url);
    }
  }
  getTotalPerformance() {
    let total =
      this.state.businessPerformance +
      this.state.esgRiskManagement +
      this.state.supplierRiskManagement +
      this.state.carbonFootprint +
      this.state.sustainableDevelopmentGoals;
    if (total <= 450) {
      return "Needs Improvement";
    } else if (total <= 800) {
      return "Average";
    } else if (total <= 1000) {
      return "Excellent";
    }
  }
  getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getFinancialYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({ financialYear: data.data });
    }
  };
  getFrameworkAnsweredQuestionPercentage = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}frameworkAnsweredQuestionPercentage`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({ percentageData: data.data });
    }
  };
  getAnsweredQuestionPercent = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getAnsweredQuestionPercent`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      console.log(" getAnsweredQuestionPercent", data.data);
      this.setState({ totalPercentage: data.data });
    }
  };
  async componentDidMount() {
    const authValue = JSON.parse(localStorage.getItem("currentUser"));
    this.setState({ authValue });
    this.getFrameworkAnsweredQuestionPercentage();
    this.getAnsweredQuestionPercent();
    this.fetchGraphs();
    // this.downloadESGReport();
    // const requestOptions = {
    //   method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("token")}`,
    //     "Content-Type": "application/json",
    //   },
    // };
    this.getPoints();
    const { isSuccess: isSuccessBlogs, data: blogsData } = await apiCall(
      config.API_URL + "blogs",
      {},
      {},
      "GET"
    );
    if (isSuccessBlogs) {
      this.setState({
        isLoaded: false,
        blogs: blogsData?.result,
      });
    } else {
      this.setState({
        isLoaded: true,
      });
    }
    const { isSuccess: isSuccessPrograms, data: programsData } = await apiCall(
      config.API_URL + "getGlobalInnovativeProgrammes",
      {},
      {},
      "GET"
    );
    if (isSuccessPrograms) {
      this.setState({
        isLoaded2: true,
        globalInnovativeProgram: programsData.innovativeProgram,
      });
    } else {
      this.setState({
        isLoaded2: true,
      });
    }

    // const { isSuccess: isSuccessProfile, data: profileData } = await apiCall(
    //   config.API_URL + "getProfileData",
    //   {},
    //   {},
    //   "GET"
    // );
    // if (isSuccessProfile) {
    //   this.setState({
    //     isLoaded2: true,
    //     id: profileData?.user[0]?.id,
    //     uuid: +localStorage.getItem("user_temp_id"),
    //   });
    //   // this.getInnovativeProgrammes(localStorage.getItem("user_temp_id"));
    //   // this.getInnovativeProgrammesbyUserIDForSubUsers(profileData?.user[0]?.id);
    // } else {
    //   this.setState({
    //     isLoaded2: true,
    //   });
    // }
    // fetch(`${config.API_URL}getProfileData?userId=${localStorage.getItem("user_temp_id")}`, requestOptions)
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       this.setState({
    //         isLoaded2: true,
    //         id: result.user[0]?.id,
    //         uuid: +localStorage.getItem('user_temp_id'),
    //       });
    //       this.getInnovativeProgrammes(localStorage.getItem('user_temp_id'));
    //       this.getInnovativeProgrammesbyUserIDForSubUsers(result.user[0]?.id);
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded2: true,
    //         error,
    //       });
    //     }
    //   );
    // const { isSuccess: isSuccessSupply, data: supplyData } = await apiCall(
    //   config.OLD_API_URL + "supplyChainRiskManagement",
    //   {},
    //   {
    //     limit: this.state.limit,
    //     skip: this.state.skip,
    //     startDate: this.state.startDate,
    //     endDate: this.state.endDate,
    //   },
    //   "GET"
    // );
    // if (isSuccessSupply) {
    //   this.setState({
    //     isLoaded: true,
    //     totalCount: supplyData.totalCount ?? 0,
    //     items: supplyData.data,
    //     list: supplyData.data,
    //     pages: Math.floor(supplyData.data.length / this.state.perPage),
    //     isEditableOrNot: supplyData?.insertOrUpdate,
    //   });
    // } else {
    //   this.setState({
    //     isLoaded: true,
    //   });
    // }
    // fetch(
    //   config.OLD_API_URL +
    //     `supplyChainRiskManagement?limit=${this.state.limit}&skip=${this.state.skip}&startDate=${this.state.startDate}&endDate=${this.state.endDate}`,
    //   requestOptions
    // )
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       this.setState({
    //         isLoaded: true,
    //         totalCount: result.totalCount ?? 0,
    //         items: result.data,
    //         list: result.data,
    //         pages: Math.floor(result.data.length / this.state.perPage),
    //         isEditableOrNot: result?.insertOrUpdate,
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       });
    //     }
    //   );
  }
  handleClose = () => this.setState({ show: false });
  handleFinancialYearChange = async (e) => {
    const fId = e.target.value;
    await this.fetchStoredData(fId);
    this.setState({ financialYearId: fId });
    // await this.fetchStoredData(fId)
  };
  fetchFrameworkApi = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getFramework`,
      {},
      { type: "ALL" }
    );
    if (isSuccess) {
      console.log(data?.data,"data?.data")
      this.setState({
        frameworkValue: data?.data,
      });
      const modifiedOptions = data?.data.map((obj) => ({
        value: obj.id,
        label: obj.title,
      }));
      this.setState({ options: modifiedOptions });
    }
  };
  fetchStoredData = async (fId) => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getDataCompanyWise`,
      {},
      { type: "SQ", financial_year_id: fId },
      "GET"
    );
    if (isSuccess) {
      const companyFremWorkData = JSON.parse(data?.data?.framework_topic_kpi)?.framework_id;
      const filteredObjects = this.state.frameworkValue?.filter(obj => companyFremWorkData?.includes(obj.id));
      this.setState({
        frameworkValueData: filteredObjects,
      });
  };
}
  async downloadESGReport() {
    // const token = JSON.parse(localStorage.getItem('currentUser'));
    const { isSuccess, data } = await apiCall(
      config.API_URL + "generateReport",
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({ esgReportDownloadLink: data?.data?.data?.url });
    }
    // axios.get(`${config.API_URL}generateReport`,{
    //   headers:{
    //     Authorization:`Bearer ${localStorage.getItem("token")}`
    //   }
    // }).then((response) =>{
    //   console.log(response,"response");
    //   this.setState({esgReportDownloadLink:response?.data?.data?.url})
    //   console.log(response?.data?.data?.url,"esgReportDownloadLink");
    // }).catch((error) =>{
    //   console.log(error,"error");
    // })
  }

  render() {
    const {
      // blogs,
      seriesDataLength,
      percentageData,
      seriesMultiStake,
      businessPerformance,
      esgRiskManagement,
      supplierRiskManagement,
      carbonFootprint,
      sustainableDevelopmentGoals,
    } = this.state;

    // const businessPerformanceText =
    //   businessPerformance >= 0 && businessPerformance < 131
    //     ? "Below Average"
    //     : businessPerformance > 130 && businessPerformance < 171
    //       ? "Average"
    //       : businessPerformance > 170 && businessPerformance < 251
    //         ? "Above Average"
    //         : "None";
    // const esgRiskManagementText =
    //   esgRiskManagement >= 0 && esgRiskManagement < 131
    //     ? "Below Average"
    //     : esgRiskManagement > 130 && esgRiskManagement < 171
    //       ? "Average"
    //       : esgRiskManagement > 170 && esgRiskManagement < 251
    //         ? "Above Average"
    //         : "None";
    // const supplierRiskManagementText =
    //   supplierRiskManagement >= 0 && supplierRiskManagement < 131
    //     ? "Below Average"
    //     : supplierRiskManagement > 130 && supplierRiskManagement < 171
    //       ? "Average"
    //       : supplierRiskManagement > 170 && supplierRiskManagement < 251
    //         ? "Above Average"
    //         : "None";
    // const carbonFootprintText =
    //   carbonFootprint >= 0 && carbonFootprint < 41
    //     ? "Below Average"
    //     : carbonFootprint > 40 && carbonFootprint < 71
    //       ? "Average"
    //       : carbonFootprint > 70 && carbonFootprint < 151
    //         ? "Above Average"
    //         : "None";
    // const sustainableDevelopmentGoalsText =
    //   sustainableDevelopmentGoals >= 0 && sustainableDevelopmentGoals < 41
    //     ? "Below Average"
    //     : sustainableDevelopmentGoals > 40 && sustainableDevelopmentGoals < 61
    //       ? "Average"
    //       : sustainableDevelopmentGoals > 60 && sustainableDevelopmentGoals < 101
    //         ? "Above Average"
    //         : "None";

    const {
      list,
      report,
      totalCount,
      innovativeProgram,
      globalInnovativeProgram,
      subInnovativeProgram,
      handleClose,
    } = this.state;
    let weathers =
      list.map((item, keys) => {
        return (
          <tr key={keys}>
            <td>{keys + 1}</td>
            <td>{item.companyName}</td>
            <td>
              <Moment format="DD/MM/YYYY">{item.createdAt}</Moment>
            </td>
            <td>{item.followingCriteria}</td>
            <td>
              <span className="red bold center">
                {item.identified_risk === "High" && "High"}
              </span>
              <span className="green bold center">
                {item.identified_risk === "Low" && "Low"}
              </span>
              <span className="bold center">
                {item.identified_risk === "Medium" && "Medium"}
              </span>
            </td>
          </tr>
        );
      }) || "";

    // let total =
    //   this.state.businessPerformance +
    //   this.state.esgRiskManagement +
    //   this.state.supplierRiskManagement +
    //   this.state.carbonFootprint +
    //   this.state.sustainableDevelopmentGoals;
    // let final = total / 10 + "%";

    return (
      <div>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="dashvord_aling">
                    <div className="row">
                      {/* <div className="col-12">
                        <div className="esg_score_title">
                          My ESG Performance
                        </div>
                        <div
                          className="color_div"
                          style={{ marginBottom: "20px" }}
                        >
                          <CircularProgressBar
                            score={
                              businessPerformance +
                              esgRiskManagement +
                              supplierRiskManagement +
                              carbonFootprint +
                              sustainableDevelopmentGoals
                            }
                          />
                        </div>
                      </div> */}
                      <div className="col-xxl-6 col-lg-12 col-sm-12 col-12">
                        <div className="esg_score_title">
                          My Disclosure Progress
                        </div>
                        <div className="color_div p-0">
                          <div className="main_text mb-4">
                            {/* <div className="current_score">
                              <div className="score">
                                <div
                                  className="half-arc"
                                  style={{ "--percentage": `${final}` }}
                                >
                                  <span id="value_graph" className="label">
                                    {businessPerformance +
                                      esgRiskManagement +
                                      supplierRiskManagement +
                                      carbonFootprint +
                                      sustainableDevelopmentGoals}
                                    /1000
                                  </span>
                                </div>
                                <h4 className="performance">
                                  {this.getTotalPerformance()}
                                </h4>
                                <div className="score_dashboard"></div>
                                <div className="sore_ligh_background"></div>
                              </div>
                            </div> */}
                            {/* <CircularProgressBar
                              score={
                                businessPerformance +
                                esgRiskManagement +
                                supplierRiskManagement +
                                carbonFootprint +
                                sustainableDevelopmentGoals
                              }
                            /> */}
                            <div
                              style={{
                                width: "100%",
                                height: 240,
                                margin: "28px 0",
                              }}
                            >
                              <CircularProgressbar
                                value={this.state.totalPercentage}
                                text={`${this.state.totalPercentage}%`}
                              />
                              ;
                            </div>
                            {/* <div
                              className="esg_score_title module_button_download"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {60}% Completed Form.
                            </div> */}
                          </div>
                          {/* <div className="Download_esg_report">
                            
                          </div> */}
                        </div>
                      </div>
                      <div className="col-xxl-6 col-lg-12 col-sm-12 col-12">
                        <div className="color_div p-0">
                          <div className="main_text mb-4">
                            {/* <div className="current_score">
                              <div className="score">
                                <div
                                  className="half-arc"
                                  style={{ "--percentage": `${final}` }}
                                >
                                  <span id="value_graph" className="label">
                                    {businessPerformance +
                                      esgRiskManagement +
                                      supplierRiskManagement +
                                      carbonFootprint +
                                      sustainableDevelopmentGoals}
                                    /1000
                                  </span>
                                </div>
                                <h4 className="performance">
                                  {this.getTotalPerformance()}
                                </h4>
                                <div className="score_dashboard"></div>
                                <div className="sore_ligh_background"></div>
                              </div>
                            </div> */}
                            {/* <CircularProgressBar
                              score={
                                businessPerformance +
                                esgRiskManagement +
                                supplierRiskManagement +
                                carbonFootprint +
                                sustainableDevelopmentGoals
                              }
                            /> */}

                            {/* <div
                              className="esg_score_title module_button_download"
                              style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              {60}% Completed Form.
                            </div> */}
                            <div className="tabele_div">
                              {this.state.authValue?.is_head === 1 && <div className="esg_score_title module_button_download" style={{ padding: "7px" }}>
                                Module
                                <button
                                  onClick={() => {
                                    this.setState({ show: true });
                                    this.getFinancialYear();
                                    this.fetchFrameworkApi();
                                    this.setState({ selectedFramework: [] });
                                  }}
                                  className="btn btn esgDownloadReporting "
                                  href="#"
                                // onClick={this.downloadESGReport}
                                >
                                  {/* <a
                                    href={`${this.state.esgReportDownloadLink}`}
                                  >
                                    {" "}
                                  </a> */}
                                  Download your complete ESG Report
                                </button>
                              </div>}
                              <Modal size="md" show={this.state.show} onHide={this.handleClose}>
                                <Modal.Header closeButton>
                                  <label className="align-items-center"> ESG REPORT </label>
                                </Modal.Header>
                                <Modal.Body>
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="form-group">
                                        <label
                                          htmlFor="industryType"
                                          className="mb-2 align-items-center"
                                        > Select Financial Year </label>
                                        <select
                                          name="tab_name"
                                          className="form-control input-height"
                                          onChange={
                                            this.handleFinancialYearChange
                                          }
                                        >
                                          <option> Select Financial Year </option>
                                          {this.state.financialYear?.map(
                                            (item, key) => (
                                              <option
                                                key={key}
                                                value={item.id}
                                              >
                                                {
                                                  item.financial_year_value
                                                }
                                              </option>
                                            )
                                          )}
                                        </select>
                                      </div>
                                      <div className=" mt-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor="industryType"
                                            className="mb-2 align-items-center d-flex w-100"
                                          > File Type </label>
                                          <select
                                            name="tab_name"
                                            onChange={(e) =>
                                              this.setState({
                                                report_type: e.target.value,
                                              })
                                            }
                                            className="form-control input-height"
                                          >
                                            <option> Select File Type  </option>
                                            <option value="EXCEL"> EXCEL </option>
                                            <option value="PDF">PDF</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className=" mt-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor="industryType"
                                            className="mb-2 align-items-center d-flex w-100"
                                          > Report Type </label>
                                          <select
                                            name="tab_name"
                                            onChange={(e) =>
                                              this.setState({
                                                report: e.target.value,
                                              })
                                            }
                                            className="form-control input-height"
                                          >
                                            <option value="STANDARD"> STANDARD </option>
                                            <option value="CUSTOM"> CUSTOM </option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className=" mt-3">
                                        <div className="form-group pb-3">
                                          <label htmlFor="industryType" className="mb-2 align-items-center d-flex w-100" > Select Framework </label>
                                          <Multiselect
                                            placeholder={
                                              "Select Framework"
                                            }
                                            displayValue="title"
                                            options={
                                              this.state.report ===
                                                "STANDARD" &&
                                                this.state.frameworkValueData
                                                  ?.length > 0
                                                ? [
                                                  this.state.frameworkValueData[0],
                                                ]
                                                : this.state.frameworkValueData
                                            }
                                            selectedValues={
                                              this.state
                                                .selectedFramework
                                            }
                                            // ref={this.multiselectRefTracker}
                                            onRemove={(e) => {
                                              this.setState({
                                                selectedFramework:
                                                  e || [],
                                              });
                                            }}
                                            onSelect={(e) => {
                                              this.setState({
                                                selectedFramework:
                                                  e || [],
                                              });
                                            }}
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="save_global download_report" >
                                          <button className="page_save page_width mx-3"
                                            variant="none"
                                            onClick={() => {
                                              this.setState({
                                                show: false,
                                              });
                                              this.handleConfirm();
                                            }} > Download </button>
                                        </div> */}
                                    </div>
                                  </div>
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="primary"
                                    className="new_button_style"
                                    onClick={() => {
                                      this.setState({
                                        show: false,
                                      });
                                      this.handleConfirm();
                                    }}
                                  > Download </Button>
                                </Modal.Footer>
                              </Modal>
                              {/* <Modal
                                show={this.state.show}
                                // animation={true}
                                size="fullscreen"
                                className="modal_box"
                                shadow-lg="border"
                              >
                                <div className="modal-lg">
                                  <Modal.Header className="pb-0">
                                    <label className="mb-2 align-items-center d-flex w-100">
                                      {" "}
                                      ESG REPORT
                                    </label>

                                    <Button
                                      variant="outline-dark"
                                      onClick={() =>
                                        this.setState({ show: false })
                                      }
                                    >
                                      <i className="fa fa-times"></i>
                                    </Button>
                                  </Modal.Header>
                                  <Modal.Body>
                                    <div className="modal-body vekp pt-0">
                                      <div className="row">
                                        <div className="col-md-12">
                                          <div className="color_div_on framwork_2">
                                            <div className="col-lg-8 col-xs-8">
                                              <div className="form-group pb-3 d-flex">
                                                <label
                                                  htmlFor="industryType"
                                                  className="mb-2 align-items-center d-flex w-100"
                                                >
                                                  {" "}
                                                  Select Financial Year{" "}
                                                </label>
                                                <select
                                                  name="tab_name"
                                                  onChange={
                                                    this
                                                      .handleFinancialYearChange
                                                  }
                                                  className="select_one industrylist"
                                                >
                                                  <option>
                                                    Select Financial Year
                                                  </option>
                                                  {this.state.financialYear?.map(
                                                    (item, key) => (
                                                      <option
                                                        key={key}
                                                        value={item.id}
                                                      >
                                                        {
                                                          item.financial_year_value
                                                        }
                                                      </option>
                                                    )
                                                  )}
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-lg-8 col-xs-8">
                                              <div className="form-group pb-3 d-flex">
                                                <div className="row w-100">
                                                  <div className="col-12 col-lg-6">
                                                    <label
                                                      htmlFor="industryType"
                                                      className="mb-2 align-items-center d-flex w-100 col-md-4"
                                                    >
                                                      {" "}
                                                      Select Framework{" "}
                                                    </label>
                                                  </div>

                                                  <div className="col-12 col-lg-6">
                                                    <Multiselect
                                                      placeholder={
                                                        "Select Framework"
                                                      }
                                                      displayValue="title"
                                                      options={
                                                        this.state
                                                          .frameworkValue
                                                      }
                                                      selectedValues={
                                                        this.state
                                                          .selectedFramework
                                                      }
                                                      // ref={this.multiselectRefTracker}
                                                      onRemove={(e) => {
                                                        this.setState({
                                                          selectedFramework:
                                                            e || [],
                                                        });
                                                      }}
                                                      onSelect={(e) => {
                                                        this.setState({
                                                          selectedFramework:
                                                            e || [],
                                                        });
                                                      }}
                                                    />
                                                  </div>
                                                </div>

                                                <div className="row"></div>
                                              </div>
                                            </div>
                                            <div className="col-lg-8 col-xs-8 mt-3">
                                              <div className="form-group pb-3 d-flex">
                                                <label
                                                  htmlFor="industryType"
                                                  className="mb-2 align-items-center d-flex w-100"
                                                >
                                                  {" "}
                                                  File Type{" "}
                                                </label>
                                                <select
                                                  name="tab_name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      report_type:
                                                        e.target.value,
                                                    })
                                                  }
                                                  className="select_one industrylist"
                                                >
                                                  <option>
                                                    Select File Type
                                                  </option>
                                                  <option value="EXCEL">
                                                    EXCEL
                                                  </option>
                                                  <option value="PDF">
                                                    PDF
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div className="col-lg-8 col-xs-8 mt-3">
                                              <div className="form-group pb-3 d-flex">
                                                <label
                                                  htmlFor="industryType"
                                                  className="mb-2 align-items-center d-flex w-100"
                                                >
                                                  {" "}
                                                  Report Type{" "}
                                                </label>
                                                <select
                                                  name="tab_name"
                                                  onChange={(e) =>
                                                    this.setState({
                                                      report: e.target.value,
                                                    })
                                                  }
                                                  className="select_one industrylist"
                                                >
                                                  <option value="STANDARD">
                                                    STANDARD
                                                  </option>
                                                  <option value="CUSTOM">
                                                    CUSTOM
                                                  </option>
                                                </select>
                                              </div>
                                            </div>
                                            <div
                                              className="save_global download_report"
                                              style={{
                                                justifyContent:
                                                  "right !important",
                                              }}
                                            >
                                              <button
                                                className="page_save page_width mx-3"
                                                variant="none"
                                                onClick={() => {
                                                  this.setState({
                                                    show: false,
                                                  });
                                                  this.handleConfirm();
                                                }}
                                              >
                                                {" "}
                                                Download{" "}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal.Body>
                                </div>
                              </Modal> */}
                              <div className="dashboard_score_div">
                                {percentageData.map((item, key) => (
                                  <div className="inter_fats">
                                    <div className="busins_point">
                                      <div className="bussins">
                                        <p className="prbus">{item?.title}</p>
                                      </div>

                                      <div className="text_number">
                                        <p className="nam_hol">
                                          {item?.percentage} Score
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                          {/* <div className="Download_esg_report">
                            
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="col-xxl-6 col-lg-12 col-sm-12 col-12">
                        <div className="row_two_hol">
                          <div className="esg_score_title">
                            Key Supplier Risks
                          </div>
                          <div
                            className={
                              totalCount === 0
                                ? "hideData"
                                : "color_div_key div_scroll key_div"
                            }
                          >
                            <div className="table_f manage-detail admin-risk-table table-responsive">
                              <Table striped bordered hover size="sm">
                                <thead>
                                  <tr className="heading_color">
                                    <th>ID</th>
                                    <th>SUPPLIER</th>
                                    <th>DATE ADDED</th>
                                    <th>CRITERIA &nbsp; ASSESSED</th>
                                    <th>IDENTIFIED RISK</th>
                                  </tr>
                                </thead>
                                <tbody>{weathers}</tbody>
                              </Table>
                            </div>
                          </div>

                          <div
                            className={
                              totalCount === 0 ? "color_div_key" : "hideData"
                            }
                          >
                            <div className="text_busses">
                              <div className="your_business"></div>
                            </div>
                            <div className="table_row">
                              <div className="image-upload-wrap">
                                <input
                                  type="file"
                                  className="file-upload-input"
                                  name="supplierData"
                                  accept=".csv"
                                  onChange={this.onFileChange}
                                />
                                <div className="drag-text">
                                  <i className="far fa-file-alt"></i>
                                  <h3>Upload Your Supplier Details</h3>
                                </div>
                              </div>
                              <div className="text-outside">
                                <p>
                                  Upload or drag &amp; drop your <br />
                                  documents for key supplier risks
                                </p>
                              </div>
                              <div className="meettable mt-3">
                                <Link
                                  to="../../../../../../template.xlsx"
                                  target="_blank"
                                  className="page_save_min page_width_mini m-0"
                                  type="button"
                                >
                                  Download Template
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row_two_hol">
                          <div className="color_div _q tabbing_enviormental_div">
                            <div className="tabbedPanels">
                              <div className="panelContainer">
                                <EnviornmentConsideration />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row_two_hol">
                          <div className="esg_score_title">
                            Recommended Innovative Programmes for you
                          </div>
                          <div className="color_div_Programs div_scroll recommend_div">
                            <div className="vertical_row">
                              <div className="progreas">
                                <div className="text_pros">
                                  <ul className="sp_po">
                                    {globalInnovativeProgram.map(
                                      (item, key) => (
                                        <li key={key}>
                                          <span className="pol">
                                            <strong>{item.title}: </strong>
                                          </span>
                                          <span className="ancy">
                                            {item.description}
                                          </span>
                                          <br />
                                          <div className="my-2 pol1">
                                            Date :{" "}
                                            <Moment
                                              format="DD-MMM-YYYY"
                                              withTitle
                                            >
                                              {item.created_at}
                                            </Moment>
                                          </div>
                                        </li>
                                      )
                                    )}
                                    {innovativeProgram.map((item, key) => (
                                      <li key={key}>
                                        <span className="pol">
                                          <strong>{item.title}: </strong>
                                        </span>
                                        <span className="ancy">
                                          {item.description}
                                        </span>
                                        <br />
                                        <div className="my-2 pol1">
                                          Date :{" "}
                                          <Moment
                                            format="DD-MMM-YYYY"
                                            withTitle
                                          >
                                            {item.created_at}
                                          </Moment>
                                        </div>
                                      </li>
                                    ))}
                                    {subInnovativeProgram.map((item, key) => (
                                      <li key={key}>
                                        <span className="pol">
                                          <strong>{item.title}: </strong>
                                        </span>
                                        <span className="ancy">
                                          {item.description}
                                        </span>
                                        <br />
                                        <div className="my-2 pol1">
                                          Date :{" "}
                                          <Moment
                                            format="DD-MMM-YYYY"
                                            withTitle
                                          >
                                            {item.created_at}
                                          </Moment>
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <!-- new add --> */}
                  <div className="">
                    <div className="color_div_Current">
                      <div className="row">
                        <div className="col-md-6 d-flex align-items-center justify-content-start">
                          <div className="color_rent mb-0">
                            <h6 className="home_text font-heading m-0">
                              Your Sustainability Performance Index
                            </h6>
                          </div>
                        </div>

                        <div className="col-md-6 d-flex  justify-content-end">
                          <div className="color_rent mb-0">
                            <select className="dropdown select_one_all"
                              onChange={(event) =>
                                this.fetchGraphs(event.target.value)
                              }
                            >
                              {/* Add your dropdown options here */}
                              <option value="tabular">Tabular</option>
                              <option value="quantitative_trends">
                                Quantitative Trends
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <Row>
                        {/* {  console.log("length",this.state.seriesDataLength,this.state.series)}
                        { <div className="col-md-6">
                           
                           <div id="chart">
                             <ReactApexChart
                               options={this.state.options}
                               series={this.state.series}
                               type="line"
                               height={350}
                             />
                           </div>
                           <div className="color_rent mb-0">
                             <h6 className="color_rent_form">
                               {this.state.graphTitle}
                             </h6>
                           </div>
                         </div>} */}
                        {this.getGraphs()}

                        {/* {seriesMultiStake.length > 0 && (
                          <div className="col-md-6">
                            <div id="chart">
                              <ReactApexChart
                                options={this.state.optionsMultiStake}
                                series={this.state.seriesMultiStake}
                                type="bar"
                                height={350}
                              />
                            </div>
                            <div className="color_rent mb-0">
                              <h6 className="color_rent_form">
                                {this.state.graphTitle}
                              </h6>
                            </div>
                          </div>
                        )} */}
                        {/* {
                      this.state.seriesDataLength != 2 &&
                         <div className="col-md-6">
                            <div id="chart">
                              <ReactApexChart
                                options={this.state.optionsMultiStake}
                                series={this.state.seriesMultiStake}
                                type="bar"
                                height={350}
                              />
                            </div>
                            <div className="color_rent mb-0">
                              <h6 className="color_rent_form">
                                {this.state.graphTitle}
                              </h6>
                            </div>
                          </div>
                     } */}
                        {/* {this.state.seriesMultiStake.length == "2" ? (
                          
                          <div className="col-md-6">
                           
                            <div id="chart">
                              <ReactApexChart
                                options={this.state.options}
                                series={this.state.series}
                                type="line"
                                height={350}
                              />
                            </div>
                            <div className="color_rent mb-0">
                              <h6 className="color_rent_form">
                                {this.state.graphTitle}
                              </h6>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-6">
                            <div id="chart">
                              <ReactApexChart
                                options={this.state.optionsMultiStake}
                                series={this.state.seriesMultiStake}
                                type="bar"
                                height={350}
                              />
                            </div>
                            <div className="color_rent mb-0">
                              <h6 className="color_rent_form">
                                {this.state.graphTitle}
                              </h6>
                            </div>
                          </div>
                        )} */}
                      </Row>
                      {/* <hr className="mt-5"></hr>
                      <div className="row">
                        <div className="col-md-6">
                        
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="line"
                              height={350}
                            />
                          </div>
                          <div className="color_rent mb-0">
                            <h6 className="color_rent_form">
                              Total Water Withdrawal (ML)
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                         
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="line"
                              height={350}
                            />
                          </div>
                          <div className="color_rent mb-0">
                            <h6 className="color_rent_form">
                              Water Recycled/Reused (ML)
                            </h6>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-5"></hr>
                      <div className="row">
                        <div className="col-md-6">
                         
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="line"
                              height={350}
                            />
                          </div>
                          <div className="color_rent mb-0">
                            <h6 className="color_rent_form">
                              Hazardous Wasted Generated (MT)
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                          
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="line"
                              height={350}
                            />
                          </div>
                          <div className="color_rent mb-0">
                            <h6 className="color_rent_form">
                              Hazardous Wasted Disposed (MT)
                            </h6>
                          </div>
                        </div>
                      </div>
                      <hr className="mt-5"></hr>
                      <div className="row">
                        <div className="col-md-6">
                         
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="line"
                              height={350}
                            />
                          </div>
                          <div className="color_rent mb-0">
                            <h6 className="color_rent_form">
                              Total Waste Generated (MT)
                            </h6>
                          </div>
                        </div>
                        <div className="col-md-6">
                         
                          <div id="chart">
                            <ReactApexChart
                              options={this.state.options}
                              series={this.state.series}
                              type="line"
                              height={350}
                            />
                          </div>
                          <div className="color_rent mb-0">
                            <h6 className="color_rent_form">
                              Total Waste Disposed (MT)
                            </h6>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  {/* <div className="row_two_hol">
                    <div className="color_div_Current">
                      <div className="color_rent mb-0">
                        <h6 className="home_text font-heading pb-0">Media</h6>
                      </div>
                      <div className="row">
                      
                        <div
                         
                          className="col-xxl-4 col-lg-6 col-md-12 col-12 mt-5"
                        >
                          <div className="row" style={{ alignItems: "center" }}>
                            <div className="col-sm-6">
                              <div className="curren">
                                <div className="curren_text traning_image">
                                  <a target="_blank" rel="noreferrer" href="#">
                                    <img src={supplyimg} />
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="curren">
                                <div className="curren_hop">
                                  <p className="dete_time">
                                    <Moment format="MMM YYYY" withTitle>
                                      Dec 2022
                                    </Moment>
                                  </p>

                                  <a target="_blank" rel="noreferrer" href="#">
                                    <h4 className="Austr_text mt-3">
                                      Real-Time Supply Chain ESG management
                                    </h4>
                                  </a>
                                  <p className="Pacific">Zais Admin</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    
                      <div className="flop_two">
                        <div className="comleted_dell home-align mt-3">
                          <NavLink to="/training">view all</NavLink>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="row">
                    <div className="col-sm-12">
                      <div className="row_two_hol">
                        <div className="color_div_KATE">
                          <div className="color_rent mb-5">
                            <h6 className="home_text font-heading pb-0">
                              Training
                            </h6>
                          </div>
                          <div className="three_box">
                            <div className="row">
                              <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                              
                                <ExternalLink
                                  href="https://lms.zais.tech/dashboard/"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp">
                                    <div className="card-block image">
                                      <h6 className="text-muted">Academy</h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image16}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text card_white">
                                          New to the platform?
                                        </p>
                                        <p className="card-text card_white">
                                          Get started with our Introductory
                                          video Courses.
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </ExternalLink>
                             
                              </div>
                              <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                             
                                <NavLink
                                  to="/coaching_form"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp op">
                                    <div className="card-block image">
                                      <h6 className="text-muted">Coaching</h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image17}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text card_white">
                                          Find your coach &amp; <br />
                                          accelerate your learning
                                          <br /> &amp; reporting.
                                        </p>
                                      
                                      </div>
                                    </div>
                                  </article>
                                </NavLink>
                          
                              </div>
                              <div className="col-xxl-4 col-lg-6 col-md-12 col-12">
                              
                                <ExternalLink
                                  href="https://lms.zais.tech/dashboard/"
                                  className="card_anchor"
                                >
                                  <article className="card animated fadeInUp oplo">
                                    <div className="card-block image">
                                      <h6 className="text-muted">
                                        Continuing Professional <br />
                                        Development
                                      </h6>
                                    </div>
                                    <div className="four_box">
                                      <img
                                        className="imagev_icon"
                                        src={image18}
                                        alt=""
                                      />
                                    </div>
                                    <div className="card-block">
                                      <div className="text_bill">
                                        <p className="card-text card_white">
                                          See content exclusively for
                                          <br /> our members
                                        </p>
                                      </div>
                                    </div>
                                  </article>
                                </ExternalLink>
                             
                              </div>
                            </div>
                          </div>
                          <div className="comleted_dell home-align mt-3">
                            <NavLink to="/training">view all</NavLink>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  */}
                </div>
              </section>
            </div>
          </div>
        </div >
      </div >
    );
  }
}
