import React, { useState, useCallback } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { useLocation } from "react-router-dom";
import { Button, Col, Form, Modal, Nav, Row, Spinner } from "react-bootstrap";
import AddQuestionAssessment from "./addQuestionAssessment";
import QuestionTypeTabSection from "../../Component/Sector Questions/QuestionTypeTabSection";
import Multiselect from "multiselect-react-dropdown";

const AssessmentDetail = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);

  return (
    <>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <section className="d_text">
            <div className="container-fluid">
              <form name="form">
                <div className="select-reporting-criteria">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="Reporting_heading">
                      <h1> Please Select Financial Year </h1>
                    </div>
                    <div className="d-flex dropdown align-items-center">
                      <p className="m-0"> Financial Year : </p>
                      <select className="select_one_all">
                        <option value="1">2022</option>
                        <option value="1">2023</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
              {loading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <span>Loading Questionnaire</span>
                  <Spinner
                    style={{ marginLeft: "13px" }}
                    animation="border"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <>
                  <Row>
                    <Col md={12}>
                      <div className="select-reporting-criteria">
                        <div className="question_section">
                          <div className="d-flex align-items-center justify-content-between mb-2">
                            <div className="Reporting_heading">
                              <h1>Assessment Questions </h1>
                            </div>
                            <div>
                              <Button
                                className="new_button_style mx-2"
                                onClick={handleShow}
                              >
                                Import
                              </Button>
                              <Button
                                className="new_button_style"
                                onClick={handleShow1}
                              >
                                Add Question
                              </Button>
                            </div>
                          </div>
                          <hr
                            className="mt-0"
                            style={{ color: "#00000045" }}
                          ></hr>
                          <QuestionTypeTabSection />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>
          </section>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Import Question Assessment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Multiselect
                displayValue="key"
                onKeyPressFn={function noRefCheck() {}}
                onRemove={function noRefCheck() {}}
                onSearch={function noRefCheck() {}}
                onSelect={function noRefCheck() {}}
                options={[
                  {
                    cat: "Group 1",
                    key: "Assessment 1",
                  },
                  {
                    cat: "Group 1",
                    key: "Assessment 2",
                  },
                  {
                    cat: "Group 1",
                    key: "Assessment 3",
                  },
                  {
                    cat: "Group 2",
                    key: "Assessment 4",
                  },
                  {
                    cat: "Group 2",
                    key: "Assessment 5",
                  },
                  {
                    cat: "Group 2",
                    key: "Assessment 6",
                  },
                  {
                    cat: "Group 2",
                    key: "Assessment 7",
                  },
                ]}
                showCheckbox
              />
            </Modal.Body>
            <Modal.Footer>
              <Button className="new_button_style">Import</Button>
            </Modal.Footer>
          </Modal>

          <Modal show={show1} onHide={handleClose1} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add Question Assessment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <AddQuestionAssessment />
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default AssessmentDetail;
