import React, { Component } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { NavLink } from "react-router-dom";
import "./esg_reporting.css";
import Multiselect from "multiselect-react-dropdown";
import axios from "axios";
import config from "../../../../config/config.json";
import { DataContext } from "../../../../contextApi";
import swal from "sweetalert";
import { Button, Modal } from "react-bootstrap";
import { apiCall } from "../../../../_services/apiCall";

const authValue = JSON.parse(localStorage.getItem("currentUser"));
export default class home extends Component {
  state = {
    frameworkValue: [],
    updatedValue: [],
    selecedValue: [],
    topicsData: [],
    selectedMappingIds: [],
    kpiData: [],
    mainFremworkID: [],
    mainTopicID: [],
    mainKpiID: [],
    //new state
    selectedFramework: [],
    selectedTopics: [],
    initialFtechTopicData: false,
    showButton: false,
    buttonText: "Next",
    show: false,
    close: false,
    kpiData: [],
    slectedkpiList: [],
    checked: false,
    selectedKpiMappingIds: [this.context?.selectedKpiMappingData],
    financialYear: [],
    financialYearId: "",
  };
  static contextType = DataContext;

  constructor(props) {
    super(props);
    this.multiselectRefTracker = React.createRef();
  }
  componentDidMount = () => {
    this.fetchFrameworkApi();
    this.getFinancialYear();
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if (
    //   this.state.frameworkValue &&
    //   this.state.frameworkValue.length !==
    //     (prevState.frameworkValue && prevState.frameworkValue.length)
    // ) {
    //   this.fetchStoredData()
    // }
    if (
      this.state.selectedFramework &&
      this.state.selectedFramework.length !==
        (prevState.selectedFramework && prevState.selectedFramework.length)
    ) {
      if (
        this.state.selectedFramework &&
        this.state.selectedFramework.length > 0
      ) {
        const frameworkIds = this.state.selectedFramework.map(({ id }) => id);
        if (
          this.state.initialFtechTopicData &&
          prevState.initialFtechTopicData === false
        ) {
          this.fetchTopicData(frameworkIds, true);
          // this.getKpiData();
        } else {
          this.fetchTopicData(frameworkIds);
          // this.getKpiData();
        }
      }
    }
  };
  getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getFinancialYear`,
      {},
      {},
      "GET"
    );
    if (isSuccess) {
      this.setState({ financialYear: data.data });
      if (data?.data?.length === 1) {
        this.setState({ financialYearId: data?.data[0]?.id });
        await this.fetchStoredData(data?.data[0]?.id);
      }
    }
  };

  // fetchFrameworkApi = () => {
  //   const authValue = JSON.parse(localStorage.getItem("currentUser"));
  //   axios
  //     .get(
  //       `${config.API_URL}getFramework?type=ALL`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       this.setState({
  //         frameworkValue: response?.data?.data,
  //       });
  // }

  fetchFrameworkApi = async () => {
    const authValue = JSON.parse(localStorage.getItem("currentUser"));
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getFramework`,
      {},
      { type: "ALL" }
    );
    if (isSuccess) {
      this.setState({
        frameworkValue: data?.data,
      });
    }
  };

  fetchTopicData = async (framworkIds, fetchInitialData) => {
    if (framworkIds && framworkIds.length > 0) {
      const authValue = JSON.parse(localStorage.getItem("currentUser"));
      const { isSuccess, data } = await apiCall(
        `${config.API_URL}admin/getTopic`,
        {},
        { type: "ESG", framework_ids: framworkIds }
      );
      if (isSuccess) {
        const filterSelctedTopics = data?.data;
        const localSelectedTopicIds = [];
        for (let i = 0; i < filterSelctedTopics.length; i++) {
          localSelectedTopicIds.push(filterSelctedTopics[i].id);
        }
        const selectedTopicIds = localSelectedTopicIds.filter((element) =>
          this.state.mainTopicID.includes(element)
        );
        const sortedData = filterSelctedTopics.sort((a, b) => {
          const indexA = selectedTopicIds.indexOf(a.id);
          const indexB = selectedTopicIds.indexOf(b.id);

          if (indexA === -1 && indexB === -1) {
            return 0;
          }
          if (indexA === -1) {
            return 1;
          }
          if (indexB === -1) {
            return -1;
          }

          return indexA - indexB;
        });
        this.setState({ topicsData: sortedData });

        this.setState({ selectedTopics: filterSelctedTopics });
        this.context.setSelectedTopicsData(selectedTopicIds);
        if (selectedTopicIds.length > 0) {
          this.getKpiData();
        }

        // const selectedTopicIds =
        // (filterSelctedTopics &&
        //   filterSelctedTopics.length > 0 &&
        //   filterSelctedTopics.map(({ id }) => id)) ||
        // []

        // if (fetchInitialData && data && data.data && data.data.length > 0) {
        //   const filterSelctedTopics = data.data.filter((topic) => {
        //     return this.context.selectedTopicsData.find((value) => {
        //       return value == topic.id
        //     })
        //   })
        //   const selectedTopicIds =
        //     (filterSelctedTopics &&
        //       filterSelctedTopics.length > 0 &&
        //       filterSelctedTopics.map(({ id }) => id)) ||
        //     []
        //   this.setState({ selectedTopics: filterSelctedTopics })
        //   this.context.setSelectedTopicsData(selectedTopicIds)
        //   if (selectedTopicIds.length > 0) {
        //     this.getKpiData()
        //   }
        // }
      }

      //   axios
      //     .get(
      //       `${
      //         config.API_URL
      //       }admin/getTopic?type=ESG&framework_ids=${JSON.stringify(framworkIds)}`,
      //       {
      //         headers: {
      //           Authorization: `Bearer ${localStorage.getItem("token")}`,
      //           "Content-Type": "application/json",
      //         },
      //       }
      //     )
      //     .then((response) => {

      //     })
      //     .catch((error) => {
      //     });
      // }
    }
  };

  saveSelectedData = async () => {
    const body = {
      data: {
        kpi_id: this.context?.SelectedKpiData,
        topic_id: this.context?.selectedTopicsData,
        framework_id: this.context?.selectedFrameworkData,
      },
      type: "SQ",
      financial_year_id: this.state.financialYearId,
    };
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}saveDataCompanyWise`,
      {},
      body,
      "POST"
    );
    if (isSuccess) {
    } else {
      this.setState({ show: false });
    }
  };

  getKpiData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}admin/getKpi`,
      {},
      {
        type: "ESG",
        company_id: localStorage.getItem("user_temp_id"),
        topic_ids: JSON.stringify(this.context.selectedTopicsData),
      },
      "GET"
    );
    if (isSuccess) {
      const filteredKPIs = data?.data;
      const localSelectedKpiIds = [];
      for (let i = 0; i < filteredKPIs.length; i++) {
        localSelectedKpiIds.push(filteredKPIs[i].id);
      }
      const selectedKpiIds = localSelectedKpiIds.filter((element) =>
        this.state.mainKpiID.includes(element)
      );
      const sortedData = filteredKPIs.sort((a, b) => {
        const indexA = selectedKpiIds.indexOf(a.id);
        const indexB = selectedKpiIds.indexOf(b.id);

        if (indexA === -1 && indexB === -1) {
          return 0;
        }
        if (indexA === -1) {
          return 1;
        }
        if (indexB === -1) {
          return -1;
        }

        return indexA - indexB;
      });
      this.setState({ kpiData: sortedData });
      this.setState({ selectedKpiList: selectedKpiIds });
      this.context.setSelectedKpiData(selectedKpiIds);

      // if (data && data.data && data.data.length > 0) {
      //   const filteredKPIs = data.data.filter((kpiObj) => {
      //     return this.context.SelectedKpiData.find((value) => {
      //       return value === kpiObj.id
      //     })
      //   })
      //   if (filteredKPIs && filteredKPIs.length > 0) {
      //     const kpiIds = filteredKPIs.map(({ id }) => id)
      //     this.setState({ selectedKpiList: kpiIds })
      //     this.context.setSelectedKpiData(kpiIds)
      //   }
      // }
    }
    // axios
    // .get(
    //   `${config.API_URL}admin/getKpi?type=ESG&company_id=${localStorage.getItem(
    //     "user_temp_id"
    //   )}&topic_ids=${JSON.stringify(this.context.selectedTopicsData)}`,
    //   {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       "Content-Type": "application/json",
    //     },
    //   }
    // )
    // .then((response) => {
    //   this.setState({ kpiData: response?.data?.data });
    //   if (
    //     response.data &&
    //     response.data.data &&
    //     response.data.data.length > 0
    //   ) {
    //     const filteredKPIs = response.data.data.filter((kpiObj) => {
    //       return this.context.SelectedKpiData.find((value) => {
    //         return value === kpiObj.id;
    //       });
    //     });
    //     if (filteredKPIs && filteredKPIs.length > 0) {
    //       const kpiIds = filteredKPIs.map(({ id }) => id);
    //       this.setState({ slectedkpiList: kpiIds });
    //       this.context.SelectedKpiData(kpiIds);
    //     }
    //   }
    // })
    // .catch((error) => {
    // });
  };

  handleConfirm = () => {
    if (this.state.selectedTopics.length === 0) {
      this.context.setSelectedKpiData([]);
    }
    this.saveSelectedData();
  };

  handleFinancialYearChange = async (e) => {
    if (e.target.value === "Select Financial Year")
      this.setState({ financialYearId: "" });
    else {
      const fId = e.target.value;
      this.setState({ financialYearId: fId });
      await this.fetchStoredData(fId);
    }
  };

  fetchStoredData = async (fId) => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getDataCompanyWise`,
      {},
      { type: "SQ", financial_year_id: fId },
      "GET"
    );
    if (isSuccess) {
      if (data.data.length == 0) {
        // this.context.setSelectedFrameworkMappingData([])
        this.context.setSelectedTopicsData([]);
        // this.context.setSelectedKpiMappingData([])
        // const selectedFramework = this.state.selectedFramework
        // this.state.selectedFramework.forEach((element) => {
        //   this.onRemoveFrameworkHandler([element])
        // })
        // this.onRemoveFrameworkHandler([])
        this.multiselectRefTracker.current.resetSelectedValues();
        // this.onRemoveFrameworkHandler([
        //   selectedFramework[selectedFramework.length - 1],
        // ])

        this.setState({
          selectedFramework: [],
          topicsData: [],
          selectedTopics: [],
          kpiData: [],
          selectedKpiList: [],
        });
        this.context.setSelectedTopicsData([]);
      } else {
        const data1 = data?.data?.framework_topic_kpi
          ? JSON.parse(data?.data?.framework_topic_kpi)
          : {};
        this.setState({
          mainFremworkID: data1?.framework_id || [],
          mainTopicID: data1?.topic_id || [],
          mainKpiID: data1?.kpi_id || [],
        });
        this.context.setSelectedKpiData(data1?.kpi_id || []);
        this.context.setSelectedFrameworkData(data1?.framework_id || []);
        this.context.setSelectedTopicsData(data1?.topic_id || []);
        if (data1 && data1.framework_id && data1.framework_id.length > 0) {
          this.handleAutoselectedFramework(data1.framework_id);
        }
      }
    }
  };

  handleAutoselectedFramework = (selectedFrameworkMapping) => {
    const filteredFrameValue = this.state.frameworkValue.filter((obj) => {
      return selectedFrameworkMapping.find((value) => {
        return value === obj.id;
      });
    });
    if (filteredFrameValue && filteredFrameValue.length > 0) {
      this.setState((prevState) => {
        return {
          ...prevState,
          selectedFramework: filteredFrameValue,
          initialFtechTopicData: true,
        };
      });
      this.fetchTopicData();
    }
  };

  onSelectFrameworkHandler = (data) => {
    const frameworkIds = data && data?.map(({ id }) => id);
    this.context.setSelectedFrameworkData(frameworkIds);
    const selectedFrameworkMappingIds = data && data.map(({ id }) => id);
    this.context.setSelectedFrameworkMappingData(selectedFrameworkMappingIds);
    this.setState({
      selectedFramework: data || [],
    });
  };

  onRemoveFrameworkHandler = (data) => {
    //setp 1 if removed all data
    if (data && data.length === 0) {
      this.setState({
        selectedFramework: [],
        selectedTopics: [],
        kpiData: [],
        selectedKpiList: [],
        topicsData: [],
      });

      this.context.setSelectedFrameworkMappingData([]);
      this.context.setSelectedTopicsData([]);
      // this.setState({topicsData:[]});
      // this.setState({kpiData:[]});
      this.context.setSelectedKpiMappingData([]);
    } else {
      //Step 2 remove data from selectedFramewok and selecetd framework mapping ids
      this.onSelectFrameworkHandler(data);
      //Step 3 remove if selected topics and sellecte KPIs
      if (this.state.selectedTopics && this.state.selectedTopics.length > 0) {
        const filteredTopics = this.state.selectedTopics.filter((obj) => {
          return data.find((dataObj) => {
            return dataObj.id === obj.id;
          });
        });
        if (filteredTopics && filteredTopics.length > 0) {
          const topicMappingIds = filteredTopics.map(({ id }) => id);
          const topicIds = filteredTopics.map(({ id }) => id);
          this.setState({ selectedTopics: filteredTopics });
          // this.context.setSelectedTopicsData(topicMappingIds);
          this.context.setSelectedTopicsData(topicMappingIds);
        } else {
          this.setState({ selectedTopics: [] });
          this.context.setSelectedTopicsData([]);
          this.context.setSelectedKpiMappingData([]);
          // this.context.setSelectedTopicsData([]);
        }
      }
    }
  };

  onTopicChangeHandler = async (event, topicObj) => {
    if (event.target.checked) {
      let selectedTopicIds = [...this.context?.selectedTopicsData];
      selectedTopicIds.push(topicObj?.id);
      await this.context.setSelectedTopicsData(selectedTopicIds);
      this.getKpiData();
      // this.context.setSelectedTopicsData(ids);
    } else {
      const selectedTopicData = this.state.selectedTopics.filter((topic) => {
        if (topic.id !== topicObj.id) {
          return topic;
        }
      });
      const filterSelectedMappingIds = this.context.selectedTopicsData.filter(
        (value) => value !== topicObj.id
      );
      const selectedTopicsIds =
        selectedTopicData &&
        selectedTopicData.length > 0 &&
        selectedTopicData.map(({ id }) => id);
      this.setState({ selectedTopics: selectedTopicData });
      // this.context.setSelectedTopicMappingData(filterSelectedMappingIds);
      await this.context.setSelectedTopicsData(filterSelectedMappingIds);
      if (filterSelectedMappingIds.length > 0) {
        this.getKpiData();
      } else {
        this.setState({ kpiData: [] });
        this.setState({ selectedKpiList: [] });
      }
    }
  };

  handleSelect = (event, kpiObj) => {
    if (event.target.checked) {
      const kpiIds = [...this.state.slectedkpiList, kpiObj?.id];
      const kpiMappingIds = [...this.context.SelectedKpiData, kpiObj?.id];
      this.setState({
        slectedkpiList: kpiIds,
        selectedKpiMappingIds: kpiMappingIds,
      });
      this.context.setSelectedKpiData(kpiIds);
      this.context.setSelectedKpiMappingData(kpiMappingIds);
    } else {
      const filteredIds = this.state.slectedkpiList.filter(
        (value) => value !== kpiObj.id
      );
      const filteredMappingIds = this.context.selectedKpiMappingData.filter(
        (value) => value !== kpiObj.id
      );
      this.setState({
        slectedkpiList: filteredIds,
        selectedKpiMappingIds: filteredMappingIds,
      });
      this.context.setSelectedKpiData(filteredIds);
      this.context.setSelectedKpiMappingData(filteredMappingIds);
    }
  };
  render() {
    return (
      <>
        <Sidebar dataFromParent={this.props.location.pathname} />
        <Header />
        <div className="main_wrapper">
          <div className="inner_wraapper">
            <div className="container-fluid">
              <section className="d_text">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="color_div_on">
                        <div className="col-lg-6 col-xs-6">
                          <div className="form-group d-flex">
                            <label
                              htmlFor="industryType"
                              className="frame align-items-center d-flex"
                            >
                              Please Select Financial Year :
                            </label>
                            {this.state.financialYear &&
                            this.state.financialYear?.length === 1 ? (
                              <select
                                name="tab_name"
                                onChange={this.handleFinancialYearChange}
                                className="select_one_all industrylist"
                              >
                                {this.state.financialYear?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {item.financial_year_value}
                                  </option>
                                ))}
                              </select>
                            ) : (
                              <select
                                name="tab_name"
                                onChange={this.handleFinancialYearChange}
                                className="select_one_all industrylist"
                              >
                                <option>Select Financial Year</option>
                                {this.state.financialYear?.map((item, key) => (
                                  <option key={key} value={item.id}>
                                    {item.financial_year_value}
                                  </option>
                                ))}
                              </select>
                            )}
                          </div>
                        </div>
                      </div>
                      {this.state.financialYearId && (
                        <>
                          <div className="color_div_on mt-4">
                            <h5 className="frame py-3 m-0">
                              Please select which ESG reporting Framework you
                              want to use:
                            </h5>
                            <div className="Global">
                              <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                  <div className="border_box">
                                    <div className="wel_fel">
                                      <div className="row">
                                        <Multiselect
                                          placeholder={"Select Framework"}
                                          displayValue="title"
                                          options={this.state.frameworkValue}
                                          selectedValues={
                                            this.state.selectedFramework
                                          }
                                          ref={this.multiselectRefTracker}
                                          onRemove={(e) => {
                                            this.onRemoveFrameworkHandler(e);
                                          }}
                                          onSelect={(e) => {
                                            this.onSelectFrameworkHandler(e);
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      <br></br>
                      {this.state.financialYearId &&
                        this.state.topicsData.length > 0 && (
                          <div className="color_div_on">
                            <h5 className="frame pt-4 pb-3">
                              Please select your focus areas: (Mandatory)
                            </h5>
                            <div className="Global">
                              <div className="row">
                                <div className="col-sm-12 col-xs-12">
                                  <div className="border_box p-3">
                                    <div className="wel_fel">
                                      <div className="row">
                                        {this.state.topicsData?.map(
                                          (data, index) => {
                                            let dataFlag = [
                                              ...this.context
                                                ?.selectedTopicsData,
                                            ].some(
                                              (value) => value == data?.id
                                            );
                                            return (
                                              <div
                                                key={index}
                                                className="col-xxl-6 col-lg-6 col-md-12 col-12"
                                              >
                                                <div className="Global_text">
                                                  <div className="form-check form-check-inline clobal_check input-padding">
                                                    <input
                                                      className="form-check-input input_one "
                                                      name="frameworksUsed[]"
                                                      type="checkbox"
                                                      checked={
                                                        dataFlag ? true : false
                                                      }
                                                      onChange={(e) => {
                                                        this.onTopicChangeHandler(
                                                          e,
                                                          data
                                                        );
                                                      }}
                                                    />

                                                    <label
                                                      className="form-check-label label_one"
                                                      htmlFor={
                                                        "xcfgvldskfjgosdfg"
                                                      }
                                                    >
                                                      {data?.title}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      <div style={{ margin: "20px 0" }}>
                        <div className="color_div_on">
                          <h5 className="frame">
                            Please select your focus areas: (Voluntary)
                          </h5>
                          <div className="Global">
                            <div className="row">
                              <div className="col-sm-12 col-xs-12">
                                <div className="border_box p-3">
                                  <div className="wel_fel">
                                    <div className="row">
                                      
                                          <div
                                            key={'1'}
                                            className="col-xxl-6 col-lg-6 col-md-12 col-12"
                                          >
                                            <div className="Global_text">
                                              <div className="form-check form-check-inline clobal_check input-padding">
                                                <input
                                                  className="form-check-input input_one "
                                                  // name="frameworksUsed[]"
                                                 
                                                />
                                                <label
                                                  className="form-check-label label_one"
                                                  htmlFor={"xcfgvldskfjgosdfg"}
                                                >
                                                  zhsfthj
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {
                        // this.state?.kpiData?.length > 0 &&
                        <div style={{ margin: "20px 0" }}>
                          {this.state.kpiData.length > 0 && (
                            <div className="color_div_on">
                              <h5 className="frame">
                                Please select KPIs you want to use:
                              </h5>
                              <div className="Global">
                                <div className="row">
                                  <div className="col-sm-12 col-xs-12">
                                    <div className="border_box p-3">
                                      <div className="wel_fel">
                                        <div className="row">
                                          {this.state.kpiData.map(
                                            (data, index) => {
                                              const dataFlag =
                                                this.context.SelectedKpiData &&
                                                this.context.SelectedKpiData.some(
                                                  (value) => value === data.id
                                                );
                                              return (
                                                <div
                                                  key={index}
                                                  className="col-xxl-6 col-lg-6 col-md-12 col-12"
                                                >
                                                  <div className="Global_text">
                                                    <div className="form-check form-check-inline clobal_check input-padding">
                                                      <input
                                                        className="form-check-input input_one "
                                                        // name="frameworksUsed[]"
                                                        type="checkbox"
                                                        checked={dataFlag}
                                                        onChange={(e) => {
                                                          this.handleSelect(
                                                            e,
                                                            data
                                                          );
                                                        }}
                                                      />
                                                      <label
                                                        className="form-check-label label_one"
                                                        htmlFor={
                                                          "xcfgvldskfjgosdfg"
                                                        }
                                                      >
                                                        {data.title}
                                                      </label>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      }
                      <div className="global_link">                      
                        {this.state.financialYearId && (
                          <div className="save_global global_link">
                            <button
                              className="page_save page_width"
                              variant="none"
                              disabled={this.state.selectedFramework?.length <= 0}
                              onClick={() => {
                                this.setState({ show: true });
                                this.handleConfirm();
                              }}
                            >                           
                              Confirm
                            </button>
                          </div>
                        )}
                        <Modal
                          show={this.state.show}
                          animation={true}
                          size="md"
                          className="modal_box"
                          shadow-lg="border"
                        >
                          <div className="modal-md">
                            <Modal.Header className="pb-0">
                              <Button
                                variant="outline-dark"
                                onClick={() => this.setState({ show: false })}
                              >
                                <i className="fa fa-times"></i>
                              </Button>
                            </Modal.Header>
                            <div className="modal-body vekp pt-0">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="response">
                                    <h4>Response Saved!</h4>
                                    <p className="text-center my-3">
                                      {" "}
                                      To download your ESG Risk Report please
                                      complete the Sector Questions and download
                                      at the completion of that module.{" "}
                                    </p>
                                    <div className="global_link">
                                      <NavLink
                                        className="page_save page_width"
                                        to={"sector_questions"}
                                      >
                                        {" "}
                                        go to sector questions{" "}
                                      </NavLink>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </>
    );
  }
}
