import React, { useState } from "react";
import { Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import config from "../../../../config/config.json";
import axios from "axios";
import "./trandsInputCard.css";

const initalData = {
  from_date: "",
  to_date: "",
  meter_id: "",
  process: "",
  reading_value: "",
  note: "",
  question: [],
  status: true,
  id: 123456,
};
function TradeCards({
  handleSubmitHandler,
  item,
  questionDetail,
  deleteTrandsCardData,
  cardAnswers,
  setCardAnswers,
  index,
  formula,
  errorObject,
  changeAnswer,
  meterListData,
  processListData,
}) {
  const [fieldsValue, setFieldsValue] = React.useState(item);
  const [changeValue, setChangeValue] = React.useState(false);
  const [toDateError, setToDateError] = React.useState(false);
  const [fromDateError, setFromDateError] = React.useState(false);
  const [afterCardChanges, setAfterCardChanges] = React.useState(true);
  const [processValue, setProcessValue] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const datepickerRef = React.useRef(null);
  React.useEffect(() => {
    setFieldsValue(item);
  }, [item]);
  React.useEffect(() => {
    if (changeValue) changeAnswer();
  }, [changeValue]);
  React.useEffect(() => {
    setAfterCardChanges(true);
  }, [errorObject]);

  const handleFromDateChange = (date, qid) => {
    setAfterCardChanges(false);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedDate = `${year}-${month}`;

    if (fieldsValue["to_date"] && formattedDate > fieldsValue["to_date"]) {
      setFromDateError("From date should be before the To date.");
    } else {
      const changedData = {
        ...fieldsValue,
        from_date: formattedDate,
      };
      setFieldsValue(changedData);
      setFromDateError(null);
      setChangeValue(true);
    }
  };
  const handleToDateChange = (date, qid) => {
    setAfterCardChanges(false);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const formattedDate = `${year}-${month}`;

    if (fieldsValue["from_date"] && formattedDate < fieldsValue["from_date"]) {
      setToDateError("To date should be after the From date.");
    } else {
      const changedData = {
        ...fieldsValue,
        to_date: formattedDate,
      };
      setFieldsValue(changedData);
      setToDateError(null);
      setChangeValue(true);
    }
  };

  const handleOnchange = (event, qId, type) => {
    setAfterCardChanges(false);
    const name = event?.target?.name || "";
    if (type === "date") {
      const str = event?.target?.value;
      const changedData = {
        ...fieldsValue,
        [name.trim()]: moment(str).format("DD-MM-YYYY"),
      };
      setFieldsValue(changedData);
    } else {
      const changedData = {
        ...fieldsValue,
        [name.trim()]: event?.target?.value,
      };
      setFieldsValue(changedData);
    }
    setChangeValue(true);
  };
  const getProcessValueById = async (id, data) => {
    const item = data?.find((item) => item["id"] == id);
    item ? setProcessValue(item.process) : setProcessValue("");
  };
  React.useEffect(() => {
    if (formula) getProcessValueById(item?.process, processListData);
  }, [item]);
  const handleFileUpload = async (event) => {
    setAfterCardChanges(false);
    const timestamp = new Date().getTime();
    const fileName = `${timestamp}_${event.target.files[0]?.name}`;
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("fileName", fileName);
    formData.append("filePath", "yasil/");

    try {
      let requestOptions = {
        method: "POST",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: `${config.S3_API_URL}`,
        redirect: "follow",
      };
      axios(requestOptions).then(function (response) {
        console.log(JSON.stringify(response.data));
        // const changedData = {
        //   ...fieldsValue,
        //   [name.trim()]: event?.target?.value,
        // };
        // setFieldsValue(changedData);
      });
      // const response = await fetch(`${config.S3_API_URL}`, requestOptions);

      // const result = await response.text();
      // console.log("urlllllllll", result);
    } catch (error) {
      console.error("error", error);
    }
  };
  return (
    <>
      <div className="Quantative_Sector">
        <div
          className={`${
            !afterCardChanges
              ? "Quantative_Sector_one custom-border"
              : "Quantative_Sector_one"
          }`}
        >
          <div className="d-flex justify-content-between align-items-center mb-2">
            {item?.answered_by_email?.length > 0 && (
              <span>
                <b>ANS By : </b>
                {item?.answered_by_email}
              </span>
            )}
            {item?.audit_status?.length > 0 && (
              <div>
                <b>Status : </b>
                <span className={item?.audit_status}>
                  {item?.audit_status?.replace(/_/g, " ")}
                </span>
              </div>
            )}
          </div>
          <Row className="align-items-center">
            <Col md={12}>
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ gap: "5px" }}
              >
                <p className="Quantative_Title m-0">From</p>
                <div className="d-flex flex-column">
                  <div className="date-picker-container d-flex flex-column">
                    <div className="input-container d-flex">
                      <DatePicker
                        selected={
                          fieldsValue["from_date"]
                            ? new Date(fieldsValue["from_date"])
                            : null
                        }
                        onChange={(event) =>
                          handleFromDateChange(event, questionDetail?.id)
                        }
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        className="form-control date-picker-input"
                        readOnly={formula}
                        required
                        ref={datepickerRef}
                        placeholder="MM/yyyy"
                      />
                      <div
                        className="calendar-i"
                        style={{
                          margin: "3px -34px 0px -34px",
                          zIndex: "100",
                        }}
                      >
                        {/* <button onClick={handleIconClick}>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    {fromDateError && (
                      <div className="red">{fromDateError}</div>
                    )}
                  </div>
                  <div>
                    {!fieldsValue["from_date"] &&
                      errorObject?.index == index &&
                      errorObject?.errors &&
                      errorObject.errors.includes(
                        "Please Select From Date"
                      ) && <div className="red">Please Select From Date</div>}
                  </div>
                </div>
                <p className="Quantative_Title m-0">To</p>
                <div className="d-flex flex-column">
                  <div className="date-picker-container">
                    <div className="input-container d-flex">
                      <DatePicker
                        selected={
                          fieldsValue["to_date"]
                            ? new Date(fieldsValue["to_date"])
                            : null
                        }
                        onChange={(event) =>
                          handleToDateChange(event, questionDetail?.id)
                        }
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        className="form-control date-picker-input"
                        readOnly={formula}
                        required
                        ref={datepickerRef}
                        placeholder="MM/yyyy"
                      />
                      <div
                        className="calendar-i"
                        style={{
                          margin: "3px -34px 0px -34px",
                          zIndex: "100",
                        }}
                      >
                        {/* <button onClick={handleIconClick}>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </button> */}
                      </div>
                    </div>
                  </div>
                  <div>
                    {toDateError && <div className="red">{toDateError}</div>}
                  </div>
                  <div>
                    {!fieldsValue["to_date"] &&
                      errorObject?.index == index &&
                      errorObject?.errors &&
                      errorObject.errors.includes("Please Select To Date") && (
                        <div className="red"> Please Select To Date</div>
                      )}
                  </div>
                </div>
              </div>
            </Col>
            {item?.audit_remark?.length > 0 && (
              <Col md={5} className=" justify-content-end d-flex">
                <>
                  <button className="new_button_style" onClick={handleShow}>
                    View Remark
                  </button>
                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Auditor Remark</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="mb-4">{item?.audit_remark}</div>
                    </Modal.Body>
                  </Modal>
                </>
              </Col>
            )}
          </Row>
          <hr className="line"></hr>
          <p className="energy">Source</p>
          {formula ? (
            <InputGroup className="mb-2">
              <Form.Control
                style={{
                  width: "100%",
                }}
                aria-label="first_input"
                type="text"
                name="meter_id"
                value={fieldsValue && fieldsValue["source"]}
                readOnly={formula}
                required
              />
            </InputGroup>
          ) : (
            <Form.Select
              aria-label="Default select example p-5"
              className="mb-2 form-control"
              name="meter_id"
              value={fieldsValue && fieldsValue["meter_id"]}
              onChange={(event) => handleOnchange(event, questionDetail?.id)}
              readOnly={formula}
              required
            >
              <option value="" hidden>
                Please Select the Source
              </option>
              {meterListData &&
                meterListData?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.process}
                    </option>
                  );
                })}
            </Form.Select>
          )}
          <div className="d-flex align-items-center justify-content-between">
            {meterListData?.length === 0 && (
              <div className="red" style={{ fontSize: 13 }}>
                {" "}
                Please Create Source On Here
              </div>
            )}
            {meterListData?.length === 0 && (
              <a href="/#/settings" style={{ fontSize: 13 }}>
                <u>Create Source</u>
              </a>
            )}
          </div>
          {!fieldsValue["meter_id"] &&
            errorObject?.index == index &&
            errorObject?.errors &&
            errorObject.errors.includes("Please Select Source") && (
              <div className="red"> Please Select Source</div>
            )}
          <p className="energy">Process</p>
          {console.log("process", processValue)}
          {formula ? (
            <InputGroup className="mb-2">
              <Form.Control
                style={{
                  width: "100%",
                }}
                aria-label="first_input"
                type="text"
                name="process"
                value={processValue}
                readOnly={formula}
                required
              />
            </InputGroup>
          ) : (
            <Form.Select
              aria-label="Default select example p-5"
              className="mb-2 form-control"
              name="process"
              value={fieldsValue && fieldsValue["process"]}
              onChange={(event) => handleOnchange(event, questionDetail?.id)}
              readOnly={formula}
              required
            >
              <option value="" hidden>
                Please Select the Process
              </option>
              {processListData &&
                processListData?.map((data, index) => {
                  return (
                    <option key={index} value={data.id}>
                      {data.process}
                    </option>
                  );
                })}
            </Form.Select>
          )}
          {/* <input
            aria-label="Default select example"
            className="mb-3 w-100 form-control"
            name="process"
            value={fieldsValue && fieldsValue["process"]}
            onChange={(event) => handleOnchange(event, questionDetail?.id)}
            readOnly={formula}
            required
          /> */}
          {!fieldsValue["process"] &&
            errorObject?.index == index &&
            errorObject?.errors &&
            errorObject.errors.includes("Please Enter Process") && (
              <div className="red"> Please Enter Process</div>
            )}
          {/* <div>{errorObject[0]}</div> */}
          <p className="energy">Reading value</p>
          <InputGroup className="mb-2">
            <Form.Control
              style={{
                width: "70%",
              }}
              aria-label="first_input"
              type="number"
              name="reading_value"
              value={fieldsValue && fieldsValue["reading_value"]}
              onChange={(e) => handleOnchange(e, questionDetail?.id)}
              readOnly={formula}
              required
            />
            <Form.Control
              style={{
                width: "30%",
              }}
              aria-label="Last_input"
              value={questionDetail?.question_detail[0]?.option}
            />
          </InputGroup>

          {!fieldsValue["reading_value"] &&
            errorObject?.index == index &&
            errorObject?.errors &&
            errorObject.errors.includes("Please Enter Reading Value") && (
              <div className="red"> Please Enter Reading Value </div>
            )}
          <p className="energy">Note</p>
          <Form.Control
            className="mb-2"
            as="textarea"
            placeholder="Leave a Note here"
            style={{
              height: "100px",
            }}
            name="note"
            value={fieldsValue && fieldsValue["note"]}
            onChange={(e) => handleOnchange(e, questionDetail?.id)}
            readOnly={formula}
          />
          <div className="d-flex align-items-center justify-content-between">
            {!formula && (
              <button
                style={{
                  background: "#1f9ed1",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  border: "none",
                  color: "#fff",
                }}
                disabled={
                  (index === errorObject.index && afterCardChanges) ||
                  afterCardChanges
                }
                onClick={() => handleSubmitHandler(fieldsValue, index)}
              >
                Save
              </button>
            )}
            {index > 0 && !formula && (
              <button
                style={{
                  background: "red",
                  padding: "5px 10px",
                  borderRadius: "5px",
                  border: "none",
                  color: "#fff",
                  marginLeft: "5px",
                }}
                onClick={() => deleteTrandsCardData(fieldsValue, index)}
              >
                Delete
              </button>
            )}
            <span>
              <div>
                <input
                  id="aaaa"
                  type="file"
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      handleFileUpload(e);
                    }
                  }}
                  style={{
                    display: "none",
                  }}
                />
              </div>

              <div className="file file--upload">
                <label
                  htmlFor="aaaa"
                  // onClick={(e) => {
                  //   setUploadItem({
                  //     index: index,
                  //     item: item,
                  //   });
                  // }}
                  // data-index={index}
                >
                  <svg
                    width="34"
                    height="34"
                    viewBox="0 0 34 34"
                    fill="none"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <path
                      d="M17 34C7.6109 34 0 26.3891 0 17C0 7.6109 7.6109 0 17 0C26.3891 0 34 7.6109 34 17C34 26.3891 26.3891 34 17 34ZM17 30.6C20.6069 30.6 24.0662 29.1671 26.6167 26.6167C29.1671 24.0662 30.6 20.6069 30.6 17C30.6 13.3931 29.1671 9.93384 26.6167 7.38335C24.0662 4.83285 20.6069 3.4 17 3.4C13.3931 3.4 9.93384 4.83285 7.38335 7.38335C4.83285 9.93384 3.4 13.3931 3.4 17C3.4 20.6069 4.83285 24.0662 7.38335 26.6167C9.93384 29.1671 13.3931 30.6 17 30.6Z"
                      fill="#A7ACC8"
                    ></path>
                    <path
                      d="M22.6 16.75H22.35V17V22.1944H11.65V17V16.75H11.4H10H9.75V17V23.2222C9.75 23.4868 9.84435 23.7452 10.0192 23.9394C10.1948 24.1346 10.439 24.25 10.7 24.25H23.3C23.561 24.25 23.8052 24.1346 23.9808 23.9394C24.1556 23.7452 24.25 23.4868 24.25 23.2222V17V16.75H24H22.6ZM12.8 14.9167H16.05V20.1111V20.3611H16.3H17.7H17.95V20.1111V14.9167H21.2H21.7613L21.3858 14.4994L17.1858 9.83276L17 9.62629L16.8142 9.83276L12.6142 14.4994L12.2387 14.9167H12.8Z"
                      fill="#A7ACC8"
                      stroke="#A7ACC8"
                      strokeWidth="0.5"
                    ></path>
                  </svg>
                </label>
              </div>
            </span>
            {!formula && (
              <span
                style={{
                  cursor: "pointer",
                  position: "relative",
                }}
              >
                <a
                  href="null"
                  target="_blank"
                  download=""
                  style={{
                    color: "green",
                    cursor: "pointer",
                    position: "relative",
                  }}
                >
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none">
                    <path
                      d="M17 1.48619e-06C26.3891 6.65366e-07 34 7.6109 34 17C34 26.3891 26.3891 34 17 34C7.6109 34 -6.65366e-07 26.3891 -1.48619e-06 17C-2.30701e-06 7.6109 7.6109 2.30701e-06 17 1.48619e-06ZM17 3.4C13.3931 3.4 9.93384 4.83285 7.38334 7.38335C4.83285 9.93384 3.4 13.3931 3.4 17C3.4 20.6069 4.83285 24.0662 7.38335 26.6167C9.93384 29.1671 13.3931 30.6 17 30.6C20.6069 30.6 24.0662 29.1671 26.6167 26.6167C29.1671 24.0662 30.6 20.6069 30.6 17C30.6 13.3931 29.1671 9.93384 26.6166 7.38335C24.0662 4.83285 20.6069 3.4 17 3.4Z"
                      fill="#A7ACC8"
                    ></path>
                    <path
                      d="M11.4 17.25L11.65 17.25L11.65 17L11.65 11.8056L22.35 11.8056L22.35 17L22.35 17.25L22.6 17.25L24 17.25L24.25 17.25L24.25 17L24.25 10.7778C24.25 10.5132 24.1556 10.2548 23.9808 10.0606C23.8052 9.86542 23.561 9.75 23.3 9.75L10.7 9.75C10.439 9.75 10.1948 9.86542 10.0192 10.0606C9.84435 10.2548 9.75 10.5132 9.75 10.7778L9.75 17L9.75 17.25L10 17.25L11.4 17.25ZM21.2 19.0833L17.95 19.0833L17.95 13.8889L17.95 13.6389L17.7 13.6389L16.3 13.6389L16.05 13.6389L16.05 13.8889L16.05 19.0833L12.8 19.0833L12.2387 19.0833L12.6142 19.5006L16.8142 24.1672L17 24.3737L17.1858 24.1672L21.3858 19.5006L21.7613 19.0833L21.2 19.0833Z"
                      fill="#A7ACC8"
                      stroke="#A7ACC8"
                      strokeWidth="0.5"
                    ></path>
                  </svg>
                </a>
                <span
                  className="delete_attachment"
                  data-id="411"
                  aria-hidden="true"
                >
                  {" "}
                  ×{" "}
                </span>
              </span>
            )}
          </div>
        </div>
        {!afterCardChanges && (
          <span className="color_div_on red justify-content-center align-content-center d-flex">
            We have to Save this Card
          </span>
        )}
      </div>
    </>
  );
}

export default function TrandsInputCard({
  item,
  answer,
  handleSubmit,
  handleDeleteSubmit,
  formula,
  changeAns,
  initalValue,
  meterListData,
  processListData,
}) {
  const [id, setID] = React.useState(123456);
  const [cardAnswers, setCardAnswers] = React.useState([]);
  const [modifiedInitialData, setModifiedInitialData] = React.useState([]);
  const [questionDetail, setQuestionDetail] = React.useState(item);
  const [errorObject, setErrorObject] = React.useState({
    errors: [],
    index: 0,
  });
  React.useEffect(() => {
    if (
      answer &&
      answer[0] &&
      answer[0]["answer"] &&
      answer[0]["answer"].length > 0
    ) {
      setCardAnswers(answer[0]["answer"]);
      let tmpAns = answer[0]["answer"];
      let length = tmpAns?.length;
      const modifiedData = {
        ...modifiedInitialData,
        from_date: tmpAns[length - 1]?.from_date,
        to_date: tmpAns[length - 1]?.to_date,
      };
      setModifiedInitialData(modifiedData);
      // console.log(
      //   "answer[0]",
      //   notSaveCard
      // );
    } else {
      const modifiedInitialData = {
        ...initalData,
        from_date:
          new Date().getFullYear() +
          "-" +
          ("0" + initalValue?.starting_month).slice(-2),
        to_date: calculateToDate(
          initalValue?.starting_month,
          initalValue?.frequency
        ),
      };
      setModifiedInitialData(modifiedInitialData);
      setCardAnswers([modifiedInitialData]);
    }
  }, [answer]);
  const calculateToDate = (starting_month, frequency) => {
    const year = new Date().getFullYear();
    let nextMonth;
    let newYear = year;
    if (frequency === "MONTHLY") {
      nextMonth = (starting_month + 0) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    } else if (frequency === "QUATERLY") {
      nextMonth = (starting_month + 1) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    } else if (frequency === "YEARLY") {
      nextMonth = (starting_month + 10) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    } else if (frequency === "HALF_YEARLY") {
      nextMonth = (starting_month + 4) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    }
    if (frequency === "MONTHLY") {
      return newYear + "-" + ("0" + nextMonth).slice(-2);
    }
    return newYear + "-" + ("0" + (nextMonth + 1)).slice(-2);
  };
  const calculateAddMoreToDate = (newDate, starting_month, frequency) => {
    const date = new Date(newDate);
    const year = date.getFullYear();
    let nextMonth;
    let newYear = year;
    if (frequency === "MONTHLY") {
      nextMonth = (starting_month + 0) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    } else if (frequency === "QUATERLY") {
      nextMonth = (starting_month + 1) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    } else if (frequency === "YEARLY") {
      nextMonth = (starting_month + 10) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    } else if (frequency === "HALF_YEARLY") {
      nextMonth = (starting_month + 4) % 12;
      if (nextMonth < starting_month) {
        newYear++;
      }
    }
    if (frequency === "MONTHLY") {
      return newYear + "-" + ("0" + nextMonth).slice(-2);
    }
    return newYear + "-" + ("0" + (nextMonth + 1)).slice(-2);
  };
  const handleSubmitHandler = (objItem, index) => {
    let yourObject = [];
    if (!objItem?.from_date || objItem?.from_date?.length === 0) {
      yourObject.push("Please Select From Date");
    }
    if (!objItem?.to_date || objItem?.to_date?.length === 0) {
      yourObject.push("Please Select To Date");
    }
    if (!objItem?.meter_id || objItem?.meter_id?.length === 0) {
      yourObject.push("Please Select Source");
    }
    if (!objItem?.process || objItem?.process?.length === 0) {
      yourObject.push("Please Enter Process");
    }
    if (!objItem?.reading_value || objItem?.reading_value === "") {
      yourObject.push("Please Enter Reading Value");
    }
    setErrorObject({ errors: yourObject, index: index });
    if (yourObject?.length === 0) {
      let tempArray = [...cardAnswers];
      const findIndex = index;
      if (findIndex >= 0) {
        tempArray[findIndex] = { ...objItem, status: false };
        setCardAnswers(tempArray);
        handleSubmit(item, tempArray, item?.questionType);
      } else if (tempArray[index] && tempArray[index].length > 0) {
        tempArray[index] = { ...objItem, status: false };
        handleSubmit(item, tempArray, item?.questionType);
        setCardAnswers(tempArray);
      }
    }
  };
  const deleteTrandsCardData = (fieldsValue, index) => {
    let tempAns = [...cardAnswers];

    handleDeleteSubmit(item, fieldsValue, index);
    setCardAnswers(tempAns.filter((d) => d.id !== fieldsValue.id));
  };
  const changeAnswer = () => {
    changeAns();
  };
  const handleAddMore = () => {
    const date = new Date(modifiedInitialData.to_date);
    const month = date.getMonth() + 1;
    const month1 = date.getMonth() + 2;
    const year = date.getFullYear();
    let adjustedMonth = month1;
    let adjustedYear = year;

    if (adjustedMonth > 12) {
      adjustedMonth = adjustedMonth % 12;
      adjustedYear += Math.floor(month1 / 12);
    }
    const currentMonth = ("0" + adjustedMonth).slice(-2);
    const from_date = adjustedYear + "-" + currentMonth;
    const modifiedData = {
      ...modifiedInitialData,
      from_date: from_date,
      to_date: calculateAddMoreToDate(
        modifiedInitialData.to_date,
        month1,
        initalValue?.frequency
      ),
    };
    setModifiedInitialData(modifiedData);
    let tempAns = [...cardAnswers, { ...modifiedData, id: id + 1 }];
    setCardAnswers(tempAns);
    setID((prevValue) => {
      return prevValue + 1;
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "grid",
          margin: "0 auto",
          gap: "1rem",
          gridTemplateColumns: "repeat(2, 1fr)",
        }}
      >
        {cardAnswers?.length > 0 &&
          cardAnswers.map((inputTradeAns, index) => {
            return (
              <div key={`${item?.id}-${index}`} style={{ width: "100%" }}>
                <TradeCards
                  deleteTrandsCardData={deleteTrandsCardData}
                  item={inputTradeAns}
                  questionDetail={questionDetail}
                  handleSubmitHandler={handleSubmitHandler}
                  cardAnswers={cardAnswers}
                  index={index}
                  setCardAnswers={setCardAnswers}
                  formula={formula}
                  changeAnswer={changeAnswer}
                  errorObject={errorObject}
                  meterListData={meterListData}
                  processListData={processListData}
                />
              </div>
            );
          })}
      </div>
      {formula ? (
        <></>
      ) : (
        <button
        className="mb-3"
          style={{
            background: "#1f9ed1",
            padding: "5px 10px",
            borderRadius: "5px",
            border: "none",
            color: "#fff",
          }}
          onClick={() => {
            handleAddMore();
          }}
        >
          Add more
        </button>
      )}

      {answer[0]?.notFindANswer ? (
        <>
          <p
            className="mt-3"
            style={{
              marginBottom: "-4px",
              textAlign: "center",
              color: "rgb(31, 158, 209)",
              fontWeight: "bold",
            }}
          >
            {" "}
            Answer for Question {answer[0]?.notFindANswer} is not submitted yet.
          </p>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
