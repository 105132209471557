/* eslint-disable jsx-a11y/anchor-is-valid */
import { sweetAlert } from "../../../src/utils/UniversalFunction";
import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import logo from "../../img/Zais_logo.png";
import $ from "jquery";
import "./signup.css";
import config from "../../config/config.json";
import ReCAPTCHA from "react-google-recaptcha";
import env from "../../env";
import ReactTooltip from "react-tooltip";
import { setStore } from "../../utils/UniversalFunction";
import EyeCross from '../../img/eye-icon-cross.png'
import Eye from '../../img/eye-icon.png'
import Hint from '../../img/question.png'
import LoginImages from '../../img/login-image.jpg'
import {
  checkPasswordValidation,
  isValidEmail,
} from "../../utils/UniversalFunction";
import { apiCall } from "../../_services/apiCall";
import { Col, Row } from "react-bootstrap";
import { Country, State, City } from "country-state-city";
const baseURL = config.baseURL;

export default class signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      error2: null,
      isLoaded2: false,
      error3: null,
      isLoaded3: false,
      companyIndustoryItems: [],
      titleOrPositionsItems: [],
      industry_Category: [],
      company_industry: "",
      companyIndustry: [],
      items: [],
      user: {
        firstName: "",
        lastName: "",
        email: "",
        country: "",
        businessNumber: "",
        position: "",
        password: "",
        privacy: "1",
        userCategory: "",
        role: "company",
        source: "",
        register_company_name: "",
        companyIndustry: "",
        privacyPolicy: "",
        invitationCountry: "",
        frequency: "",
      },
      invitation: false,
      type: "password",
      passwordValidation: false,
      passwordValidationMessage: "",
      emailvalidation: false,
      emailvalidationMessage: "",
      submitted: false,
      industryId: "",
      no_of_users: "",
      captchaIsVerified: false,
      reference_id: "",
      url_location: this.props.location,
      countryCode: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
    this.showHide = this.showHide.bind(this);
    this.getSubIndustry = this.getSubIndustry.bind(this);
    this.handleChangeForCompanyIndustry =
      this.handleChangeForCompanyIndustry.bind(this);
    this.handleChangeForUser = this.handleChangeForUser.bind(this);
  }

  getSubIndustry(id) {
    fetch(config.BASE_URL + `getIndustriesOfCategoryId/?id=${id}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded2: true,
            companyIndustry: result.companyIndustry,
          });
        },

        (error2) => {
          this.setState({
            isLoaded2: true,
            error2,
          });
        }
      );
  }

  showHide(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      type: this.state.type === "password" ? "input" : "password",
    });
  }

  handleVerification(e) {
    this.setState({
      captchaIsVerified: true,
    });
  }
  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
    if (name === "industrytype") {
      this.getSubIndustry(value);
    }
    if (name === "password") {
      let condition = checkPasswordValidation(value);
      if (condition === true) {
        this.setState({
          passwordValidation: true,
          passwordValidationMessage: "",
        });
      } else {
        this.setState({
          passwordValidation: false,
          passwordValidationMessage: condition,
        });
      }
    }
    if (name === "email") {
      let condition = isValidEmail(value);
      if (condition === true) {
        this.setState({
          emailValidation: true,
          emailValidationMessage: "",
        });
      } else {
        this.setState({
          emailValidation: false,
          emailValidationMessage: "Please check email format",
        });
      }
    }
  }

  handleChangeForCompanyIndustry(event) {
    const value = event.target.value?.split("-");
    let industryId = value[0];
    let industryTitle = value[1];
    this.setState({
      industryId: industryId,
      company_industry: industryTitle,
    });
  }

  handleChangeForUser(event) {
    let no_of_users = event.target.value;
    this.setState({
      no_of_users: no_of_users,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const {
      user,
      passwordValidation,
      emailValidation,
      captchaIsVerified,
      countryCode,
    } = this.state;
    if (
      user.firstName &&
      user.lastName &&
      user.email &&
      user.businessNumber &&
      user.position &&
      user.password && 
      user?.source
      // passwordValidation &&
      // emailValidation
      // user.privacy &&
      // user.role &&
      // user.userCategory &&
      // user.register_company_name &&
      // captchaIsVerified &&
      // user.privacyPolicy
    ) {
      let referenceData = window.localStorage.getItem("reference");
      axios
        .post(config.AUTH_API_URL + "signup", {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          user_type_code: "company",
          register_company_name: user.register_company_name,
          country: countryCode ? countryCode : user.country,
          business_number: user.businessNumber,
          position: user.position,
          password: user.password,
          frequency: user.frequency,
          source: user.source,
          // privacy: user.privacy,
          userCategory: user.userCategory,
          // no_of_users: this.state.no_of_users,
          company_industry: this.state.company_industry,
          company_industry_id: this.state.industryId,
          request_id: this.state.reference_id,
          // token: captchaIsVerified,
          // reference: referenceData
        })
        .then((response) => {
          sweetAlert("success", response.data.message);
          let setResponse = {};
          setResponse.data = response.data;
          setResponse.data.role = response.data.user_type_code;
          // localStorage.setItem("currentUser", JSON.stringify(setResponse));
          const pushToRoute = "/#/verify_message";
          let finalLink = "";
          if (response.data.link) {
            finalLink = response.data.link;
            setStore("reference", "");
          } else {
            finalLink = baseURL + pushToRoute;
          }
          setTimeout(() => {
            window.location.href = pushToRoute;
          }, 2000);
        })
        .catch(function (error) {
          if (error.response) {
            sweetAlert("error", error.response.data.message);
          }
        });
    } else {
      sweetAlert("error", "Please fill all input");
    }
  }

  // findRedrenceId = () =>{
  //   const parts = this.state.url_location?.pathname?.split('/');
  //   const signupPath = parts[1]; // "signup"
  //   const email = parts[2]; // "ankit.rathore@sapidblue.com"
  //   const reference_id = parts[3];
  //   const countryCode = parts[4];

  //   this.setState({
  //     user: {
  //       email: email
  //     }
  //   });
  //   this.setState({reference_id:reference_id});
  //   if(countryCode===""){
  //   this.setState({user: {
  //     country: ""
  //   }})}else{
  //     this.setState({user: {
  //       country: countryCode
  //     }})
  //   }
  // }

  async componentDidMount() {
    // const {
    //   isSuccess: isSuccessForCountries,
    //   data: allCountries,
    //   error: error1,
    // } = await apiCall(config.BASE_URL + "getAllCountries", {}, {}, "GET");
    // if (isSuccessForCountries) {
    //   this.setState({
    //     isLoaded: true,
    //     items: allCountries.countries,
    //   });
    // }

    const url = window.location.href;
    const regex = /\/company_invite\/(.+)/;
    const match = url.match(regex);
    if (match && match[1]) {
      const paramValue = match[1];
      this.setState({ reference_id: paramValue });
      let body = {
        request_id: paramValue,
      };

      const { isSuccess, data } = await apiCall(
        config.BASE_URL + "getInvitationDetails",
        {},
        body,
        "GET"
      );
      if (isSuccess) {
        this.setState({
          invitation: true,
        });
        const country = this?.state?.items?.find(
          (country) => country?.iso === data?.data[0]?.country_code
        );
        this.setState({
          user: {
            email: data?.data[0]?.email,
            register_company_name: data?.data[0]?.company_name,
            country: data?.data[0]?.country_code,
            invitationCountry: country?.name,
          },
        });
      }
    } else {
      this.setState({
        invitation: false,
      });
    }

    const {
      isSuccess: isSuccessForTitle,
      data: TitleOrPositions,
      error: error2,
    } = await apiCall(config.BASE_URL + "getTitleOrPositions", {}, {}, "GET");
    if (isSuccessForTitle) {
      this.setState({
        isLoaded3: true,
        titleOrPositionsItems: TitleOrPositions.titleOrPositions,
      });
    }
    const {
      isSuccess: isSuccessForIndustry,
      data: Industry,
      error: error3,
    } = await apiCall(config.BASE_URL + "getIndustryCategories", {}, {}, "GET");
    if (isSuccessForIndustry) {
      this.setState({
        isLoaded2: true,
        industry_Category: Industry.industry_Category,
      });
    }
  }
  render() {
    const { user, submitted, type } = this.state;
    const { items, companyIndustry, titleOrPositionsItems, industry_Category } =
      this.state;
    return (
      <div>
        <Row>
          <Col md={7}>
            <div className="login-left-panel">
              <img src={LoginImages} alt="" />
            </div>
          </Col>
          <Col md={5}>
            <section className="login">
              <div className="login_part signup_middle">
                <div className="sing_log mt-5">
                  <div className="sing_one">
                    <img src={logo} alt="logo" />
                  </div>
                  <form name="form">
                    {/* <div className="step-forms step-forms-active"> */}
                    <div className="text_sing">
                      <p className="faster_oval">Set Up Your Business Below</p>
                      {/* <h4 className="Account">Set Up Your Business Below</h4> */}
                      {/* <p className="faster_oval">
                    Please make sure you fill in all onboarding information for
                    quick account approval. Contact us, if you have any
                    questions or need any help.
                  </p> */}
                    </div>
                    <div className="ster_form">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form_sign">
                            <div
                              className={
                                "form-group fg" +
                                (submitted && !user.register_company_name
                                  ? " has-error"
                                  : "")
                              }
                            >
                              <label className="st_name" htmlFor="name">
                                Registered Company Name
                              </label>
                              <input
                                className="form-control input-height"
                                required
                                placeholder="Enter Company name"
                                id="register_company_name"
                                type="text"
                                name="register_company_name"
                                value={user.register_company_name}
                                onChange={this.handleChange}
                                readOnly={this.state.invitation}
                              />
                              {submitted && !user?.register_company_name && (
                                <div className="help-block">
                                  Registered Company Name is required
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6">
                              <div
                                className={
                                  "form-group fg" +
                                  (submitted && !user.firstName
                                    ? " has-error"
                                    : "")
                                }
                              >
                                <label className="st_name" htmlFor="name">
                                  First Name
                                </label>
                                <input
                                  className="form-control input-height"
                                  id="firstName"
                                  type="text"
                                  name="firstName"
                                  placeholder="Enter First Name"
                                  value={user.firstName}
                                  onChange={this.handleChange}
                                />
                                {submitted && !user.firstName && (
                                  <div className="help-block">
                                    First Name is required
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div
                                className={
                                  "form-group fg" +
                                  (submitted && !user.lastName
                                    ? " has-error"
                                    : "")
                                }
                              >
                                <label className="st_name" htmlFor="name">
                                  Last Name
                                </label>
                                <input
                                  className="form-control input-height"
                                  id="lastName"
                                  type="text"
                                  placeholder="Enter Last Name"
                                  name="lastName"
                                  value={user.lastName}
                                  onChange={this.handleChange}
                                />
                                {submitted && !user.lastName && (
                                  <div className="help-block">
                                    Last Name is required
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <div
                              className={
                                "form-group fg" +
                                (submitted && !user.email ? " has-error" : "")
                              }
                            >
                              <label className="st_name" htmlFor="email">
                                Corporate Email
                              </label>
                              <input
                                className="form-control input-height"
                                id="email"
                                type="email"
                                name="email"
                                value={user.email}
                                autoComplete="off"
                                placeholder="Enter Email Address"
                                onChange={this.handleChange}
                                readOnly={this.state.invitation}
                              />
                              {this.state.emailValidation === false && (
                                <div className="help-block">
                                  {this.state.emailValidationMessage}
                                </div>
                              )}
                              {submitted && !user.email && (
                                <div className="help-block">
                                  Email is required
                                </div>
                              )}
                            </div>
                          </div>
                          {/* <div className="">
                    <div
                      className={
                        "form-group fg" +
                        (submitted && !user.role ? " has-error" : "")
                      }
                    >
                      {console.log(user.role ,"  use rolr")}
                      <label className="st_name" htmlFor="name">
                        User Role
                      </label>
                      <select
                        id="role"
                        required
                        name="role"
                        className="form-control"
                        value={user.role}
                        onChange={this.handleChange}
                      >
                        <option className="bold" value="">
                          Select category
                        </option>
                        <option value={"company"} key="company">Company</option>
                        <option value={"supplier"} key="supplier">Supplier</option>
                      </select>
                      {submitted && !user.role && (
                        <div className="help-block">
                          User role is required
                        </div>
                      )}
                    </div>
                  </div> */}
                          <div className="">
                            <div
                              className={
                                "form-group fg eye-frame" +
                                (submitted && !user.password ? " has-error" : "")
                              }
                            >
                              <label className="st_name" htmlFor="name">
                                Password&nbsp;
                                <span data-tip data-for="registerTip">
                                  <img src={Hint} alt="" srcSet="" />
                                </span>
                              </label>
                              <ReactTooltip
                                id="registerTip"
                                place="top"
                                effect="solid"
                              >
                                <h6>Password Must :</h6>
                                <ul>
                                  <li>Have at least one lower case character</li>
                                  <li>Have at least one Capital letter</li>
                                  <li>Have at least one number</li>
                                  <li>Have at least one special character</li>
                                  <li>Be at least 8 characters</li>
                                  <li>Not be a common password</li>
                                </ul>
                                <h6>
                                  For Eg : <b>Password@123</b>
                                </h6>
                              </ReactTooltip>
                              <input
                                className="form-control input-height"
                                id="password"
                                type={type}
                                name="password"
                                placeholder="Enter Strong Password"
                                value={user.password}
                                onChange={this.handleChange}
                              />
                              {this.state.passwordValidation === false && (
                                <div className="help-block">
                                  {this.state.passwordValidationMessage}
                                </div>
                              )}
                              {submitted && !user.password && (
                                <div className="help-block">
                                  Password is required
                                </div>
                              )}
                              <span className="eye-under" style={{ top: '54px' }} onClick={this.showHide}>
                                {this.state.type === "input" ? (
                                  <img src={EyeCross} alt="" srcSet="" />
                                ) : (
                                  <img src={Eye} alt="" srcSet="" />
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="">
                        <div
                          className={
                            "form-group fg" +
                            (submitted && !user.country ? " has-error" : "")
                          }
                        >
                          <label className="st_name" htmlFor="name">
                            Main Country Of Operations
                          </label>
                          {this.state?.user?.invitationCountry ? (
                            <input
                              className="form-control input-height"
                              id="country"
                              name="country"
                              type="text"
                              value={this.state?.user?.invitationCountry}
                              readOnly={this.state.invitation}
                            />
                          ) : (
                            <select
                              id="country"
                              name="country"
                              required
                              className="form-control input-height"
                              value={this.state.countryCode}
                              onChange={(e) =>
                                this.setState({ countryCode: e.target.value })
                              }
                            >
                              <option className="bold" value="1">
                                Select Country
                              </option>
                              {Country.getAllCountries().map((country, i) => (
                        <option value={country.isoCode}>{country.name}</option>
                      ))}
                            </select>
                          )}

                          {submitted &&
                            !this.state?.countryCode &&
                            !this.state.invitation && (
                              <div className="help-block">
                                Country Name is required
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="form_sign">
                            <div
                              className={
                                "form-group fg" +
                                (submitted && !user.source
                                  ? " has-error"
                                  : "")
                              }
                            >
                              <label className="st_name" htmlFor="name">
                                Source
                              </label>
                              <input
                                className="form-control input-height"
                                required
                                placeholder="Enter Source Name"
                                id="source"
                                type="text"
                                name="source"
                                value={user.source}
                                onChange={this.handleChange}
                                // readOnly={this.state.invitation}
                              />
                              {submitted && !user?.source && (
                                <div className="help-block">
                                Source is required
                                </div>
                              )}
                            </div>
                          </div>
                      <div className="">
                        <div
                          className={
                            "form-group fg" +
                            (submitted && !user.businessNumber ? " has-error" : "")
                          }
                        >
                          <label className="st_name" htmlFor="name">
                            Business Number
                          </label>
                          <input
                            type="tel"
                            required
                            placeholder="Enter business number"
                            className="form-control input-height"
                            name="businessNumber"
                            maxLength="11"
                            id="businessNumber"
                            value={user.businessNumbindustryIder}
                            onChange={this.handleChange}
                          />
                          {submitted && !user.businessNumber && (
                            <div className="help-block">
                              Business Number is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div
                          className={
                            "form-group fg" +
                            (submitted && !user?.frequency ? " has-error" : "")
                          }
                        >
                          <label className="st_name" htmlFor="name">
                            Select Frequency
                          </label>
                          <select
                            id="frequency"
                            name="frequency"
                            className="form-control input-height"
                            value={user?.frequency}
                            onChange={this.handleChange}
                          >
                            <option className="bold">Select Frequency</option>
                            <option value="MONTHLY">MONTHLY</option>
                            <option value="QUATERLY">QUATERLY</option>
                            <option value="HALF_YEARLY">HALF YEARLY</option>
                            <option value="YEARLY">YEARLY</option>
                            {/* {titleOrPositionsItems.length > 0 &&
                          titleOrPositionsItems.map((titleOrPositionsItem) => (
                            <option key={titleOrPositionsItem.id}>
                              {titleOrPositionsItem.title}
                            </option>
                          ))} */}
                          </select>
                          {submitted && !user?.frequency && (
                            <div className="help-block">
                              Frequency is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div
                          className={
                            "form-group fg" +
                            (submitted && !user.industrytype ? " has-error" : "")
                          }
                        >
                          <label className="st_name" htmlFor="name">
                            Company Industry
                          </label>
                          <select
                            className="form-control input-height"
                            name="industrytype"
                            id="industrytype"
                            placeholder="Select Industry Type"
                            onChange={this.handleChange}
                          >
                            <option value="">Please Select Company Industry</option>
                            {industry_Category?.length > 0 &&
                              industry_Category?.map((item, key) => (
                                <option key={key} value={item?.id}>
                                  {item?.name}
                                </option>
                              ))}
                          </select>
                          <label className="st_name" htmlFor="name">
                            Sector Type
                          </label>
                          <select
                            className="form-control input-height"
                            name="industryId"
                            id="industryId"
                            placeholder="Select Sector Type"
                            onChange={(e) => this.handleChangeForCompanyIndustry(e)}
                          >
                            <option value="" disabled selected>
                              Please Select Sector Type
                            </option>
                            {companyIndustry.length > 0 &&
                              companyIndustry?.map((item, key) => (
                                <option
                                  key={key}
                                  value={item.industryId + "-" + item.title}
                                >
                                  {item.title}
                                </option>
                              ))}
                          </select>
                          {submitted && !user.industrytype && (
                            <div className="help-block">Company is required</div>
                          )}
                        </div>
                      </div>
                      <div className="">
                        <div
                          className={
                            "form-group fg" +
                            (submitted && !user.position ? " has-error" : "")
                          }
                        >
                          <label className="st_name" htmlFor="name">
                            Title or Position
                          </label>
                          <select
                            id="position"
                            name="position"
                            className="form-control input-height"
                            value={user.position}
                            onChange={this.handleChange}
                          >
                            <option className="bold">Select Title Position</option>
                            {titleOrPositionsItems.length > 0 &&
                              titleOrPositionsItems.map((titleOrPositionsItem) => (
                                <option key={titleOrPositionsItem.id}>
                                  {titleOrPositionsItem.title}
                                </option>
                              ))}
                          </select>
                          {submitted && !user.position && (
                            <div className="help-block">
                              Title or Position is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={ "form-group fg" + (submitted && !user.position ? " has-error" : "")}>
                        <label className="st_name" htmlFor="profile">
                          Upload Profile Image
                        </label>
                        <div>
                          <input className="w-100" type="file" accept=".jpg, .png" name="" id="" />
                        </div>
                      </div>
                      {/* <div className="">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !user.userCategory ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="name">
                          User Category
                        </label>
                        <select
                          id="userCategory"
                          required
                          name="userCategory"
                          className="form-control"
                          value={user.userCategory}
                          onChange={this.handleChange}
                        >
                          <option className="bold" value="1">
                            Select category
                          </option>
                          <option>Business Account</option>
                          <option>Partner</option>
                        </select>
                        {submitted && !user.userCategory && (
                          <div className="help-block">
                            User Category is required
                          </div>
                        )}
                      </div>
                    </div> */}
                      <div className="my-3 text">
                        <input
                          type="checkbox"
                          name="privacyPolicy"
                          onChange={(e) => {
                            if (this.state.user.privacyPolicy) {
                              this.setState({
                                user: {
                                  ...user,
                                  privacyPolicy: "",
                                },
                              });
                            } else {
                              this.setState({
                                user: {
                                  ...user,
                                  privacyPolicy: e.target.value,
                                },
                              });
                            }
                          }}
                          required
                        />
                        <span className="p-2">
                          I have read the&nbsp;
                          <Link to="/privacy_policy" target="_blank">
                            Privacy Policy
                          </Link>
                          &nbsp; &amp; &nbsp;
                          <Link to="/terms_and_conditions" target="_blank">
                            Terms & Conditions
                          </Link>
                          &nbsp; and agree to them.
                        </span>
                      </div>
                      {/* <div className="view_bottoma">
                      {user.userCategory &&
                      user.register_company_name &&
                      user.businessNumber &&
                      user.privacyPolicy &&
                      user.country ? (
                        <a className="btn btn-next width-50 ml-auto">Next</a>
                      ) : (
                        <a className="btn btn-next width-50 ml-auto disabledd disabled-link">
                          Next
                        </a>
                      )}
                    </div> */}
                      <div className="btns-group view_bottoma mt-3">
                        <button
                          type="submit"
                          value="Submit"
                          id="submit-form"
                          className="btn btn-prev"
                          onClick={(e) => this.handleSubmit(e)}
                          disabled={
                            !user.privacyPolicy ||
                            !user.password ||
                            !user.firstName ||
                            !user.lastName ||
                            !user.register_company_name ||
                            !user.businessNumber ||
                            !user.email
                          }
                        >
                          Register
                        </button>
                      </div>
                      <div className="global d-flex align-items-center justify-content-center mt-3" >
                        If you are already registered then,&nbsp;&nbsp;
                        <NavLink className="login-btn" to="/login">
                          Login Here
                        </NavLink>
                      </div>
                    </div>
                    {/* <div className="step-forms">
                  <div className="text_sing mb-4">
                    <h4 className="Account">Set Up Your User Profile Below</h4>
                    <p className="faster_oval">
                      Please make sure you fill in all onboarding information
                      for quick account approval. Contact us, if you have any
                      questions or need any help.
                    </p>
                  </div>
                  <div className="ster_form">
                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.firstName ? " has-error" : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              First Name
                            </label>
                            <input
                              className="form-control input-height"
                              id="firstName"
                              type="text"
                              name="firstName"
                              placeholder="Enter First Name"
                              value={user.firstName}
                              onChange={this.handleChange}
                            />
                            {submitted && !user.firstName && (
                              <div className="help-block">
                                First Name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={
                              "form-group fg" +
                              (submitted && !user.lastName ? " has-error" : "")
                            }
                          >
                            <label className="st_name" htmlFor="name">
                              Last Name
                            </label>
                            <input
                              className="form-control input-height"
                              id="lastName"
                              type="text"
                              placeholder="Enter Last Name"
                              name="lastName"
                              value={user.lastName}
                              onChange={this.handleChange}
                            />
                            {submitted && !user.lastName && (
                              <div className="help-block">
                                Last Name is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !user.position ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="name">
                          Title or Position
                        </label>
                        <select
                          id="position"
                          name="position"
                          className="form-control"
                          value={user.position}
                          onChange={this.handleChange}
                        >
                          <option className="bold">
                            Select Title Position
                          </option>
                          {titleOrPositionsItems.map((titleOrPositionsItem) => (
                            <option key={titleOrPositionsItem.id}>
                              {titleOrPositionsItem.title}
                            </option>
                          ))}
                        </select>
                        {submitted && !user.position && (
                          <div className="help-block">
                            Title or Position is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !this.state.no_of_users
                            ? " has-error"
                            : "")
                        }
                      >
                        <label className="st_name" htmlFor="name">
                          No. of users
                        </label>
                        <select
                          id="no_of_users"
                          required
                          name="no_of_users"
                          className="form-control"
                          value={this.state.no_of_users}
                          onChange={this.handleChangeForUser}
                        >
                          <option>Select no. of users</option>
                          <option value="1-5">1 to 5</option>
                          <option value="6-20">6 to 20</option>
                          <option value="21+">21+</option>
                        </select>
                        {submitted && !this.state.no_of_users && (
                          <div className="help-block">
                            No of Users is required
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div
                        className={
                          "form-group fg" +
                          (submitted && !user.email ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="email">
                          Corporate Email
                        </label>
                        <input
                          className="form-control input-height"
                          id="email"
                          type="email"
                          name="email"
                          value={user.email}
                          placeholder="Enter Email Address"
                          onChange={this.handleChange}
                        />
                        {this.state.emailValidation === false && (
                          <div className="help-block">
                            {this.state.emailValidationMessage}
                          </div>
                        )}
                        {submitted && !user.email && (
                          <div className="help-block">Email is required</div>
                        )}
                      </div>
                    </div>
                    <div className="">
                      <div
                        className={
                          "form-group fg eye-frame" +
                          (submitted && !user.password ? " has-error" : "")
                        }
                      >
                        <label className="st_name" htmlFor="name">
                          Password&nbsp;
                          <span data-tip data-for="registerTip">
                            <i
                              className="fa fa-question-circle"
                              aria-hidden="true"
                            ></i>
                          </span>
                        </label>
                        <ReactTooltip
                          id="registerTip"
                          place="top"
                          effect="solid"
                        >
                          <h6>Password Must :</h6>
                          <ul>
                            <li>Have at least one lower case character</li>
                            <li>Have at least one Capital letter</li>
                            <li>Have at least one number</li>
                            <li>Have at least one special character</li>
                            <li>Be at least 8 characters</li>
                            <li>Not be a common password</li>
                          </ul>
                          <h6>
                            For Eg : <b>Password@123</b>
                          </h6>
                        </ReactTooltip>
                        <input
                          className="form-control input-height"
                          id="password"
                          type={type}
                          name="password"
                          placeholder="Enter Strong Password"
                          value={user.password}
                          onChange={this.handleChange}
                        />
                        {this.state.passwordValidation === false && (
                          <div className="help-block">
                            {this.state.passwordValidationMessage}
                          </div>
                        )}
                        {submitted && !user.password && (
                          <div className="help-block">Password is required</div>
                        )}
                        <span className="eye-under" onClick={this.showHide}>
                          {this.state.type === "input" ? (
                            <i className="fas fa-eye-slash"></i>
                          ) : (
                            <i className="fas fa-eye"></i>
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="glee">
                            <div className="bacei">
                              <ReCAPTCHA
                                sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                                onChange={(e) => this.handleVerification(e)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="btns-group view_bottoma mt-3">
                          {/* <a href="#" className="btn btn-prev w-50">
                            Previous
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {user.firstName &&
                          user.lastName &&
                          user.position &&
                          user.email &&
                          user.password ? (
                            <span
                              type="submit"
                              value="Submit"
                              className="new_button_style d-block w-50" 
                              onClick={(e) => this.handleSubmit(e)}
                            >
                              Register
                            </span>
                          ) : ( 
                            <button
                              type="submit"
                              value="Submit"
                              id="submit-form"
                              className="btn btn-prev"
                              onClick={(e) => this.handleSubmit(e)}
                            >
                              Register
                            </button>
                          {/* )} 
                        </div>
                        <div className="global d-flex justify-content-center my-3">
                          If you are already registered then,&nbsp;&nbsp;
                          <NavLink className="login-btn" to="/">
                            Login Here
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                  </form>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}
