import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../img/Zais_logo.png";
import "../sidebar/common.css";
import { Col, Row } from "react-bootstrap";
import LoginImages from '../../img/login-image.jpg'

export default class ResetMassage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  componentDidMount() {

  }
  render() {
    return (
      <div>
        <Row>
          <Col md={7}>
            <div className="login-left-panel">
              <img src={LoginImages} alt="" />
            </div>
          </Col>
          <Col md={5}>
            <section className="login">
              <div className="login_part">
                <div className="sing_log">
                  <div className="sing_one">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="text_sing">
                    {/* <h4 className="Account">Reset Your Password</h4> */}
                    <p className="massage">Check your email for a link to Reset your Password. if it doesn't appear within a few minutes, check your Spam folder</p>

                    <p>If you know password., then click on Login Button.</p>
                    <div className="global d-flex justify-content-center m-3">
                      <NavLink className="page_width page_save button_color" to="/login">
                        Login
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}
