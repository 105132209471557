import AuditCard from "./AuditCard";
import React, { useEffect, useState } from "react";
import { apiCall } from "../../../_services/apiCall";
import config from "../../../config/config.json";
import swal from "sweetalert";
import "./AuditCard.css";
import Header from "../../header/header";
import Sidebar from "../../sidebar/sidebar";

const AuditList = ({ listing }) => {
  const [audit_Data, setAudit_Data] = useState([]);

  console.log("audit history page");

  useEffect(() => {
    (async () => {
      const { isSuccess, data } = await apiCall(
        `${
          config.API_URL
        }getAuditHistoryListing?type=${listing}&financial_year=2023-2024&current_role=${localStorage.getItem(
          "role"
        )}`
      );

      if (isSuccess) {
        console.log(data?.data);
        setAudit_Data(data?.data);
      } else {
        swal({
          icon: "error",
          title: data.message, //check this
          timer: 1000,
        });
      }
    })();
  }, [listing]);

  return (
    <>
      {/* <Header />
      <Sidebar /> */}
      {audit_Data.map((audit_data) => (
        <AuditCard audit_data={audit_data} />
      ))}
    </>
  );
};

export default AuditList;
