import React, { useState } from "react";
import { Form, InputGroup, Row, Col, Modal } from "react-bootstrap";
export default function TebularInputCard({
  qualitativeStatus,
  qualitativeRemark,
  item,
  value,
  handleOnChange,
  handleKeyDown,
  handleAddRow,
  meterListData,
}) {
  const answer2D = value && value.length > 0 ? value : [];  
  const [source, setSource] = useState("");
  return (
    <>
    <Form.Select
    aria-label="Default select example p-5"
    className="mb-2 form-control"
    name="meter_id"
    value={source}
    onChange={(event) => setSource(event.target.value)}

    required
  >
    <option value="" hidden>
      Please Select the Source
    </option>
    {meterListData &&
      meterListData?.map((data, index) => {
        return (
          <option key={index} value={data.id}>
            {data.process}
          </option>
        );
      })}
  </Form.Select>
  
    <div className="table_width">
      <div className="table__wrapper">
        <table className="table">
          <thead>
            <tr className="option_wrapper">
              <th>
                {item?.question_detail &&
                  item?.question_detail
                    .filter(function (item) {
                      return item.option_type == "firstRowAndColumnHead";
                    })
                    .map((col, ind) => {
                      return <th key={ind}>{col.option}</th>;
                    })}
                {/* {qualitativeStatus && (
                <div>
                  {" "}
                  Status:- {""}
                  {qualitativeStatus?.replace(/_/g, " ")}
                </div>
              )}
              {qualitativeRemark && (
                <div>
                  Auditor Remark:- {"  "}
                  {qualitativeRemark}
                </div>
              )} */}
              </th>
              {item?.question_detail &&
                item?.question_detail
                  .filter(function (item) {
                    return item.option_type == "column";
                  })
                  .map((col, ind) => {
                    return <th key={ind}>{col.option}</th>;
                  })}
            </tr>
          </thead>
          <tbody>
            {item?.question_detail &&
              item?.question_detail
                .filter(function (item) {
                  return item.option_type == "row";
                })
                .map((row, indRow) => {
                  return (
                    <tr
                      key={indRow}
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      <th className="option_wrapper">{row.option}</th>
                      {item?.question_detail &&
                        item?.question_detail
                          .filter(function (item) {
                            return item.option_type == "column";
                          })
                          .map((col, indCol) => {
                            const startDate = col.option.includes("Start date");
                            const endDate = col.option.includes("End date");
                            const isEmail =
                              col.option.includes("E mail") ||
                              row.option.includes("E mail");
                            const isDate =
                              col.option.includes("date") ||
                              row.option.includes("date");
                            const isYear =
                              col.option.includes("year") ||
                              row.option.includes("year");
                            const isContact =
                              col.option.includes("Contact") ||
                              row.option.includes("Contact");
                            const isNumber =
                              col.option.includes("%") ||
                              row.option.includes("%") ||
                              col.option.includes("Number") ||
                              row.option.includes("Number") ||
                              col.option.includes("Total") ||
                              row.option.includes("Total") ||
                              col.option.includes("No.") ||
                              row.option.includes("No.") ||
                              col.option.includes("Rate") ||
                              row.option.includes("Rate");

                          let inputType = "text";
                          if (isNumber ) {
                            inputType = "number";
                          } else if (isDate || isYear) {
                            inputType = "date";
                          } else if (isEmail) {                           
                            inputType = "email";
                          } else if(isContact) {
                            inputType ="tel";
                          }
                          const value =
                            (answer2D &&
                              answer2D[indRow] &&
                              answer2D[indRow][indCol]) ||
                            "";
                          return (
                            <td key={indCol}>
                              <input
                                value={value}
                                type={inputType}
                                onChange={(event) => {
                                  const tempObj = {
                                    ...item,
                                    indexRow: indRow,
                                    indexCol: indCol,
                                  };
                                  handleOnChange(
                                    tempObj,
                                    event,
                                    item?.questionType,
                                    source,
                                    endDate
                                  );
                                }}
                                onKeyDown={(event) => {
                                  const tempObj = {
                                    ...item,
                                    indexRow: indRow,
                                    indexCol: indCol,
                                  };
                                  handleKeyDown(tempObj, event);
                                }}
                              />
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
        </tbody>
      </table>
      {item?.add_row == 1 ? (
        <button className="new_button_style mb-3" onClick={handleAddRow}>
          Add new row
        </button>
      ) : (
        ""
      )}
    </div>
    </div>
    </>
  )
}
