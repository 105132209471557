/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { authenticationService } from "../../_services/authentication";
import { history } from "../../_helpers/history";
import { Link, NavLink, useHistory } from "react-router-dom";
import config from "../../config/config.json";
import "./header.css";
import {
  getSubscriptionAuth,
  checkIsAuthenticated,
} from "../../utils/UniversalFunction";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import Switch from "react-switch";
import axios from "axios";
import swal from "sweetalert";
import { apiCall } from "../../_services/apiCall";
import { ROLE_ID_MAPPING } from "../../_constants/constants";
import { Dropdown } from "react-bootstrap";
import { transformRoleOrder } from "../../utils/UniversalFunction";

const ProfilePics =
  "https://res.cloudinary.com/dmklsntsw/image/upload/v1658480882/dummyPic.75a04487_fqfqey.png";
export default class header extends Component {
  static contextType = PermissionMenuContext;
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      usersData: [],
      name: "",
      profilePic: null,
      firstName: "",
      lastName: "",
      checked: false,
      role: localStorage.getItem("role"),
      roles: transformRoleOrder(
        JSON.parse(localStorage.getItem("currentUser"))?.user_type_code
      ),
    };

    this.goToPreviousPath = this.goToPreviousPath.bind(this);
    this.routeChecking = this.routeChecking.bind(this);
    this.getProfileData = this.getProfileData.bind(this);
    this.calculateTime = this.calculateTime.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.SwitchUserRole = this.SwitchUserRole.bind(this);
  }

  handleChange(checked) {
    this.setState({ checked: checked });
    if (this.state.checked) {
      this.context.setToggleUserRole("company");
    } else {
      this.context.setToggleUserRole("supplier");
    }
  }
  goToPreviousPath() {
    window.history.back();
  }

  logout() {
    authenticationService.logout();
    history.push("/");
    localStorage.clear();
  }
  // getProfileData

  async routeChecking() {
    let data = await checkIsAuthenticated();
    let checkCondition = data.status === false;

    if (checkCondition) {
      localStorage.clear();
      return false;
    } else {
      const subscriptionAuth = getSubscriptionAuth("subscriptionAuth");
      // if (subscriptionAuth === false || subscriptionAuth === undefined) {
      //   window.location.href = "/#/subscription_plan";
      // }
      const currentUser = localStorage.getItem("role");
      if (currentUser === null) {
        window.location.href = "/";
      } else {
        if (currentUser === "super_admin" || currentUser === "sub_admin") {
          window.location.href = "/";
        }
      }
      return true;
    }
  }

  saveToContext = (payloadValue, typeValue) => {
    const { dispatch } = this.context;
    dispatch({
      type: typeValue,
      payload: payloadValue,
    });
  };

  async getProfileData(role_id) {
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
    };
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getProfileData?userId=${localStorage.getItem(
        "user_temp_id"
      )}${role_id ? `&role_id=${role_id}` : ""}`,
      {}
    );
    if (isSuccess) {
      localStorage.setItem("user_temp_id", JSON.stringify(data?.user?.id));
      localStorage.setItem("role", JSON.stringify(data?.currentRole).replaceAll('"','').toUpperCase());
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ ...data?.user, is_head: data?.is_head , hasValidPlan:data?.hasValidPlan})
      );
      localStorage.setItem("menu", JSON.stringify(data?.menu));
      localStorage.setItem("subscriptionAuth", JSON.stringify(200));

      const menu = JSON.parse(localStorage.getItem("menu"));
      this.saveToContext(menu, "Add_Menu");
      this.saveToContext(localStorage.getItem("role"), "Add_Role");
      // if (data.user[0]?.userCategory !== "Partner") {
      //   this.calculateTime(data.user[0]?.subscriptionExpiredAt);
      // }
      let beforeThis = data?.user?.profile_picture?.split(":")[0];
      this.setState({
        isLoaded: true,
        usersData: data.user,
        name: data.user?.register_company_name,
        profilePic:
          data?.user?.profile_picture && beforeThis != "https"
            ? ProfilePics
            : data?.user?.profile_picture,
        firstName: data?.user?.first_name,
        lastName: data?.user?.last_name,
        user_type: data?.user?.currentRole,
        roles: transformRoleOrder(data?.user?.user_type_code),
      });
    }
  }

  calculateTime(endDate) {
    let url = document.URL.split("/");
    if (url[3] !== "checkout") {
      if (endDate !== null) {
        let start = Date.now() / 1000;
        let end = endDate;
        let dd = start.toFixed(0);
        function getMinDiff(startDate, endDate) {
          let response = false;
          if (endDate < startDate) {
            response = true;
          }
          return response;
        }
        let diffMinutes = getMinDiff(dd, end);
        if (diffMinutes === true) {
          window.location.href = "/#/subscription_plan";
        }
      }
    }
  }

  async componentDidMount() {
    let getData = await this.routeChecking();
    let checkCondition = getData === false;
    if (checkCondition) {
      window.location.href = "/";
    } 
    // else {
    //   this.getProfileData();
    // }
  }

  sidebarOpen() {
    const div = document.getElementById("sidebar-wrapper");
    const res = div.classList.contains("active-sidebar"); // true

    if (res) {
      document
        .getElementById("sidebar-wrapper")
        .classList.remove("active-sidebar");
      document.getElementById("sidebar-wrapper").style.display = "block";
      document.getElementById("sidebar-wrapper").style.zIndex = "3";
      setTimeout(() => {
        document.getElementById("sidebar-wrapper").style.marginLeft = "0rem";
      }, 200);
    } else {
      document
        .getElementById("sidebar-wrapper")
        .classList.add("active-sidebar");
      document.getElementById("sidebar-wrapper").style.marginLeft = "-15rem";
      setTimeout(() => {
        document.getElementById("sidebar-wrapper").style.display = "none";
      }, 200);
    }
  }

  SwitchUserRole = async (e) => {
    let newRole = e.target.getAttribute("value").toUpperCase();
    this.setState({ ...this.state, user_type: newRole });
    const headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      Accept: "application/json",
    };

    let response = await this.getProfileData(ROLE_ID_MAPPING[newRole]);
    try {
      let value = this;
      swal({
        icon: "success",
        title: "Success",
        text: "User role switched successfully .",
        timer: 2000,
      });

      // localStorage.setItem("currentUser", JSON.stringify(response));
      // localStorage.setItem("menu", JSON.stringify(response?.data?.menu));
      // localStorage.setItem(
      //   "user_temp_id",
      //   JSON.stringify(response?.data?.user_id)
      // );
      // localStorage.setItem(
      //   "role",
      //   JSON.stringify(response?.data?.user_type_code)
      // );
      // localStorage.setItem("subscriptionAuth", JSON.stringify(200));

      //  const menu =JSON.parse(localStorage.getItem("menu"));
      // this.saveToContext(menu, "Add_Menu");
      // this.saveToContext(JSON.parse(localStorage.getItem("role")), "Add_Role");

      // this.props.history.push('/home');
      return (window.location.href = config.baseURL + "/#/home");
    } catch (error) {}


  };

  render() {
    const { name, profilePic, lastName, firstName } = this.state;
    const { state } = this.context;
    let fullUrl = window.location.href.split("/");

    let urr = "";
    if (fullUrl[3] !== "sub_accounts") {
      urr = fullUrl.pop().toUpperCase();
    } else {
      urr = fullUrl[3];
    }
    let finalResult = urr.replaceAll("_", " ");
    if (finalResult === "SUPPLIER FAST") {
      finalResult = "SUPPLIER";
    }
    if (finalResult === "SUSTAINABLE") {
      finalResult = "SUSTAINABLE DEVELOPMENT GOALS";
    }

    if (fullUrl[3] === "checkout") {
      finalResult = "Success";
    }

    let uri = window.location.pathname.split("/");
    let path = uri[1];

    if (path === "Leadership_Governance") {
      finalResult = "Leadership & Governance";
    }
    if (path === "Cyber_Digital") {
      finalResult = "Cyber & Digital";
    }
    if (path === "Business_Model_Innovation") {
      finalResult = "Business Model & Innovation";
    }

    if (path === "suppliers_fast") {
      finalResult = "Suppliers";
    }

    return (
      <div>
        <div className="d-flex" id="wrapper">
          {/* <!-- Page content wrapper--> */}
          <div id="page-content-wrapper">
            {/* <!-- Top navigation--> */}
            <nav className="navbar navbar-expand-lg  border-bottom navclassName background topbar_esg">
              <div className="container-fluid">
                {/* <!--  <button className="desh" id="sidebarToggle">Deshboard</button> --> */}
                <div className="deahbord " id="sidebarToggle">
                  <NavLink
                    to="#"
                    className="sidebar_bar"
                    onClick={this.sidebarOpen}
                  >
                    <i className="fas fa-bars"></i>
                  </NavLink>
                  <h4 className="back_quninti back_quninti_2 m-0">
                    <a className="back_text">
                      <span className="step_icon">
                        <i
                          onClick={this.goToPreviousPath}
                          className="far fa-long-arrow-left"
                        ></i>
                      </span>
                      {finalResult}
                    </a>
                  </h4>
                </div>

                {/* <div className="toggle__btn__styling">
                <label className="toggle__label">
                  <span className="text-capitalize">{this.context?.toggleUserRole}</span>
                  <Switch
                    onChange={this.handleChange}
                    checked={this.state.checked}
                    uncheckedIcon={false}
                    checkedIcon={false}
                    onColor="#209ed1"
                    offColor="#209ed1"
                  />
                </label>
                </div> */}
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon">
                    <i className="fas fa-bars"></i>
                  </span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav align-items-center ms-auto">
                    <li className="nav-item">
                      {/* <div className="form-group has-search one"> */}

                      {this.state.roles.length === 1 && (
                        <span
                          style={{
                            marginTop: "16px",
                            marginRight: "20px",
                            background: "#209ed1",
                            color: "#fff",
                            borderRadius: "5px",
                            border: "none",
                            padding: "10px 20px",
                            cursor: "pointer",
                          }}
                        >
                          {this.state.roles[0].role}
                        </span>
                      )}
                      {this.state.roles.length > 1 && (
                        <Dropdown
                        // style={{
                        //   marginTop: "16px",
                        //   marginRight: "20px",
                        //   background: "#209ed1",
                        //   color: "#fff",
                        //   borderRadius: "5px",
                        //   border: "none",
                        //   padding: "10px 20px",
                        //   cursor: "pointer",
                        // }}
                        >
                          <Dropdown.Toggle
                            id="dropdown-basic"
                            style={{
                              background: "#209ed1",
                              borderColor: "#209ed1",
                              padding: "10px",
                              cursor: "pointer",
                              border: "none",
                            }}
                          >
                            {localStorage.getItem("role").replaceAll('"','')}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {this.state.roles.map((role) => (
                              <Dropdown.Item
                                as="button"
                                eventKey={role.role}
                                value={role.role}
                                onClick={this.SwitchUserRole}
                                disabled={
                                  localStorage.getItem("role").toLowerCase() ===
                                  role.role.toLowerCase()
                                    ? true
                                    : false
                                }
                              >
                                {role.role.toUpperCase().replaceAll('"','')}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                      <div className="has-search">
                        {/* <span className="fa fa-search form-control-feedback"></span> */}
                        {/* <input type="text" className="form-control" placeholder="Search..."/> */}
                      </div>
                    </li>
                    <li className="nav-item dropdown text_down">
                      <div className="image_round">
                        <img
                          src={profilePic}
                          alt="Ban"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src =
                              "https://placeimg.com/200/300/animals";
                          }}
                          className="image--coverq"
                        />
                      </div>
                      <a
                        className="nav-link home_drop"
                        id="navbarDropdown"
                        href="#"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {name}
                        <i
                          className="fa fa-caret-down elly"
                          aria-hidden="true"
                        ></i>
                        <p className="text_p">
                          {firstName} {lastName}
                        </p>
                      </a>

                      <div
                        className="dropdown-menu dropdown-menu-end dropdown_menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link to="/settings" className="dropdown-item">
                          <i className="fa fa-user"></i>
                          <span>My Profile</span>
                        </Link>
                        {/*<Link to="/Google_2FA" className="dropdown-item">
                          <i className="fa fa-lock"></i>
                          <span>Two Factor Auth</span>
                        </Link>
                        <Link to="/Setting_Billing" className="dropdown-item">
                          <i className="fa fa-dollar-sign"></i>
                          <span>Subscription</span>
                        </Link>
                        <Link to="/sub_accounts" className="dropdown-item">
                          <i className="fa fa-users"></i>
                          <span>Sub Admins</span>
                        </Link>*/}
                        <Link
                          onClick={this.logout}
                          to="/"
                          className="dropdown-item"
                        >
                          <i className="fa fa-sign-out"></i>
                          <span>Logout</span>
                        </Link>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    );
  }
}
