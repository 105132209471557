import React, { useState } from "react";
import TrandsInputCard from "./TrandsInputCard";
import { Form, Table } from "react-bootstrap";
import TebularInputCard from "./TebularInputCard";
import TebularInputCardAnswer from "./TabularInputCardAnswer";
import axios from "axios";
import config from "../../../../config/config.json";
import swal from "sweetalert";
import UploadIcon from "./Icons/uploadIcon";
import DownloadIcon from "./Icons/downloadIcon";
import '../../../sidebar/common.css'

const SectorAnswer = (props) => {
  const [renderedCount, setRenderedCount] = useState(0);

  const authValue = JSON.parse(localStorage.getItem("currentUser"));

  const {
    questionData,
    handleAnswers,
    answers,
    user_Is_head,
    handleOtherTypes,
    processList,
    changedData,
    meterList,
    filterQuestionList,
    setAnswers,
    setFilterQuestionList,
    uploadItem,
    initalValue,
    questionList,
  } = props;

  let value;
  let qualitativeAnswerBy;
  let qualitativeStatus;
  let qualitativeRemark;
  let tabularStatus;
  let tabularRemark;
  let combinedAnswers;
  let proof_document;
  let quantitativeAnswers = [{}];
  const filterAnswer = answers?.filter(
    (obj) => obj.question_id == questionData.id
  );

  if (
    filterAnswer &&
    filterAnswer[0] &&
    filterAnswer[0]["answer"] &&
    filterAnswer[0]["answer"] &&
    questionData.type === "quantitative_trends"
  ) {
    quantitativeAnswers = filterAnswer[0]["answer"];
  }
  value = (filterAnswer && filterAnswer[0] && filterAnswer[0]["answer"]) || "";
  qualitativeAnswerBy =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["answered_by_email"]) ||
    "";
  qualitativeStatus =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["audit_status"]) || "";
  qualitativeRemark =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["audit_remark"]) || "";
  combinedAnswers =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["combined_answers"]) ||
    "";
  proof_document =
    (filterAnswer && filterAnswer[0] && filterAnswer[0]["proof_document"]) ||
    "";

  const getSpacedString = (strValue) => {
    let str = "";
    for (const char of strValue) {
      if (["+", "-", "/", "*"].includes(char)) {
        str = str.concat(" ", char, " ");
      } else {
        str = str.concat(char);
      }
    }
    return str;
  };

  const getEvaluatedString = (strArray, item, event) => {
    let evaluatedStr = "";
    for (const value of strArray) {
      let str = value && value.toLowerCase();
      console.log("looping str value", value);
      if (["+", "-", "/", "*"].includes(value)) {
        evaluatedStr = evaluatedStr.concat(value);
      } else if (str) {
        console.log("value exist");
        if (str.includes("r") && str.includes("c") && str.includes("q")) {
          const qIndex = str.indexOf("q");
          const cIndex = str.indexOf("c");
          const rIndex = str.indexOf("r");
          if (cIndex > rIndex > qIndex) {
            const qNum = str.substring(1, rIndex);
            const rNum = str.substring(rIndex + 1, cIndex);
            const cNum = str.substring(cIndex + 1);
            const question = questionList && questionList[+qNum - 1];
            if (
              question &&
              question["id"] &&
              question["questionType"] === "tabular_question"
            ) {
              const answerArr = answers?.filter((obj) => {
                if (obj["question_id"] == question["id"]) {
                  return obj;
                }
              });
              const tabularAns =
                answerArr && answerArr[0] && answerArr[0]["answer"];
              const answer =
                tabularAns &&
                tabularAns[+rNum - 1] &&
                tabularAns[+rNum - 1][+cNum - 1];

              if (isNaN(answer)) {
                //handleAnswers(item, event, item["questionType"], "");
                alert(
                  `For ${value} the answer is not a number type. i.e. ${answer}`
                );
                break;
              } else {
                evaluatedStr = evaluatedStr.concat(answer);
              }
            } else {
              // handleAnswers(item, event, item["questionType"], "");
              alert(
                `${
                  question["id"]
                    ? `${value} is not a tabular type question.`
                    : `No question exist for ${value}`
                }`
              );
              break;
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `Please enter tabluar question ${value} in proper format. eg Q2R1C2`
            );
            break;
          }
          //getting 2D array value
        } else if (str.includes("q")) {
          const index = str.substring(1);
          const question = questionList && questionList[+index - 1];
          if (
            question &&
            question["id"] &&
            question["questionType"] === "quantitative"
          ) {
            const answerArr = answers?.filter((obj) => {
              if (obj["question_id"] == question["id"]) {
                return obj;
              }
            });
            const answer = answerArr && answerArr[0] && answerArr[0]["answer"];

            if (isNaN(answer)) {
              //handleAnswers(item, event, item["questionType"], "");
              alert(
                `For ${value} the answer is not a number type. i.e. ${answer}`
              );
              break;
            } else {
              evaluatedStr = evaluatedStr.concat(answer);
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `${
                question["id"]
                  ? `${value} is not a quantative type question.`
                  : `No question exist for ${value}`
              }`
            );
            break;
          }
        }
      }
    }
    return evaluatedStr;
  };

  const handlekeyPress = (item, event) => {
    const str = event?.target?.value?.trim();
    if (str && str.startsWith("=")) {
      if (event.key === "Enter" || event.keyCode === 13) {
        console.log("getting value", str);
        const spacedString = getSpacedString(str.substring(1));
        const strArray = spacedString.split(" ");
        const evaluatedString = getEvaluatedString(strArray);
        const regex = /[a-zA-Z]/g;
        if (!evaluatedString.match(regex)) {
          const answer = eval(evaluatedString);
          handleAnswers(item, null, item["questionType"], answer);
        } else {
          handleAnswers(item, null, item["questionType"], "");
          alert("Answer is not a proper number value");
        }
      }
    }
  };

  const uploadFile = (files) => {
    console.log("files", files);
    let tempAnswers = [...answers];
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime();

    const fileName = `${timestamp}_${selectedFile.name}`;
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    var requestOptions = {
      header: {
        "Content-Type": "multipart/form-data",
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${config.S3_API_URL}`, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        // let index = uploadItem.index;
        let item = uploadItem.item;
        let url = JSON.parse(result);

        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.question_id == item.id
          );

          if (check !== -1) {
            tempAnswers[check]["proof_document"] = url?.url;
            tempAnswers[check]["status"] = true;

            await setAnswers(tempAnswers);
          } else {
            let tempObj = {
              question_id: item?.id,
              question_type: item?.questionType,
              // answer_id: -1,
              status: true,
              proof_document: url?.url,
            };
            tempAnswers.push(tempObj);

            await setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleAddRow = async (item) => {
    const lastRow = item.question_detail
      ?.filter((detail) => detail.option_type === "row")
      .pop();
    axios
      .post(
        `${config.API_URL}addRow`,
        {
          question_id: item.id,
          row_value: lastRow.option,
        },
        {
          headers: {
            Authorization: `Bearer ${authValue?.data?.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const indexToUpdate = filterQuestionList.findIndex(
          (question) => question.id === item.id
        );
        const updatedQuestion = { ...filterQuestionList[indexToUpdate] };

        const newRow = {
          id: response?.data?.data?.id,
          question_id: 24,
          option_type: "row",
          option: response?.data?.data?.value?.toString(),
          rules: null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        updatedQuestion.question_detail.push(newRow);
        const updatedList = [
          ...filterQuestionList.slice(0, indexToUpdate),
          updatedQuestion,
          ...filterQuestionList.slice(indexToUpdate + 1),
        ];
        setFilterQuestionList(updatedList);
        let tempAnswers = [...answers];
        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.question_id == item.id
          );
          if (check !== -1) {
            const firstArrayLength = tempAnswers[check]?.answer[0].length;
            const newArray = Array(firstArrayLength).fill("");

            tempAnswers[check]?.answer.push(newArray);

            setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.response.data.message,
          timer: 1000,
        });
      });
  };

  const handleDeleteAnswers = (item, event, questionType, value, ind) => {
    switch (questionType) {
      case "tabular_question": {
        // handleTabularAnswers(item, event, index, value);
        break;
      }
      case "quantitative_trends": {
        handleDeleteQunatativeTreds(item, value);
        break;
      }
      case "quantitative": {
        // handlequantitativeTypes(item, event, index, value);
        break;
      }
      default: {
        handleOtherTypes(item, event, ind);
      }
    }
  };

  const handleDeleteQunatativeTreds = (item, index) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    for (let i = 0; i < tempAnswers.length; i++) {
      if (item.id == tempAnswers[i].question_id) {
        tempAnswers[i].answer.splice(index, 1);
        break;
      }
    }
    setAnswers(tempAnswers);
  };

  const changeAnswer = (check) => {
    const isValueExists = changedData.includes(check);
    if (!isValueExists) {
      // setAnswerChange((prevState) => {
      //   const updatedChange = Array.isArray(prevState) ? [...prevState] : [];
      //   updatedChange.push(check);
      //   return updatedChange;
      // });
      changedData.push(check);
    }
  };

  const handleItemRendered = () => {
    setRenderedCount((prevCount) => prevCount + 1);
  };
  return (
    <>
      <div >
        <div className="mb-2">
          <strong>{questionData.title}</strong>
        </div>
        {/* <Table bordered striped>
          <tbody>
            <tr>
              <td>Upload Attachment</td>
              <td></td>
            </tr>
          </tbody>
        </Table> */}
        {questionData.type == "qualitative" && (
          <>
            <Table striped bordered hover size="sm">
              <tbody>
                {qualitativeAnswerBy?.length > 0 && (
                  <tr>
                    <td style={{ width: "150px" }}>Answered By</td>
                    <td>{qualitativeAnswerBy}</td>
                  </tr>
                )}
                {qualitativeStatus?.length > 0 && (
                  <tr>
                    <td>Status</td>
                    <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                  </tr>
                )}
                {qualitativeRemark?.length > 0 && (
                  <tr>
                    <td> Auditor Remark</td>
                    <td>{qualitativeRemark}</td>
                  </tr>
                )}
              </tbody>
            </Table>
            <textarea
              className="form-control mb-3"
              name="answers"
              required
              value={value}
              placeholder="Leave a Answer here"
              onChange={(e) => {
                handleAnswers(questionData, e, questionData?.type);
              }}
            ></textarea>
          </>
        )}
        <div
          onLoad={handleItemRendered}
          className={`${
            questionData.assigned_to && user_Is_head !== 0
              ? "disable__section__wrapper row align-items-center"
              : "row align-items-center"
          }`}
          style={{ position: "relative" }}
        >
          {questionData.type === "quantitative_trends" && (
            <div className="col-md-12">
              <div>
                <TrandsInputCard
                  item={questionData}
                  answer={answers.filter(
                    (obj) => obj?.question_id === questionData?.id
                  )}
                  handleSubmit={(questionData, answer) =>
                    handleAnswers(
                      questionData,
                      answer,
                      questionData?.questionType
                    )
                  }
                  handleDeleteSubmit={(
                    questionData,
                    answer,
                    tempArray,
                    index
                  ) =>
                    handleDeleteAnswers(
                      questionData,
                      answer,
                      questionData?.type,
                      tempArray,
                      index
                    )
                  }
                  changeAns={() => changeAnswer(questionData?.title)}
                  formula={questionData?.formula == null ? false : true}
                  initalValue={initalValue}
                  meterListData={meterList}
                  processListData={processList}
                />
              </div>
            </div>
          )}
          <div className="col-md-12">
            {questionData.type == "yes_no" && (
              <div>
                <Table striped bordered hover size="sm">
                  <tbody>
                    {qualitativeAnswerBy?.length > 0 && (
                      <tr>
                        <td style={{ width: "150px" }}>Answered By</td>
                        <td>{qualitativeAnswerBy}</td>
                      </tr>
                    )}
                    {qualitativeStatus?.length > 0 && (
                      <tr>
                        <td>Status</td>
                        <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                      </tr>
                    )}
                    {qualitativeRemark?.length > 0 && (
                      <tr>
                        <td>Auditor Remark</td>
                        <td>{qualitativeRemark}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Form>
                  <div>
                    <input
                      inline="true"
                      label="Yes"
                      name="group1"
                      type={"radio"}
                      value={"yes"}
                      checked={value === "yes"}
                      onChange={(value) => {
                        handleAnswers(questionData, value, questionData?.type);
                      }}
                    />
                    <span className="mx-2">Yes</span>
                    <br></br>
                    <input
                      inline="true"
                      label="No"
                      name="group1"
                      type={"radio"}
                      value={"no"}
                      checked={value === "no"}
                      onChange={(value) => {
                        handleAnswers(questionData, value, questionData?.type);
                      }}
                    />
                    <span className="mx-2">No</span>
                  </div>
                </Form>
              </div>
            )}

            {questionData.type == "tabular_question" && (
              <TebularInputCard
                qualitativeStatus={qualitativeStatus}
                qualitativeRemark={qualitativeRemark}
                item={questionData}
                value={value}
                handleAddRow={() => handleAddRow(questionData)}
                handleOnChange={(
                  questionData,
                  event,
                  questionType,
                  source,
                  endDate
                ) =>
                  handleAnswers(
                    questionData,
                    event,
                    questionType,
                    source,
                    endDate
                  )
                }
                handleKeyDown={(questionData, event) =>
                  handlekeyPress(questionData, event)
                }
                meterListData={meterList}
              />
            )}
            {questionData.type == "quantitative" && (
              <>
                <Table striped bordered hover size="sm">
                  <tbody>
                    {qualitativeAnswerBy?.length > 0 && (
                      <tr>
                        <td style={{ width: "150px" }}>Answer By</td>
                        <td>{qualitativeAnswerBy}</td>
                      </tr>
                    )}
                    {qualitativeStatus?.length > 0 && (
                      <tr>
                        <td>Status</td>
                        <td>{qualitativeStatus?.replace(/_/g, " ")}</td>
                      </tr>
                    )}
                    {qualitativeRemark?.length > 0 && (
                      <tr>
                        <td>Auditor Remark</td>
                        <td>{qualitativeRemark}</td>
                      </tr>
                    )}
                    <tr>
                      <td>Upload Attatchment</td>
                      <td>
                        <UploadIcon />
                        {proof_document &&
                          proof_document.startsWith("http") && <DownloadIcon />}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <div>
                  <input
                    className="p-2 "
                    type="number"
                    value={value}
                    onKeyDown={(e) => {
                      handlekeyPress(questionData, e);
                    }}
                    onChange={(value) => {
                      handleAnswers(questionData, value, questionData?.type);
                    }}
                  />
                </div>
              </>
            )}
          </div>
          {questionData.type == "tabular_question" && (
            <TebularInputCardAnswer
              item={questionData}
              combinedAnswers={combinedAnswers}
              userIsHead={user_Is_head}
              userIsHistory={0}
              meterListData={meterList}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SectorAnswer;
