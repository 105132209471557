import React, { useEffect } from "react";
import { Badge, Button, Card, Col, Row, Table } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import ConvertTimeStamp from "./../../../utils/ConvertTimeStamp";

const AuditCard = ({ audit_data }) => {
  const history = useHistory();


  let dateTime = audit_data?.answer_details?.updatedAt? ConvertTimeStamp(audit_data.answer_details.updatedAt):
  ConvertTimeStamp(audit_data.answer_details.answered_at);

  console.log("dateTime", dateTime);

  return (
    <Card>
      <Table>
        <Row>
          <Col md={3} className="dateColumn">
            <p>{dateTime[0]}</p>
            <p>{dateTime[1].split(".")[0]}</p>
          </Col>
          <Col md={9}>
            <div>
              <Badge className={"bg-warning text-dark"}>
                {audit_data.answer_details["zb_users.register_company_name"]}
              </Badge>
              <Badge className={"bg-success"}>{audit_data.audit_status}</Badge>
            </div>
            <div>
              {audit_data.answer_details["zb_users.first_name"]}{" "}
              {audit_data.answer_details["zb_users.last_name"]}
            </div>

            <div style={{ float: "right", marginTop: "40px" }}>
              <Button
                onClick={() => {
                  sessionStorage.setItem("audit_data", JSON.stringify(audit_data))
                  history.push({
                    pathname: `/detailed_audit`,
                    //  state:audit_data,
                  });
                }}
              >
                View Detailed Report
              </Button>
            </div>
          </Col>
        </Row>
      </Table>
    </Card>
  );
};

export default AuditCard;
