import { sweetAlert } from "../../../src/utils/UniversalFunction";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import logo from "../../img/Zais_logo.png";
import "./login.css";
import { authenticationService } from "../../_services/authentication";
import "../sidebar/common.css";
import config from "../../config/config.json";
import env from "../../env";
import { apiCall } from "../../_services/apiCall";
import { Col, Row } from "react-bootstrap";
import LoginImages from '../../img/login-image.jpg'

export default class resetpass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        email: ""
      },
      submitted: false,
      captchaIsVerified: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleVerification = this.handleVerification.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value
      }
    });
  }
  handleVerification(e) {
    this.setState({
      captchaIsVerified: true
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ submitted: true });
    const { user, captchaIsVerified } = this.state;

    // document.getElementById('user_captcha_input').value = "";
    // if (captchaIsVerified) {
    if (user.email) {
      const { isSuccess, data } = await apiCall(config.BASE_URL + "resetPassword", {}, {
        email: user.email,
        token: true
      }, "POST");
      if (isSuccess) {
        setTimeout(() => {
          this.props.history.push("/reset_massage");
        }, 1000);
      }
      //   axios
      //     .post(config.API_URL + "password/email", {
      //       email: user.email,
      //       token: captchaIsVerified
      //     })
      //     .then((response) => {
      //       sweetAlert("success", response.data.message);
      //       setTimeout(() => {
      //         this.props.history.push("/reset_massage");
      //       }, 1000);
      //     })
      //     .catch(function (error) {
      //       if (error.response) {
      //         sweetAlert("error", error.response.data.message);
      //       }
      //     });
      // } else {
      //   sweetAlert("error", "Please fill Email Address");
      // }
    }
    //  else {
    //   sweetAlert("error", "Please verify Captcha");
    // }
  }

  componentDidMount() {
    if (authenticationService.currentUserValue) {
      this.props.history.push("/home");
    }
  }

  render() {
    const { user, submitted } = this.state;
    return (
      <div>
        <Row>
          <Col md={7}>
            <div className="login-left-panel">
              <img src={LoginImages} alt="" />
            </div>
          </Col>
          <Col md={5}>
            <section className="login">
              <div className="login_part">
                <div className="sing_log">
                  <div className="sing_one">
                    <img src={logo} alt="logo" />
                  </div>
                  <div className="text_sing">
                    {/* <h4 className="Account">Reset Password</h4> */}
                    <p className="faster_oval"> Enter your registered email address to reset your Zais account password. </p>
                  </div>
                  <form name="form" onSubmit={this.handleSubmit}>
                    <div className="ster_form">
                      <div className="make_form">
                        <div className="row">
                          <div className="col-lg-12 col-xs-12">
                            <div className="form_sign">
                              <div
                                className={
                                  "form-group fg" +
                                  (submitted && !user.register_company_name
                                    ? " has-error"
                                    : "")
                                }
                              >
                                <label className="st_name" htmlFor="name">
                                  Email
                                </label>
                                <input
                                  className="form-control name_nf"
                                  type="text"
                                  name="email"
                                  placeholder="Enter Register Email Address"
                                  value={user.email}
                                  onChange={this.handleChange}
                                />
                                {submitted && !user.email && (
                                  <div className="help-block">
                                    Email is required
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className="make_form">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="glee">
                            <div className="bacei">
                              <ReCAPTCHA
                                sitekey={env.GOOGLE_RECAPTCHA_SITE_KEY}
                                onChange={(e) => this.handleVerification(e)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      <div class="row my-4">
                        <div class="col-md-6">
                          <div class="global_link d-flex justify-content-center mx-0">
                            <NavLink className="page_width page_save w-100" to="/Login" >
                              Back to Login
                            </NavLink>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="global_link d-flex justify-content-center mx-0">
                            {user.email ? (
                              <button type="submit" value="Submit" className="page_width page_save w-100">
                                Reset
                              </button>
                            ) : (
                              <button type="submit" value="Submit" className="page_width page_save w-100" disabled >
                                Reset
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </Col>
        </Row>
      </div>
    );
  }
}
