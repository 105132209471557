import React, { useState, useCallback } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import { useLocation } from "react-router-dom";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import "./supplier_assessment.css";
import Table from "react-bootstrap/Table";
import YearPicker from "react-year-picker";
import View from "../../../../img/view.png";
import Edit from "../../../../img/edit.png";
import Delete from "../../../../img/delete.png";
import Duplicate from "../../../../img/duplicate.png";
import Multiselect from "multiselect-react-dropdown";

export default function Supplier_assessment(props) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  const handleClose = () => setShow(false);
  const handleClose1 = () => setShow1(false);
  const handleClose2 = () => setShow2(false);
  const handleShow = () => setShow(true);
  const handleShow1 = () => setShow1(true);
  const handleShow2 = () => setShow2(true);

  const handleChange = useCallback((date) => {
    console.log(date);
  }, []);
  return (
    <div>
      <Sidebar dataFromParent={location?.pathname} />
      <Header />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-sm-12 color_div_on">
                    <div className="heading heading_wth_text">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>Supplier Assesment List</h4>
                        <div>
                          <Button
                            className="new_button_style mx-2"
                            onClick={handleShow1}
                          >
                            Assign Assessment
                          </Button>
                          <Button
                            className="new_button_style"
                            onClick={handleShow}
                          >
                            New Supplier Assessment
                          </Button>
                        </div>
                      </div>
                    </div>
                    <hr class="mt-2" style={{color: "rgba(0, 0, 0, 0.27)"}}/>
                    <div>
                      <div className="table_f manage-detail admin-risk-table table-responsive">
                        <Table striped bordered hover size="sm">
                          <thead>
                            <tr className="heading_color">
                              <th style={{ width: 55 }}>ID</th>
                              <th scope="col">Assessment Name</th>
                              <th scope="col">Date</th>
                              <th style={{ width: 155 }} scope="col">
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>1</td>
                              <td>Assessment Name 1</td>
                              <td>03-08-2023</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="none"
                                    onClick={() => {
                                      props.history.push(
                                        "/supplier_assessment/assessement_detail"
                                      );
                                    }}
                                    className="non_underline_link bold view_c viewAssess"
                                  >
                                    <img src={View} alt="" srcSet="" />
                                  </Button>
                                  <Button
                                   onClick={handleShow2}
                                    variant="none"
                                    className="non_underline_link bold view_c editAssess"
                                  >
                                    <img src={Duplicate} alt="" srcSet="" />
                                  </Button>
                                  {/* <Button
                                    variant="none"
                                    className="non_underline_link bold view_c editAssess"
                                  >
                                    <img src={Edit} alt="" srcSet="" />
                                  </Button>
                                  <Button
                                    variant="none"
                                    className="non_underline_link bold view_c deleteAssess"
                                  >
                                    <img src={Delete} alt="" srcSet="" />
                                  </Button> */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>Assessment Name 1</td>
                              <td>03-08-2023</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="none"
                                    onClick={() => {
                                      props.history.push(
                                        "/supplier_assessment/assessement_detail"
                                      );
                                    }}
                                    className="non_underline_link bold view_c viewAssess"
                                  >
                                    <img src={View} alt="" srcSet="" />
                                  </Button>
                                  <Button
                                    variant="none"
                                    onClick={handleShow2}
                                    className="non_underline_link bold view_c editAssess"
                                  >
                                    <img src={Duplicate} alt="" srcSet="" />
                                  </Button>
                                  {/* <Button
                                    variant="none"
                                    className="non_underline_link bold view_c editAssess"
                                  >
                                    <img src={Edit} alt="" srcSet="" />
                                  </Button>
                                  <Button
                                    variant="none"
                                    className="non_underline_link bold view_c deleteAssess"
                                  >
                                    <img src={Delete} alt="" srcSet="" />
                                  </Button> */}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>1</td>
                              <td>Assessment Name 1</td>
                              <td>03-08-2023</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  <Button
                                    variant="none"
                                    onClick={() => {
                                      props.history.push(
                                        "/supplier_assessment/assessement_detail"
                                      );
                                    }}
                                    className="non_underline_link bold view_c viewAssess"
                                  >
                                    <img src={View} alt="" srcSet="" />
                                  </Button>
                                  <Button
                                    variant="none"
                                    onClick={handleShow2}
                                    className="non_underline_link bold view_c editAssess"
                                  >
                                    <img src={Duplicate} alt="" srcSet="" />
                                  </Button>
                                  {/* <Button
                                    variant="none"
                                    className="non_underline_link bold view_c editAssess"
                                  >
                                    <img src={Edit} alt="" srcSet="" />
                                  </Button>
                                  <Button
                                    variant="none"
                                    className="non_underline_link bold view_c deleteAssess"
                                  >
                                    <img src={Delete} alt="" srcSet="" />
                                  </Button> */}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Create Assessment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md-={6}>
                    <Form.Label>Assessment Title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Assessment Title"
                    />
                  </Col>
                  <Col md-={6}>
                    <Form.Label>Select Year</Form.Label>
                    <YearPicker onChange={handleChange} />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button className="new_button_style">Create</Button>
              </Modal.Footer>
            </Modal>



            <Modal show={show2} onHide={handleClose2}>
              <Modal.Header closeButton>
                <Modal.Title>Duplicate Assessment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md-={6}>
                    <Form.Label>Assessment Title</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Assessment Title"
                      value={"Assessment Name 1"}
                    />
                  </Col>
                  <Col md-={6}>
                    <Form.Label>Select Year</Form.Label>
                    <YearPicker onChange={handleChange} />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button className="new_button_style">Create</Button>
              </Modal.Footer>
            </Modal>

            <Modal show={show1} onHide={handleClose1}>
              <Modal.Header closeButton>
                <Modal.Title>Assign Assessment</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="mb-3">
                  <Form.Label>Select Supplier</Form.Label>
                  <Multiselect
                    displayValue="key"
                    onKeyPressFn={function noRefCheck() {}}
                    onRemove={function noRefCheck() {}}
                    onSearch={function noRefCheck() {}}
                    onSelect={function noRefCheck() {}}
                    options={[
                      {
                        cat: "Group 1",
                        key: "Supplier 1",
                      },
                      {
                        cat: "Group 1",
                        key: "Supplier 2",
                      },
                      {
                        cat: "Group 1",
                        key: "Supplier 3",
                      },
                      {
                        cat: "Group 2",
                        key: "Supplier 4",
                      },
                      {
                        cat: "Group 2",
                        key: "Supplier 5",
                      },
                      {
                        cat: "Group 2",
                        key: "Supplier 6",
                      },
                      {
                        cat: "Group 2",
                        key: "Supplier 7",
                      },
                    ]}
                    showCheckbox
                  />
                </div>
                <Form.Label>Select Assessment</Form.Label>
                <Multiselect
                  displayValue="key"
                  onKeyPressFn={function noRefCheck() {}}
                  onRemove={function noRefCheck() {}}
                  onSearch={function noRefCheck() {}}
                  onSelect={function noRefCheck() {}}
                  options={[
                    {
                      cat: "Group 1",
                      key: "Assessment 1",
                    },
                    {
                      cat: "Group 1",
                      key: "Assessment 2",
                    },
                    {
                      cat: "Group 1",
                      key: "Assessment 3",
                    },
                    {
                      cat: "Group 2",
                      key: "Assessment 4",
                    },
                    {
                      cat: "Group 2",
                      key: "Assessment 5",
                    },
                    {
                      cat: "Group 2",
                      key: "Assessment 6",
                    },
                    {
                      cat: "Group 2",
                      key: "Assessment 7",
                    },
                  ]}
                  showCheckbox
                />
              </Modal.Body>
              <Modal.Footer>
                <Button className="new_button_style">Assign</Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}
