import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Button, Col, Modal, Row, Spinner } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import config from "../../config/config.json";
import Edit from "../../img/edit.png";
import "./working_progress.css";
import { apiCall } from "../../_services/apiCall";
import { PermissionMenuContext } from "../../contextApi/permissionBasedMenuContext";
import Multiselect from "multiselect-react-dropdown";
import swal from "sweetalert";
const SubUsers = ({ tab, userPermissionList }) => {
  const [updateLoader, setUpdateLoader] = useState(false);
  const [meterData, setMeterData] = useState();
  const [userRole, setUserRole] = useState([]);
  const [source_ids, setSource_ids] = useState();
  const [mobileDetails, setMobileDetails] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [role_id, setRole_id] = useState("");
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
  });

  const [activeModal, setActiveModal] = useState();
  const [showModal, setShowModal] = useState(false);

  const [id, setId] = useState();
  const [status, setStatus] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [totalCount, setTotalCount] = useState();
  const [items, setItems] = useState([]);
  const [list, setList] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const requests = async () => {
      await serverRequest()
        .then()
        .catch((err) =>
          swal({
            icon: "error",
            title: err,
            showConfirmButton: false,
            timer: 1000,
          })
        );
    };

    requests();
  }, [tab]);

  const getMeterIdData = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getMeterId`,
      {},
      {
        company_id: localStorage.getItem("user_temp_id"),
        current_role: localStorage.getItem("role"),
      },
      "GET"
    );
    if (isSuccess) {
      setMeterData(data?.data);
    }
  };
  const getUserRole = async () => {
  
      const { isSuccess, data } = await apiCall(
        `${config.API_URL}getRoles`,
        {},
        { current_role: localStorage.getItem("role") },
        "GET"
      );
      if (isSuccess) {
        setUserRole(data?.data);
        setUpdateLoader(false);
      }
    
  };

  // const getSubUserModalDetails = async () => {
  //   await getUserRole();
  // };

  const onRemoveSourceHandler = (removedItem) => {
    const updatedSelectedId = source_ids.filter(
      (item) => item !== removedItem[0].id
    );
    setSource_ids(updatedSelectedId);
  };
  const onSelectSourceHandler = (selectedItems) => {
    const selectedItemValues = selectedItems.map((item) => item.id);
    setSource_ids(selectedItemValues);
  };
  const handleMobileChange = (event) => {
    const { name, value } = event.target;
    // Remove any non-digit characters from the input value
    const cleanedValue = value.replace(/\D/g, "");
    // Validate the input value against the Indian mobile number pattern
    const isValidMobile = /^[6-9]\d{0,9}$/.test(cleanedValue);

    setMobileDetails({
      [name]: cleanedValue,
      isValidMobile: isValidMobile || cleanedValue === "",
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    // const headers = {
    //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   Accept: "application/json",
    // };
    const { isSuccess, data } = await apiCall(
      config.API_URL + "createSubUser",
      {},
      {
        email: userDetails.email,
        mobile: userDetails.mobile,
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        role_id: role_id,
        source_ids: source_ids,
        current_role: localStorage.getItem("role"),
      },
      "POST"
    );
    if (isSuccess) {
      setTimeout(() => {
        handleCloseModal();
        setUserDetails({
          firstName: "",
          lastName: "",
          email: "",
          mobile: "",
        });
        setRole_id("");
        serverRequest();
        setSubmitted(false);
      }, 1000);
    }
    // {
    //   axios
    //     .post(
    //       config.API_URL + "createSubUser",
    //       {
    //         email: email,
    //         mobile: mobile,
    //         first_name: firstName,
    //         last_name: lastName,
    //         role_id:role_id
    //       },
    //       { headers }
    //     )
    //     .then((response) => {
    //       this.serverRequest();
    //       Swal.fire({
    //         icon: "success",
    //         title: response.data.message,
    //         showConfirmButton: false,
    //         timer: 1000,
    //       });
    //       setTimeout(() => {
    //         this.handleCloseModal();
    //         this.setState({
    //           firstName: "",
    //           lastName: "",
    //           email: "",
    //           mobile: "",
    //           role_id:'',
    //           submitted: false,
    //         });
    //         this.setState({ submitted: false });
    //       }, 1000);
    //     })
    //     .catch(function (error) {
    //       if (error.response) {
    //         Swal.fire({
    //           icon: "error",
    //           title: error.response.data.message,
    //           showConfirmButton: false,
    //           timer: 1000,
    //         });
    //       }
    //     });
  };
  const handleUpdate = async (event) => {
    if (event) {
      event.preventDefault();
    }
    setSubmitted(true);
    const { isSuccess, data } = await apiCall(
      config.API_URL + "updateSubUser",
      {},
      {
        email: userDetails.email,
        mobile_number: userDetails.mobile,
        first_name: userDetails.firstName,
        last_name: userDetails.lastName,
        status: status,
        id: id,
        role_id: role_id,
        current_role:localStorage.getItem("role"),
      },
      "POST"
    );
    if (isSuccess) {
      setTimeout(() => {
        handleCloseModal();
        setSubmitted(false);
        serverRequest();
      }, 1000);
    }
    // axios
    //   .post(
    //     config.API_URL + "updateSubUser",
    //     {
    //       email: email,
    //       mobile_number: mobile,
    //       first_name: firstName,
    //       last_name: lastName,
    //       status: status,
    //       id: id,
    //       role_id:role_id
    //     },
    //     { headers }
    //   )
    //   .then((response) => {
    //     this.serverRequest();
    //     Swal.fire({
    //       icon: "success",
    //       title: response.data.message,
    //       showConfirmButton: false,
    //       timer: 1000,
    //     });
    //     setTimeout(() => {
    //       this.handleCloseModal();
    //       this.handleUpdate();
    //       this.setState({ submitted: false });
    //     }, 1000);
    //   })
    //   .catch(function (error) {
    //     if (error.response) {
    //       Swal.fire({
    //         icon: "error",
    //         title: error.response.data.message,
    //         showConfirmButton: false,
    //         timer: 1000,
    //       });
    //     }
    //   });
  };
  // const handlePageClick = (event) => {
  //     let page = event.selected;
  //     setState({ page });
  // };
  const handleOpenModal = (val) => {
    setActiveModal(val);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const handleStatusChange = (event) => {
  //     const target = event?.target;
  //     const value = target.checked ? true : false;
  //     const name = target.name;
  //     setState({
  //         [name]: value,
  //     });
  // };
  const handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setUserDetails((userdetails) => ({ ...userdetails, [name]: value }));
  };
  const serverRequest = async () => {
    // console.log("Hello", "subuser");
    // const headers = {
    //   Authorization: `Bearer ${localStorage.getItem("token")}`,
    //   Accept: "application/json",
    // };
    const { isSuccess, data, error } = await apiCall(
      config.API_URL + `getSubUsers`,
      {},
      { type: "ALL", current_role: localStorage.getItem("role") },
      "GET"
    );
    if (isSuccess) {
      setIsLoaded(true);
      setTotalCount(data.totalCount);
      setItems(data.data);
      setList(data.data);
      // setUserRole(data?.data);

      // setPages(Math.floor(data?.data?.length / state.perPage))
    } else {
      setIsLoaded(false);
      setError(error);
    }
    // fetch(config.API_URL + `getSubUsers`, { headers })
    //   .then((res) => res.json())
    //   .then(
    //     (result) => {
    //       console.log(result, "SubAdmin Data");
    //       this.setState({
    //         isLoaded: true,
    //         totalCount: result.totalCount,
    //         items: result.data,
    //         list: result.data,
    //         pages: Math.floor(result?.data?.length / this.state.perPage),
    //       });
    //     },
    //     (error) => {
    //       this.setState({
    //         isLoaded: true,
    //         error,
    //       });
    //     }
    //   );
  };
  //   let items = list?.slice(page * perPage, (page + 1) * perPage);
  let ii = 0;
  let weathers =
    items?.map((item, key) => {
      return (
        <tr key={ii++}>
          <td>{key + 1}</td>
          <td>{item?.first_name}</td>
          <td>{item?.last_name}</td>
          <td>{item?.email}</td>
          <td>{item?.mobile_number}</td>
          <td>{item?.role_name}</td>

          <td>{item.status === true ? "Active" : "Inactive"}</td>
          <PermissionMenuContext.Consumer>
            {({ userPermissionList }) =>
              userPermissionList.includes("UPDATE SUB USER") && (
                <td>
                  <button
                    className="non_underline_link bold view_c border-none"
                    style={{ background: "none" }}
                    onClick={() => {
                      handleOpenModal("update");
                      setUpdateLoader(true);
                      getUserRole();

                      setUserDetails({
                        firstName: item?.first_name,
                        lastName: item?.last_name,
                        email: item?.email,
                        mobile: item?.mobile_number,
                      });
                      setSubmitted(false);
                      setRole_id(item?.role_id);
                      setUserRole(item.role_name);
                      setId(item?.id);
                      setStatus(item?.status);
                    }}
                  >
                    <img src={Edit} alt="" />
                  </button>
                </td>
              )
            }
          </PermissionMenuContext.Consumer>
        </tr>
      );
    }) || "";
  return (
    <div className="Introduction framwork_2">
      <section className="forms">
        <div className="row">
          <div className="col-md-12">
            {userPermissionList.includes("UPDATE SUB USER") && (
              <>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="heading">
                    <h4>Sub-User's Details</h4>
                  </div>
                  <div className="directly p-0">
                    <button
                      className="new_button_style"
                      variant="none"
                      onClick={() => {
                        handleOpenModal("login");
                        getUserRole();
                      }}
                    >
                      Add new Sub User
                    </button>
                  </div>
                </div>
                <hr className="line"></hr>
              </>
            )}

            <div className="table_f">
              <Table striped bordered hover size="sm">
                <thead>
                  <tr className="heading_color">
                    <th style={{ width: "5%" }}>S No.</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Role Name</th>

                    <th>Status</th>
                    {userPermissionList.includes("CREATE SUB USER") && (
                      <th>Edit</th>
                    )}
                  </tr>
                </thead>
                <tbody>{weathers}</tbody>
              </Table>
              <div className="Page navigation example"></div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        animation={true}
        size="md"
        className="modal_box"
        shadow-lg="border"
        show={showModal && activeModal === "login"}
      >
        <div className="modal-lg">
          <Modal.Header className="justify-content-between">
            <Modal.Title>
              <div className="pb3">
                <h4>Add New Sub Accounts</h4>
              </div>
            </Modal.Title>
            <Button variant="outline-dark" onClick={handleCloseModal}>
              <i className="fa fa-times"></i>
            </Button>
          </Modal.Header>
          {updateLoader === false ? (
            <div className="modal-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="forms">
                    <div className="form-group">
                      <label htmlFor="firstName" className="form-label fw-bold">
                        Enter sub-user's information
                      </label>

                      <div className="row">
                        <div className="col-lg-6 col-xs-6">
                          <div className="form-group pb-3">
                            <input
                              className="select_one industrylist"
                              aria-expanded="false"
                              placeholder="First Name"
                              // value={userDetails.firstName}
                              name="firstName"
                              type="text"
                              onChange={handleChange}
                            />
                            {submitted && !userDetails.firstName && (
                              <div className="help-block">
                                First name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xs-6">
                          <div className="form-group pb-3">
                            <input
                              className="select_one industrylist"
                              aria-expanded="false"
                              placeholder="Last Name"
                              // value={userDetails.lastName}
                              name="lastName"
                              type="text"
                              onChange={handleChange}
                            />
                            {submitted && !userDetails.lastName && (
                              <div className="help-block">
                                Last name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-6 col-xs-6">
                          <div className="form-group pb-3">
                            <input
                              className="select_one industrylist"
                              aria-expanded="false"
                              placeholder="Email"
                              // value={userDetails.email}
                              name="email"
                              type="email"
                              onChange={handleChange}
                            />
                            {submitted && !userDetails.email && (
                              <div className="help-block">
                                Email is required
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-lg-6 col-xs-6">
                          <div className="form-group">
                            <input
                              className="form-control"
                              aria-expanded="false"
                              placeholder="Mobile"
                              style={{ height: "42px" }}
                              // value={userDetails.mobile}
                              name="mobile"
                              type="tel"
                              onChange={(e) => {
                                handleMobileChange(e);
                                handleChange(e);
                              }}
                            />
                            {submitted && !userDetails.mobile && (
                              <div className="help-block">
                                Mobile number is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-12 col-xs-12">
                          <div className="form-group pb-3">
                            <select
                              // value={role_id}
                              className="select_one industrylist"
                              onChange={(e) => {
                                setRole_id(e.target.value);
                                console.log(e.target.value);
                                getMeterIdData();
                              }}
                            >
                              <option value="">select user role</option>
                              {userRole?.length > 0 &&
                                userRole?.map((data, index) => {
                                  return (
                                    <option key={index} value={data?.id}>
                                      {data?.role_display_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        {role_id === "12" && (
                          <div className="col-lg-12 col-xs-12">
                            <div className="form-group pb-3">
                              <select
                                // value={source_ids}
                                className="select_one industrylist"
                                onChange={(e) => {
                                  setSource_ids([...Number(e.target.value)]);
                                }}
                              >
                                <option value="">Select Source</option>
                                {meterData.length > 0 &&
                                  meterData?.map((data, index) => {
                                    return (
                                      <option
                                        key={index}
                                        //  value={data?.id}
                                      >
                                        {data?.process}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                        )}
                        {role_id === "7" && (
                          <div className="col-lg-12 col-xs-12">
                            <div className="form-group pb-3">
                              <label>Select Source</label>
                              <Multiselect
                                placeholder="Select Source"
                                displayValue="process"
                                className="multi_select_dropdown w-100"
                                options={meterData}
                                // ref={multiselectRef}
                                onRemove={onRemoveSourceHandler}
                                onSelect={onSelectSourceHandler}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="cenlr text-end pt-5">
                      <button
                        className="new_button_style mx-3"
                        onClick={() => {
                          setUserDetails({
                            firstName: "",
                            lastName: "",
                            email: "",
                            mobile: "",
                          });
                          setSubmitted(false);
                        }}
                      >
                        CANCEL
                      </button>
                      <button
                        className="new_button_style"
                        onClick={handleSubmit}
                      >
                        SUBMIT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Spinner
              style={{ marginLeft: "13px" }}
              animation="border"
              role="status"
            >
              {" "}
            </Spinner>
          )}
        </div>
      </Modal>

      <Modal
        animation={true}
        size="md"
        className="modal_box"
        shadow-lg="border"
        show={showModal && activeModal === "update"}
      >
        <div className="modal-lg">
          <Modal.Header className="d-flex align-itrms-center justify-content-between">
            <Modal.Title>
              <div className="pb3">
                <h4>Update User Information</h4>
              </div>
            </Modal.Title>
            <Button variant="outline-dark" onClick={handleCloseModal}>
              <i className="fa fa-times"></i>
            </Button>
          </Modal.Header>
          {updateLoader === false ? (
            <div className="modal-body vekp pt-0">
              <div className="forms">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label fw-bold">
                    Enter updated information
                  </label>
                  <Row>
                    <Col md={6}>
                      <input
                        className="select_one my-3"
                        aria-expanded="false"
                        placeholder="First Name"
                        value={userDetails.firstName}
                        name="firstName"
                        type="text"
                        onChange={handleChange}
                      />
                      {submitted && !userDetails.firstName && (
                        <div className="help-block">First name is required</div>
                      )}
                    </Col>
                    <Col md={6}>
                      <input
                        className="select_one my-3"
                        aria-expanded="false"
                        placeholder="Last Name"
                        value={userDetails.lastName}
                        name="lastName"
                        type="text"
                        onChange={handleChange}
                      />
                      {submitted && !userDetails.lastName && (
                        <div className="help-block">Last name is required</div>
                      )}
                    </Col>
                    <Col md={6}>
                      <input
                        className="select_one my-3"
                        aria-expanded="false"
                        placeholder="Email"
                        value={userDetails.email}
                        name="email"
                        type="email"
                        onChange={handleChange}
                        readOnly
                      />
                      {submitted && !userDetails.email && (
                        <div className="help-block">Email is required</div>
                      )}
                    </Col>
                    <Col md={6}>
                      <input
                        className="select_one my-3"
                        aria-expanded="false"
                        placeholder="Mobile"
                        value={userDetails.mobile}
                        name="mobile"
                        type="tel"
                        onChange={(event) => {
                          const { name, value } = event.target;
                          // Remove any non-digit characters from the input value
                          const cleanedValue = value.replace(/\D/g, "");
                          // Validate the input value against the Indian mobile number pattern
                          const isValidMobile = /^[6-9]\d{0,9}$/.test(
                            cleanedValue
                          );
                          if (isValidMobile || cleanedValue === "") {
                            setMobileDetails({
                              [name]: cleanedValue,
                              isValidMobile: isValidMobile,
                            });
                          }
                        }}
                      />
                      {submitted && !userDetails.mobile && (
                        <div className="help-block">
                          Mobile number is required
                        </div>
                      )}
                    </Col>
                    <Col md={6}>
                      <select
                        className="select_one my-3"
                        aria-expanded="false"
                        value={status}
                        name="status"
                        type="text"
                        onChange={handleChange}
                      >
                        <option value={true}>Active</option>
                        <option value={false}>Inactive</option>
                      </select>
                    </Col>
                    <Col md={6}>
                      <select
                        value={role_id}
                        className="select_one industrylist my-3"
                        onChange={(e) => {
                          setRole_id(e.target.value);
                        }}
                      >
                        <option value="">select user role</option>
                        {userRole.length > 0 &&
                          userRole?.map((data, index) => {
                            return (
                              <option key={index} value={data?.id}>
                                {data?.role_display_name}
                              </option>
                            );
                          })}
                      </select>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <button
                    className="new_button_style mx-3"
                    onClick={() =>
                      setUserDetails({
                        firstName: "",
                        lastName: "",
                        email: "",
                        mobile: "",
                      })
                    }
                  >
                    CANCEL
                  </button>
                  <button className="new_button_style" onClick={handleUpdate}>
                    UPDATE
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <Spinner
              style={{ marginLeft: "13px" }}
              animation="border"
              role="status"
            >
              {" "}
            </Spinner>
          )}
        </div>
      </Modal>
    </div>
  );
};
export default SubUsers;
