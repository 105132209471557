import React, { Component, useEffect, useState } from "react";
import Sidebar from "../../../sidebar/sidebar";
import Header from "../../../header/header";
import Loader from "../../../loader/Loader";
import AssignQuestions from "./AssignQuestions";
import axios from "axios";
import NumericInput from "react-numeric-input";
import "./SectorQuestion.css";
import config from "../../../../config/config.json";
import { Form, Accordion, Row, Col, Table } from "react-bootstrap";
import SectorQuestionTab from "./SectorQuestionTab";
import QuestionTypeTabSection from "./QuestionTypeTabSection";
import { Button, Modal, Spinner } from "react-bootstrap";
import TebularInputCardAnswer from "./TabularInputCardAnswer";
import TebularInputCard from "./TebularInputCard";
import TrandsInputCard from "./TrandsInputCard";
import swal from "sweetalert";
import { apiCall } from "../../../../_services/apiCall";
import { useRef } from "react";
import { sweetAlert } from "../../../../utils/UniversalFunction";
import { authenticationService } from "../../../../_services/authentication";
import { async } from "rxjs";
import { commonService } from "../../../../_services/common/commonService";
import Filters from "./Filter";
import SectorAnswer from "./SectorAnswer";

const SectorQuestion = (props) => {
  const [questionList, setQuestionList] = useState();
  const [sectorQuestionType, setSectorQuestionType] = useState();
  const [refrenceId, setRefrenceID] = useState();
  const [uploadItem, setUploadItem] = useState([]);
  const [url, setUrl] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("ALL");
  const [loading, setLoading] = useState(false);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [entity, setEntity] = useState([]);
  const [entityList, setEntityList] = useState([]);
  const [menuList, setMenuList] = useState([]);
  const [answerChange, setAnswerChange] = useState([]);
  const [AllQuestionList, setAllAllQuestionList] = useState({
    quantitative: [],
    tabular_question: [],
    qualitative: [],
    yes_no: [],
    quantitative_trends: [],
  });

  const [questionData, setQuestionData] = useState({
    title: "",
    id: "",
    type: "",
    question_detail: [],
  });
  const [inputFields, setInputFields] = useState({});
  const [renderedCount, setRenderedCount] = useState(0);

  const [filterQuestionList, setFilterQuestionList] = useState();
  const [data, setData] = useState([]);
  const [previousData, setPreviousData] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [financialYearId, setFinancialYearId] = useState("");
  const [financialYear, setFinancialYear] = useState([]);
  const authValue = JSON.parse(localStorage.getItem("currentUser"));
  const [initalValue, setInitialValue] = useState({});
  const user_Is_head = authValue?.is_head;
  const [userTypeCode, setUserTypeCode] = useState(authValue?.user_type_code);
  const [answerChangedData, setAnswerAnswerChangedData] = useState([]);
  const [meterList, setMeterList] = useState([]);
  const [processList, setProcessList] = useState([]);
  const ref = useRef();
  let changedData = [];
  useEffect(() => {
    // fetchquestionApi();

    if(entity[0]){
    getFinancialYear();
    }
    // getAnswerCompanyWise();
    // fetchStoredData();
  }, [refrenceId, entity[0]]);

  useEffect(() => {
    if (financialYearId && entity) fetchStoredData();
  }, [financialYearId, entity]);
  const getFinancialYear = async () => {
    const { isSuccess, data } = await apiCall(
      `${config.API_URL}getFinancialYear?type=${localStorage
        .getItem("role")
        .toLowerCase()}&entity=${entity[0]?.id}`
    );

    if (isSuccess) {
      setFinancialYear(data.data);
      if (data?.data?.length == 1) {
        setFinancialYearId(data.data[0].id);
        if (financialYearId && entity) {
          fetchStoredData();
        }
      }
    }
    // axios
    //   .get(`${config.API_URL}getFinancialYear`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("token")}`,
    //       "Content-Type": "application/json",
    //     },
    //   })
    //   .then((response) => {
    //     setFinancialYear(response.data.data);
    //   })
    //   .catch((error) => {
    //     console.log("error");
    //   });
  };
  useEffect(() => {
    setInitialValue({
      starting_month: JSON.parse(localStorage.getItem("currentUser"))
        .starting_month,
      frequency: JSON.parse(localStorage.getItem("currentUser")).frequency,
    });
  }, []);

  useEffect(async () => {
    if (localStorage.getItem("role").toLowerCase() === "company") {
      setEntity([{ name: "company", id: 1 }]);
      setEntityList([
        { name: "company", id: 1 },
        { name: "supplier", id: 3 },
      ]);
    }
    if (localStorage.getItem("role").toLowerCase() === "supplier") {
      const { isSuccess, data, error } = await getSupplierEntities();
      if (isSuccess) {
        console.log(data?.entities);
        setEntityList(
          (data?.entities).map((entity) => ({
            name: entity.register_company_name,
            id: entity.companyId,
          }))
        );
        setEntity([
          {
            name: data?.entities[0].register_company_name,
            id: data?.entities[0].companyId,
          },
        ]);
      }
      if (error) {
        swal({
          icon: "error",
          title: "Could not get entity companies!",
          timer: 1000,
        });
      }
    }
  }, []);

  const setQuestionDataFunction = (e, item) => {
    setQuestionData({
      title: item.title,
      id: item.id,
      type: item.questionType,
      question_detail: item.question_detail,
      qualitativeAnswerBy: item.qualitativeAnswerBy,
      qualitativeStatus: item.qualitativeStatus,
      qualitativeRemark: item.qualitativeRemark,
    });
  };

  const getSupplierEntities = async () => {
    return await apiCall(
      `${config.API_URL}getSupplierEntities?supplier_id=${
        JSON.parse(localStorage.getItem("currentUser")).id
      }`
    );
  };

  const setEntityValue = (value) => {
    console.log(entityList);
    let entityName = entityList.filter((entity) => {
      if (entity.id === parseInt(value)) {
        return entity;
      }
    });

    console.log([{ name: entityName[0].name, id: parseInt(value) }]);
    setEntity([{ name: entityName[0].name, id: parseInt(value) }]);
  };

  const fetchStoredData = async () => {
    if (user_Is_head == "0") {
      fetchquestionApi();
      return;
    }
    if (financialYearId) {
      const { isSuccess, data } = await apiCall(
        `${config.API_URL}getDataCompanyWise`,
        {},
        { type: "SQ", financial_year_id: financialYearId },
        "GET"
      );
      if (isSuccess) {
        const responseData = data.data;
        const storeData = JSON.parse(responseData.framework_topic_kpi || "{}");
        // console.log(sotoreData, "sotoreData");
        if (localStorage.getItem("role").toLowerCase() === "company") {
          if (!storeData.framework_id || storeData.framework_id.length === 0) {
            swal({
              title: "Please Select Framework for ESG Reporting.",
              text: "",
              icon: "error",
              button: "OK",
            });
          } else {
            fetchquestionApi(
              storeData.framework_id,
              storeData.topic_id,
              storeData.kpi_id
            );
          }
        }
        if (localStorage.getItem("role").toLowerCase() === "supplier") {
          fetchquestionApi();
        }
      }
    } else {
      swal({
        title: "Please Select Financial Year",
        text: "",
        icon: "error",
        button: "OK",
      });
    }
  };

  const fetchquestionApi = (framework_ids, topic_ids, kpi_ids) => {
    let clientType;
    if (type === "ALL") {
      clientType = "ALL";
      setLoading(true);
    } else {
      setQuestionLoading(true);
    }

    if (localStorage.getItem("role").toLowerCase() === "company") {
      if (user_Is_head == "1") {
        clientType = "CUSTOM";
      } else clientType = "USER_BASE";
    }
    if (localStorage.getItem("role").toLowerCase() === "supplier") {
      clientType = "SUPPLIER";
    }
    axios
      .get(
        `${
          config.API_URL
        }getSectorQuestion?type=${clientType}&entity=${encodeURIComponent(
          entity[0]?.name
        )}${
          user_Is_head == "1" &&
          localStorage.getItem("role").toLowerCase() === "company"
            ? `&framework_ids=[${framework_ids}]&topic_ids=[${topic_ids}]&kpi_ids=[${kpi_ids}]`
            : ""
        }&financial_year_id=${financialYearId}&questionnaire_type=SQ`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(async (response) => {
        if (localStorage.getItem("role") === "COMPANY") {
          getMeterIdData();
          getProcessIdData();
        }
        // getAnswerCompanyWise();
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          setTimeout(() => {
            if (type === "ALL") {
              setLoading(false);
            } else {
              setQuestionLoading(false);
            }
          }, 5000);

          setData(response.data.data);
          setAllAllQuestionList({
            quantitative: [],
            tabular_question: [],
            qualitative: [],
            yes_no: [],
            quantitative_trends: [],
          });
          setQuestionList(response.data.data);
          setFilterQuestionList(response.data.data);
          setAnswers(response.data.answers);
          const data = response.data.data;
          const newData = {}; // Use a new object to store the updated input fields
          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const id = item.id;
            newData[id] = {
              question_id: item?.id,
              question_type: item?.questionType,
              answer: "",
              // answer_id: -1,
              status: false,
            };
            if (item.questionType === "tabular_question") {
              const numberofRow = item.question_detail.filter(
                (d) => d.option_type === "row"
              ).length;
              const numberofColumn = item.question_detail.filter(
                (d) => d.option_type === "column"
              ).length;
              if (item && item.answer && item.answer.length > 0) {
                // Perform any necessary operations with item.answer
              }
              const array2D = Array.from({ length: numberofRow }, () =>
                Array.from({ length: numberofColumn }, () => 0)
              );
              newData[item.id]["answer"] = array2D;
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                tabular_question: [...prevState.tabular_question, item],
              }));
            } else if (item.questionType === "quantitative") {
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                quantitative: [...prevState.quantitative, item],
              }));
            } else if (item.questionType === "qualitative") {
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                qualitative: [...prevState.qualitative, item],
              }));
            } else if (item.questionType === "yes_no") {
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                yes_no: [...prevState.yes_no, item],
              }));
            } else if (item.questionType === "quantitative_trends") {
              newData[item.id]["answer"] = [];
              let i = {
                from_date: "",
                to_date: "",
                meter_id: "",
                process: "",
                reading_value: "",
                note: "",
              };
              newData[item.id]["answer"].push(i);
              setAllAllQuestionList((prevState) => ({
                ...prevState,
                quantitative_trends: [...prevState.quantitative_trends, item],
              }));
            }
          }
          setInputFields((prevState) => ({ ...prevState, ...newData }));
        } else {
          setData([]);
          if (type === "ALL") {
            setLoading(false);
          } else {
            setQuestionLoading(false);
          }
          setFilterQuestionList([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getAnswerCompanyWise = () => {
  //   axios
  //     .get(
  //       `${config.API_URL}getAnswerCompanyWise?financial_year_id=${financialYearId}&questionnaire_type=SQ`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       const data = response?.data?.data;
  //       if (data && data.length > 0) setAnswers(data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  const getMeterIdData = () => {
    axios
      .get(
        `${config.API_URL}getMeterId?company_id=${localStorage.getItem(
          "user_temp_id"
        )}&current_role=${localStorage.getItem("role")}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data?.data;
        setMeterList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getProcessIdData = () => {
    axios
      .get(
        `${config.API_URL}getProcessId?company_id=${localStorage.getItem(
          "user_temp_id"
        )}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const data = response?.data?.data;

        setProcessList(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const uploadFile = (files) => {
    console.log("files", files);
    let tempAnswers = [...answers];
    const selectedFile = files.target.files[0];
    const timestamp = new Date().getTime(); // Generate a timestamp

    const fileName = `${timestamp}_${selectedFile.name}`;
    var formdata = new FormData();
    formdata.append("file", selectedFile);
    formdata.append("fileName", fileName);
    formdata.append("filePath", "yasil/");

    var requestOptions = {
      header: {
        "Content-Type": "multipart/form-data", // Set the Content-Type header
      },
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${config.S3_API_URL}`, requestOptions)
      .then((response) => response.text())
      .then(async (result) => {
        let index = uploadItem.index;
        let item = uploadItem.item;
        let url = JSON.parse(result);

        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.question_id == item.id
          );

          if (check !== -1) {
            tempAnswers[check]["proof_document"] = url?.url;
            tempAnswers[check]["status"] = true;

            setAnswers(tempAnswers);
          } else {
            let tempObj = {
              question_id: item?.id,
              question_type: item?.questionType,
              answer_id: -1,
              status: true,
              proof_document: url?.url,
            };
            tempAnswers.push(tempObj);

            setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => console.log("error", error));
  };
  const handlekeyPress = (item, event) => {
    const str = event?.target?.value?.trim();
    if (str && str.startsWith("=")) {
      if (event.key === "Enter" || event.keyCode === 13) {
        console.log("getting value", str);
        //getting spaced string
        const spacedString = getSpacedString(str.substring(1));
        const strArray = spacedString.split(" ");
        //concating string to evaluate
        const evaluatedString = getEvaluatedString(strArray);
        const regex = /[a-zA-Z]/g;
        if (!evaluatedString.match(regex)) {
          const answer = eval(evaluatedString);
          handleAnswers(item, null, item["questionType"], answer);
        } else {
          handleAnswers(item, null, item["questionType"], "");
          alert("Answer is not a proper number value");
        }
      }
    }
  };

  const getEvaluatedString = (strArray, item, event) => {
    let evaluatedStr = "";
    //Itrating through spaced string array
    for (const value of strArray) {
      let str = value && value.toLowerCase();
      console.log("looping str value", value);
      if (["+", "-", "/", "*"].includes(value)) {
        evaluatedStr = evaluatedStr.concat(value);
      } else if (str) {
        console.log("value exist");
        if (str.includes("r") && str.includes("c") && str.includes("q")) {
          const qIndex = str.indexOf("q");
          const cIndex = str.indexOf("c");
          const rIndex = str.indexOf("r");
          if (cIndex > rIndex > qIndex) {
            const qNum = str.substring(1, rIndex);
            const rNum = str.substring(rIndex + 1, cIndex);
            const cNum = str.substring(cIndex + 1);
            const question = questionList && questionList[+qNum - 1];
            if (
              question &&
              question["id"] &&
              question["questionType"] === "tabular_question"
            ) {
              const answerArr = answers.filter((obj) => {
                if (obj["question_id"] == question["id"]) {
                  return obj;
                }
              });
              const tabularAns =
                answerArr && answerArr[0] && answerArr[0]["answer"];
              const answer =
                tabularAns &&
                tabularAns[+rNum - 1] &&
                tabularAns[+rNum - 1][+cNum - 1];

              if (isNaN(answer)) {
                //handleAnswers(item, event, item["questionType"], "");
                alert(
                  `For ${value} the answer is not a number type. i.e. ${answer}`
                );
                break;
              } else {
                evaluatedStr = evaluatedStr.concat(answer);
              }
            } else {
              // handleAnswers(item, event, item["questionType"], "");
              alert(
                `${
                  question["id"]
                    ? `${value} is not a tabular type question.`
                    : `No question exist for ${value}`
                }`
              );
              break;
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `Please enter tabluar question ${value} in proper format. eg Q2R1C2`
            );
            break;
          }
          //getting 2D array value
        } else if (str.includes("q")) {
          const index = str.substring(1);
          const question = questionList && questionList[+index - 1];
          if (
            question &&
            question["id"] &&
            question["questionType"] === "quantitative"
          ) {
            const answerArr = answers.filter((obj) => {
              if (obj["question_id"] == question["id"]) {
                return obj;
              }
            });
            const answer = answerArr && answerArr[0] && answerArr[0]["answer"];

            if (isNaN(answer)) {
              //handleAnswers(item, event, item["questionType"], "");
              alert(
                `For ${value} the answer is not a number type. i.e. ${answer}`
              );
              break;
            } else {
              evaluatedStr = evaluatedStr.concat(answer);
            }
          } else {
            //handleAnswers(item, event, item["questionType"], "");
            alert(
              `${
                question["id"]
                  ? `${value} is not a quantative type question.`
                  : `No question exist for ${value}`
              }`
            );
            break;
          }
        }
      }
    }
    return evaluatedStr;
  };
  const getSpacedString = (strValue) => {
    let str = "";
    for (const char of strValue) {
      if (["+", "-", "/", "*"].includes(char)) {
        str = str.concat(" ", char, " ");
      } else {
        str = str.concat(char);
      }
    }
    return str;
  };
  const handleAnswers = (item, event, questionType, source, value) => {
    const tempAnswers = [answers];

    const index = tempAnswers.findIndex((obj) => obj?.question_id === item?.id);
    item["questionType"] = questionType;

    switch (questionType) {
      case "tabular_question": {
        handleTabularAnswers(item, event, index, source, value);
        break;
      }
      case "quantitative_trends": {
        handleQunatativeTreds(item, event, index);
        break;
      }
      case "quantitative": {
        handlequantitativeTypes(item, event, index, value);
        break;
      }
      default: {
        handleOtherTypes(item, event, index);
      }
    }
  };

  const changeAnswer = (check) => {
    const isValueExists = changedData.includes(check);
    if (!isValueExists) {
      // setAnswerChange((prevState) => {
      //   const updatedChange = Array.isArray(prevState) ? [...prevState] : [];
      //   updatedChange.push(check);
      //   return updatedChange;
      // });
      changedData.push(check);
    }
  };
  const handleDeleteAnswers = (item, event, questionType, value, ind) => {
    switch (questionType) {
      case "tabular_question": {
        // handleTabularAnswers(item, event, index, value);
        break;
      }
      case "quantitative_trends": {
        handleDeleteQunatativeTreds(item, value);
        break;
      }
      case "quantitative": {
        // handlequantitativeTypes(item, event, index, value);
        break;
      }
      default: {
        handleOtherTypes(item, event, ind);
      }
    }
  };
  const handleAddRow = async (item, index) => {
    const lastRow = item.question_detail
      .filter((detail) => detail.option_type === "row")
      .pop();
    axios
      .post(
        `${config.API_URL}addRow`,
        {
          question_id: item.id,
          row_value: lastRow.option,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const indexToUpdate = filterQuestionList.findIndex(
          (question) => question.id === item.id
        );
        const updatedQuestion = { ...filterQuestionList[indexToUpdate] };

        const newRow = {
          id: response?.data?.data?.id, // Assign a unique ID for the new row
          question_id: 24,
          option_type: "row",
          option: response?.data?.data?.value?.toString(),
          rules: null,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        };
        updatedQuestion.question_detail.push(newRow);
        const updatedList = [
          ...filterQuestionList.slice(0, indexToUpdate),
          updatedQuestion,
          ...filterQuestionList.slice(indexToUpdate + 1),
        ];
        setFilterQuestionList(updatedList);
        let tempAnswers = [...answers];
        if (tempAnswers.length) {
          let check = tempAnswers.findIndex(
            (value) => value.question_id == item.id
          );
          if (check !== -1) {
            const firstArrayLength = tempAnswers[check]?.answer[0].length;
            const newArray = Array(firstArrayLength).fill("");

            tempAnswers[check]?.answer.push(newArray);

            setAnswers(tempAnswers);
          }
        }
      })
      .catch((error) => {
        swal({
          icon: "error",
          title: error.response.data.message,
          timer: 1000,
        });
      });
  };
  const handleTabularAnswers = (item, event, index, source, qValue) => {
    console.log(source, "diaksjcwsefw");
    let tempAnswers = [...answers];
    if (qValue) {
      const d1 = new Date(
        tempAnswers[index]["answer"][item?.indexRow][item?.indexCol - 1]
      );
      const d2 = new Date(event?.target?.value);
      if (d1 > d2) {
        sweetAlert(
          "error",
          `You need to select end date greater than  ${
            tempAnswers[index]["answer"][item?.indexRow][item?.indexCol - 1]
          }`
        );
        return;
      }
    }
    const targetValue = event?.target?.value;
    // if (index >= 0) {
    //   //upadte value if index exist
    //   if (
    //     tempAnswers[index] &&
    //     tempAnswers[index]["answer"] &&
    //     tempAnswers[index]["answer"].length > 0
    //   ) {
    //     const value =
    //       tempAnswers[index]["answer"] &&
    //       tempAnswers[index]["answer"][item?.indexRow] &&
    //       tempAnswers[index]["answer"][item?.indexRow][item?.indexCol];
    //     if (value === 0 || value === "" || value) {
    //       tempAnswers[index]["answer"][item?.indexRow][item?.indexCol] =
    //         targetValue;
    //     } else {
    //       alert("unexpected question type for answer");
    //     }
    //   } else {
    //     const answer2D = get2DArrayAnswer(item, event);
    //     tempAnswers[index]["answer"] = answer2D;
    //     tempAnswers[index]["source_id"] = source;
    //   }
    //   const id = tempAnswers[index]["id"];
    //   tempAnswers[index]["answer_id"] = id || -1;
    //   tempAnswers[index]["status"] = true;
    //   tempAnswers[index]["source_id"] = source;
    //   tempAnswers[index]["question_type"] = item?.questionType;
    //   setAnswers(tempAnswers);
    // } else {
    //update value if index donesn't exist haan kar lo baad main dekg
    const answer2D = get2DArrayAnswer(item, event);
    const tempObj = {
      question_id: item?.id,
      question_type: item?.questionType,
      // answer_id: -1,
      status: true,
      answer: answer2D,
      source_id: source,
      question_type: item?.questionType,
    };
    tempAnswers.push(tempObj);
    setAnswers(tempAnswers);
    // }
  };
  const handleItemRendered = () => {
    setRenderedCount((prevCount) => prevCount + 1);
  };

  const handleQunatativeTreds = (item, trendsAns, index, formula) => {
    setPreviousData(trendsAns);
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    const changes = changedData?.filter((it) => it !== item?.title);
    changedData = changes;
    setAnswerAnswerChangedData(changes);

    // if (index >= 0) {
    //   //upadte value if index exist
    //   tempAnswers[index]["answer"] = trendsAns;
    //   const id = tempAnswers[index]["id"];
    //   tempAnswers[index]["answer_id"] = id || -1;
    //   tempAnswers[index]["status"] = true;
    //   tempAnswers[index]["question_type"] = item?.questionType;
    //         setAnswers(tempAnswers);
    //   if (!formula) {
    //     sweetAlert("success", `${item?.title} Successfully saved`);
    //   }
    // } else {
    //update value if index donesn't exist
    let tempObj = {
      question_id: item?.id,
      question_type: item?.questionType,
      // answer_id: -1,
      status: true,
      answer: trendsAns,
      question_type: item?.questionType,
    };
    tempAnswers.push(tempObj);
    setAnswers(tempAnswers);
    sweetAlert("success", `${item?.title} Successfully saved`);
    // }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (changedData?.length > 0) {
      sweetAlert("error", `You need to save ${changedData}`);
    } else if (answerChangedData?.length > 0) {
      sweetAlert("error", `You need to save ${answerChangedData}`);
    } else {
      let updatedAnswers = answers.filter((obj) => obj.status === true);

      const { isSuccess, data, error } = await apiCall(
        `${config.API_URL}saveAnswerCompanyWise`,
        {},
        {
          questionnaire_type: "SQ",
          company_id: localStorage.getItem("user_temp_id"),
          financial_year_id: financialYearId,
          data: updatedAnswers,
          current_role: localStorage.getItem("role").toLowerCase(),
        },
        "POST"
      );

      if (isSuccess) {
        setAnswers(data);
        swal({
          icon: "success",
          title: "Set answers!",
          timer: 1000,
        });
      }

      if (error) {
        swal({
          icon: "error",
          title: "Could not get companywise answers!",
          timer: 1000,
        });
      }
    }
  };
  const handleDeleteQunatativeTreds = (item, index) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    for (let i = 0; i < tempAnswers.length; i++) {
      if (item.id == tempAnswers[i].question_id) {
        tempAnswers[i].answer.splice(index, 1);
        break;
      }
    }
    setAnswers(tempAnswers);
  };
  const handleAnswerOfFarmula = (item) => {
    let formula = item?.formula;
    let qidValues = formula.match(/Q(\d+)/g).map((qid) => qid.replace("Q", ""));
    let multplyDigit = [];
    let parts = formula.split("+");
    for (let part of parts) {
      if (part.includes("*")) {
        let value = part.split("*")[1];
        multplyDigit.push(parseFloat(value));
      } else {
        multplyDigit.push(1);
      }
    }
    const tempAns = answers?.length ? JSON.parse(JSON.stringify(answers)) : [];
    const result = [];
    let final = [];
    let combinedKeys = [];
    let combinedKey = [];
    let answerFind = [];
    for (const qId of qidValues) {
      for (let i = 0; i < tempAns.length; i++) {
        if (qId == tempAns[i]?.question_id) {
          const tempObj = {
            question_id: tempAns[i].question_id,
            answer: [...tempAns[i].answer],
          };
          answerFind.push(tempAns[i].question_id.toString());
          for (let j = 0; j < tempObj.answer.length; j++) {
            if (tempObj?.answer[j]?.audit_status != "REJECTED") {
              let index = qidValues.indexOf(qId);
              tempObj.answer[j].reading_value =
                parseFloat(tempObj.answer[j].reading_value) *
                parseFloat(multplyDigit[index]);
              result.push(tempObj.answer[j]);
            }
          }
        }
      }
    }
    let mismatchedArray = [];
    mismatchedArray = qidValues
      .filter((element) => !answerFind.includes(element))
      .map((element) => "Q" + element)
      .join(", ")
      .toString();

    for (let j = 0; j < result.length; j++) {
      let key =
        result[j].from_date +
        "-" +
        result[j].to_date +
        "-" +
        result[j].meter_id;

      let keyIndex = combinedKeys.findIndex((item) => item.key === key);

      if (keyIndex !== -1) {
        combinedKeys[keyIndex].answer.reading_value = (
          parseFloat(combinedKeys[keyIndex].answer.reading_value) +
          parseFloat(result[j].reading_value)
        ).toString();
      } else {
        let temp = {
          key: key,
          answer: result[j],
        };

        combinedKeys.push(temp);
      }
    }
    final = combinedKeys.map((item) => item.answer);

    // if(!_.isEqual(final, PreviousData))
    // {
    //   for (let j = 0; j < final?.length; j++) {
    //     handleQunatativeTreds(item, final, j,true);
    //   }

    // }

    let temp = {
      notFindANswer: mismatchedArray,
      answer: final,
      answered_at: "2023-06-14T04:41:50.000Z",
      company_id: 3,
      createdAt: "2023-06-14T04:41:50.000Z",
      financial_year_id: 6,
      id: item?.question_detail[0]?.id,
      proof_document: null,
      question_id: item?.question_detail[0]?.question_id,
      question_type: item?.questionType,
      questionnaire_type: "SQ",
      remark: null,
      reviewd_at: "2023-06-14T08:43:21.000Z",
      status: "IN_REVIEW",
      updatedAt: "2023-06-14T08:43:21.000Z",
      user_id: 3,
    };
    return [temp];
  };
  const handlequantitativeTypes = (item, event, index, qValue) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    let value = qValue || event?.target?.value;
    // if (index >= 0) {
    //   //upadte value if index exist
    //   tempAnswers[index]["answer"] = value;
    //   const id = tempAnswers[index]["id"];
    //   tempAnswers[index]["answer_id"] = id || -1;
    //   tempAnswers[index]["status"] = true;
    //   tempAnswers[index]["question_type"] = item?.questionType;
    //   setAnswers(tempAnswers);
    // } else {
    //update value if index donesn't exist
    let tempObj = {
      question_id: item?.id,
      question_type: item?.questionType,
      // answer_id: -1,
      status: true,
      answer: value,
      question_type: item?.questionType,
    };
    tempAnswers.push(tempObj);
    setAnswers(tempAnswers);
    // }
  };

  const handleOtherTypes = (item, event, index) => {
    let tempAnswers = (answers && answers.length > 0 && [...answers]) || [];
    let tempObj = {
      question_id: item?.id,
      question_type: item?.questionType,
      // answer_id: -1,
      status: true,
      answer: event?.target?.value,
      question_type: item?.questionType,
    };
    tempAnswers.push(tempObj);
    setAnswers(tempAnswers);
    // }
  };
  useEffect(() => {
    const currentUser = authenticationService?.currentUserSubject?.getValue();
    const settingsMenu = JSON.parse(localStorage.getItem("menu")).find(
      (item) => item?.url === "sector_questions"
    );
    setMenuList(settingsMenu?.permissions);
  }, []);
  useEffect(() => {
    if (type === "ALL" && financialYearId) fetchStoredData();
  }, [type, entity]);
  const get2DArrayAnswer = (item, event, qValue) => {
    const numberofRow = item.question_detail.filter(
      (d) => d.option_type === "row"
    ).length;
    const numberofColumn = item.question_detail.filter(
      (d) => d.option_type === "column"
    ).length;
    let answer2D = Array.from({ length: numberofRow }, () =>
      Array.from({ length: numberofColumn }, () => "")
    );
    answer2D[item?.indexRow][item?.indexCol] =
      qValue || event?.target?.value || "";
    return answer2D;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <Header />
      <Sidebar dataFromParent={props.location.pathname} />
      <div className="main_wrapper">
        <div className="inner_wraapper">
          <div className="container-fluid">
            <section className="d_text">
              <div className="container-fluid">
                <form name="form" onSubmit={fetchStoredData}>
                  <div className="select-reporting-criteria">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="Reporting_heading">
                        {" "}
                        <h1> Please Select Reporting Criteria </h1>{" "}
                      </div>
                      <div className="d-flex dropdown align-items-center">
                        <p className="m-0">Entity :</p>
                        {/* {authValue?.is_head === 1 &&
                        authValue?.user_type_code === "company" ? (
                          <select
                            className="select_one_all"
                            value={entity}
                            onChange={(e) => setEntity(e.target.value)}
                          >
                            <option value="company">Company</option>
                          </select>
                        ) : ( */}
                        <select
                          className="select_one_all"
                          onChange={(e) => {
                            setEntityValue(e.target.value);
                          }}
                        >
                          {localStorage.getItem("role") === "COMPANY" ? (
                            <option value="1">company</option>
                          ) : (
                            <option value="">Select Entity</option>
                          )}
                          {entityList.map((entity) => (
                            <option value={entity.id} ref={ref}>
                              {entity.name}
                            </option>
                          ))}
                          {/* <option value="company">Company</option>
                            <option value="supplier">Supplier</option> */}
                        </select>
                      </div>
                      <div className="d-flex dropdown align-items-center">
                        <p className="m-0"> Financial Year : </p>
                        {financialYear && financialYear?.length === 1 ? (
                          <select
                            className="select_one_all"
                            onChange={async (e) => {
                              if (e.target.value !== "Select Financial Year") {
                                setFinancialYearId(e.target.value);
                                if (financialYearId && entity) {
                                  fetchStoredData();
                                }
                              } else {
                                setFinancialYearId("");
                              }
                            }}
                          >
                            {financialYear?.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.financial_year_value}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <select
                            className="select_one_all"
                            onChange={async (e) => {
                              if (e.target.value !== "Select Financial Year") {
                                setFinancialYearId(e.target.value);
                                if (financialYearId && entity) {
                                  fetchStoredData();
                                }
                              } else {
                                setFinancialYearId("");
                              }
                            }}
                          >
                            <option>Select Financial Year</option>
                            {financialYear?.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.financial_year_value}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                    {/* <div>
                      <button
                        style={{
                          background: "#1f9ed1",
                          border: "none",
                          borderRadius: "5px",
                          height: "50px",
                          color: "#fff",
                          fontWeight: "bold",
                          padding: "10px 20px",
                        }}
                        onClick={fetchStoredData}
                      >
                        Get Question
                      </button>
                    </div> */}
                  </div>
                </form>
                {loading ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <span>Loading Questionnaire</span>
                    <Spinner
                      style={{ marginLeft: "13px" }}
                      animation="border"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <>
                    {" "}
                    {data?.length > 0 ? (
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="Introduction pb-4 pt-2 px-4">
                            <div className="col-md-12 question_section">
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="Reporting_heading">
                                  <h1>Sector Questions </h1>
                                </div>
                                <div>
                                  {menuList?.includes(
                                    "ASSIGN_QUESTION_TO_COMPANY_SUB_ADMIN"
                                  ) && (
                                    <>
                                      <button
                                        className="new_button_style mx-2"
                                        onClick={handleShow}
                                      >
                                        Assign Question
                                      </button>
                                      {show === true && (
                                        <AssignQuestions
                                          questions={filterQuestionList}
                                          financialYearId={financialYearId}
                                          fetchquestionApi={fetchquestionApi}
                                          entity={entity[0].name}
                                          show={show}
                                          onHide={handleClose}
                                        />
                                      )}
                                    </>
                                  )}

                                  {/* <button className="new_button_style">View</button> */}
                                </div>
                              </div>
                              <hr className="mt-0"></hr>
                              <div>
                                {localStorage.getItem("role") !==
                                  "SUPPLIER" && (
                                  <SectorQuestionTab
                                    setType={setType}
                                    type={type}
                                    refrenceId={refrenceId}
                                    setRefrenceID={setRefrenceID}
                                  />
                                )}
                                {type !== "ALL" &&
                                  localStorage.getItem("role") !==
                                    "SUPPLIER" && (
                                    <Filters
                                      fetchData={fetchquestionApi}
                                      type={type}
                                    />
                                  )}

                                {questionLoading ? (
                                  <div className="d-flex align-items-center justify-content-center mt-3">
                                    <span>Loading Questionnaire</span>

                                    <Spinner
                                      style={{ marginLeft: "13px" }}
                                      animation="border"
                                      role="status"
                                    >
                                      <span className="visually-hidden">
                                        Loading...
                                      </span>
                                    </Spinner>
                                  </div>
                                ) : (
                                  <div>
                                    <QuestionTypeTabSection
                                      setSectorQuestionType={
                                        setSectorQuestionType
                                      }
                                      sectorQuestionType={sectorQuestionType}
                                      setFilterQuestionList={
                                        setFilterQuestionList
                                      }
                                      AllQuestionList={AllQuestionList}
                                      questionList={questionList}
                                    />
                                    <div className="form-group">
                                      <Row>
                                        <Col md={6} className="Question__type">
                                          {filterQuestionList &&
                                            filterQuestionList.map(
                                              (item, index) => {
                                                return (
                                                  <div
                                                    onClick={(e) => {
                                                      // getAnswerCompanyWise();
                                                      setQuestionDataFunction(
                                                        e,
                                                        item
                                                      );
                                                    }}
                                                  >
                                                    <Table
                                                      striped
                                                      hover
                                                      bordered
                                                      className="m-0"
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <tbody>
                                                        <tr>
                                                          <td>
                                                            {index +
                                                              1 +
                                                              ". " +
                                                              item.title}
                                                          </td>
                                                        </tr>
                                                      </tbody>
                                                    </Table>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </Col>
                                        <Col
                                          md={6}
                                          className="question_right_section"
                                        >
                                          {questionData && (
                                            <SectorAnswer
                                              questionData={questionData}
                                              handleAnswers={handleAnswers}
                                              answers={answers}
                                              user_Is_head={user_Is_head}
                                              handleOtherTypes={
                                                handleOtherTypes
                                              }
                                              changedData={changedData}
                                              meterList={meterList}
                                              processList={processList}
                                              filterQuestionList={
                                                filterQuestionList
                                              }
                                              setFilterQuestionList={
                                                setFilterQuestionList
                                              }
                                              setAnswers={setAnswers}
                                              setUrl={setUrl}
                                              setShowModal={setShowModal}
                                              setUploadItem={setUploadItem}
                                              uploadItem={uploadItem}
                                              initalValue={initalValue}
                                              questionList={questionList}
                                            />
                                          )}
                                        </Col>
                                      </Row>
                                      <Modal show={showModal}>
                                        <div className="modal-lg">
                                          <Modal.Header className="justify-content-end">
                                            <Button
                                              variant="outline-dark"
                                              onClick={() =>
                                                setShowModal(false)
                                              }
                                            >
                                              <i className="fa fa-times"></i>
                                            </Button>
                                          </Modal.Header>
                                          <div className="modal-body">
                                            <img
                                              src={url}
                                              style={{
                                                width: "100%",
                                                height: "auto",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </Modal>
                                      <Button
                                        style={{
                                          background: "#1f9ed1",
                                          border: "none",
                                          padding: "10px 30px",
                                          marginTop: "10px",
                                        }}
                                        onClick={submitHandler}
                                      >
                                        Submit
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : financialYearId ? (
                      <>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="Introduction framwork_2 d-grid justify-content-center">
                              <div className="col-md-12">
                                <div className="heading align-items-center">
                                  <h4 className="E_capital font-heading">
                                    No Questionnaire Found
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectorQuestion;
