import axios from "axios";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { config } from "rxjs";

export default function TebularInputCardAnswer({
  item,
  combinedAnswers,
  userIsHead,
  userIsHistory,
  meterListData,
}) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  function getProcessById(dataArray, inputId) {
    const foundData = dataArray.find((data) => data.id == inputId);
    return foundData ? foundData.process : null;
  }


  return (
    <>
      <div className="table__wrapper">
        {
        userIsHead === 1 &&
          combinedAnswers?.length > 0 &&
          combinedAnswers?.map((combinedAnswers) => {
            return (
              <>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  {combinedAnswers?.source_id && (
                    <div>
                      Source:-{" "}
                      <span style={{ fontWeight: 100 }}>
                        {getProcessById(
                          meterListData,
                          combinedAnswers?.source_id
                        )}
                      </span>
                    </div>
                  )}
                  {combinedAnswers?.answered_by_email && (
                    <div>
                      ANS By:-{" "}
                      <span style={{ fontWeight: 100 }}>
                        {combinedAnswers?.answered_by_email}
                      </span>
                    </div>
                  )}
                  {userIsHistory !== 1 && combinedAnswers?.audit_status && (
                    <div>
                      {" "}
                      Status:-{" "}
                      <span
                        style={{ fontWeight: 100 }}
                        className={`${combinedAnswers?.audit_status} status-font`}
                      >
                        {combinedAnswers?.audit_status?.replace(/_/g, " ") ===
                        "REJECTED"
                          ? "Rejected"
                          : "ACCEPTED"? "Accepted":"Verified"}
                      </span>
                    </div>
                  )}
                  {userIsHistory !== 1 && combinedAnswers?.audit_remark && (
                    <>
                      <button className="new_button_style" onClick={handleShow}>
                        {" "}
                        View Remark{" "}
                      </button>
                      <Modal show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                          <Modal.Title>Auditor Remark</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div>
                            <span style={{ fontWeight: 100 }}>
                              {" "}
                              {combinedAnswers?.audit_remark}
                            </span>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </>
                  )}
                </div>
                <div className="table-wapper">
                  <table className="table">
                    <thead>
                      <tr className="option_wrapper">
                        <th className="p-0"> </th>
                        {item?.question_detail &&
                          item?.question_detail
                            .filter(function (item) {
                              return item?.option_type == "column";
                            })
                            .map((col, ind) => {
                              return <th key={ind}>{col?.option}</th>;
                            })}
                      </tr>
                    </thead>
                    <tbody>
                      {item?.question_detail &&
                        item?.question_detail
                          .filter(function (item) {
                            return item.option_type == "row";
                          })
                          .map((row, indRow) => {
                            return (
                              <tr key={indRow} style={{ fontSize: "12px" }}>
                             
                                <th className="option_wrapper">
                                  {row?.option}
                                </th>
                                {item?.question_detail &&
                                  item?.question_detail
                                    .filter(function (item) {
                                      return item.option_type == "column";
                                    })
                                    .map((col, indCol) => {
                                      const value =
                                        (combinedAnswers?.answer &&
                                          combinedAnswers?.answer[indRow] &&
                                          combinedAnswers?.answer[indRow][
                                            indCol
                                          ]) ||
                                        "";
                                      return (
                                        <td key={indCol}>
                                             <>{console.log("combinedAnswers?.answer",combinedAnswers?.answer)}</>
                                          <input
                                            value={value}
                                            type={"text"}
                                            readOnly
                                          />
                                        </td>
                                      );
                                    })}
                              </tr>
                            );
                          })}
                    </tbody>
                  </table>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
}
