const OLD_API_URL = "https://staging-esg-backend.appricott.in/zais/";
const API_URL = "https://staging-esg-backend.appricott.in/api/";
const SUCCESS_MESSAGE = {
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
};
const REQUEST_MESSAGE = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGOUT: "USERS_LOGOUT",
  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  DELETE_REQUEST: "USERS_DELETE_REQUEST",
};
const ERROR_MESSAGE = {
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",
};

const ALERT_MESSAGE = {
  SUCCESS: "ALERT_SUCCESS",
  ERROR: "ALERT_ERROR",
  CLEAR: "ALERT_CLEAR",
};

const ROLE_ID_MAPPING={
  COMPANY:1,
  SUPPLIER:3,
}

export {
  OLD_API_URL,
  API_URL,
  SUCCESS_MESSAGE,
  REQUEST_MESSAGE,
  ERROR_MESSAGE,
  ALERT_MESSAGE,
  ROLE_ID_MAPPING
};
